<template>
  <div promotion-tooltip-item v-popover="{align, direction: 'bottom', text: `promotion.${text}`, useClick: isMobile, class: 'promotion-tooltip', maxWidth: 400}" v-if="text">
    <button>
      <FontIcon :name="icon" />
    </button>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'PromotionTooltipItem',
  components: { FontIcon },
  props: {
    text: { type: String, required: true },
    icon: { type: String, default: 'info-circle-line' },
  },
  computed: {
    isMobile: state('browser', 'mobile'),
    align() {
      return this.isRtl ? 'right' : 'left';
    }
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
.promotion [popover-container] {
  .popover-position {
    .popover.promotion-tooltip {
      .component-crop { .bgc(@c-blurWhite); .c(@c-black);
        .popover-text { .c(inherit); }
      }
      em.arrow { .bgc(@c-blurWhite); }
    }
  }

  @media (@mm-down) {
    .popover-position { .l(20) !important; .r(20) !important; .w(auto) !important;
      em.arrow { .hide(); }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
[promotion-tooltip-item] { .fs(16);
  button { .p(0.375em); .bgc(); .fs(1em); .c(@c-dimGrey); }
}
</style>
