import { parseQuery } from '@shared/utils/urlUtils.mjs';
import { isExcludeGgpass, isExcludeTnc } from '@/utils/baseUtil';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap';
import { regionsKeys } from '@/constants/base/signup/channelingSiteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import base32 from '@/utils/base32';
import _ from 'lodash';
import { trackGtmEvent, trackSegment } from '@/plugins/tracking';

export default class SignService {
  #services;
  #api;
  #ggpass;
  #userApi;
  #npDevApi;
  #store;
  #channelingApi;
  #site;
  constructor(services) {
    this.#services = services;
    this.#store = services.store;
    this.#api = services.api;
    this.#userApi = services.userApi;
    this.#npDevApi = services.npDevApi;
    this.#ggpass = services.passApi;
    this.#channelingApi = services.channelingApi;
    this.#site = this.#store.state.env.site;
  }

  async login({ CountryCode = '', MobileNumber = '', Username = '', Email = '', Password = '', LoginMethodType = '', Language = '', MfaCode = '', CustomIPAddress = '', CustomDeviceId = '', SiteId = '' }) {
    const setToken = token => {
      this.#services.cookie.setToken(token);
      this.#services.store.commit('user/setToken', token);
    };

    try {
      const r = (await this.#npDevApi.post(isExcludeGgpass(SiteId) ? '/dev/player/login' : '/dev/player/login-onepass', { CountryCode, MobileNumber, Username, Email: (Email ? Email.trim() : undefined), Password, LoginMethodType, Language, MfaCode, CustomIPAddress, CustomDeviceId, SiteId }));
      console.log('sign-in login', r);
      if (r?.desc) {
        if (r.desc === apiErrorCode.ACCOUNT_UNVERIFIED) {
          const values = parseQuery(r.custom[0]);
          setToken(values.token);
          return true;
        }
        this.#services.toast.toast(r.desc, { translate: false, type: 'fail' });
        return false;
      }
      if (r?.key === apiErrorCode.ACCOUNT_UNVERIFIED && r?.CustomerErrorParameters?.length) {
        const queries = parseQuery(r?.CustomerErrorParameters[0]);
        setToken(queries.token);
        return true;
      }

      if (r.NpAccessToken) {
        setToken(r.NpAccessToken);
      } else {
        const accessToken = (await this.#npDevApi.get('/player/access-tokens', null, { headers: { 'x-session': r.Token } }))?.AccessToken;
        setToken(accessToken);
      }

      return true;
    } catch (e) {
      console.log('error : login', e);
      if (e.code === apiErrorCode.ACCOUNT_UNVERIFIED) {
        const values = parseQuery(e.body.CustomerErrorParameters[0]);
        setToken(values.token);
        return true;
      }
      throw e;
    }
  }

  async logout({ isSessionExpired = false, useRedirect = true, signIn = false } = {}) {
    if (!this.#store.state.user.gwToken) return;
    try {
      this.#services.cookie.removeToken();
      this.#store.commit('user/removeToken');
      // await this.#userApi.post('/player/logout', null, { silent: true });
      if (signIn) location.replace('/sign-in');
      else useRedirect && location.replace(isSessionExpired ? '/auth-error' : '/');
    } catch (e) {
      if (e.code === 401 && (!location.pathname.includes('verification-failed') || !location.pathname.includes('auth-error'))) location.replace('/auth-error');
      // BFGW-009 code로 게이트웨이 에서 에러를 반환하여 logout 시 오류가 발생하여 확인 필요
      // if (!location.pathname.includes('auth-error')) location.replace('/auth-error');
      // throw e;
    }
  }

  /**
   * @description 이메일 중복 검사
   * @id EmailVerificationRequest
   * @param {string} Email
   * @param {string} BonusCode
   * @param {string} CountryCode
   * @param {string} Invite
   * @param {string} IsOffline
   * @param {number} SiteId
   * @param {string?} RecaptchaToken
   * @param {string?} RecaptchaAction
   * @param {string?} RecaptchaSiteKey
   * @param {string?} Challenge
   * @param {string?} SecCode
   * @param {string?} Validate
   * @return {string} Email - GetEmailVerificationResponse
   */
  async emailVerification({ Email, BonusCode, CountryCode, Invite, IsOffline = 'false', SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate }) {
    try {
      const r = await this.#api.put('/player/signup/email/verify', { Email: (Email ? Email.trim() : undefined), BonusCode, CountryCode, Invite, IsOffline, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate });
      return r;
    } catch (e) {
      console.log('error : emailVerification');
      throw e;
    }
  }

  /**
   * @description 이메일 인증 번호 전송
   * @id SendEmailVerificationCodeRequest
   * @param {string} PlatformType
   * @param {string} Email
   * @param {string} Password
   * @param {string} BonusCode
   * @param {string} BTag
   * @param {string} CountryCode
   * @param {string} StateCode
   * @param {string} Invite
   * @param {string} RecaptchaToken
   * @param {string?} RecaptchaAction
   * @param {string?} RecaptchaSiteKey
   * @param {string?} Challenge
   * @param {string?} SecCode
   * @param {string?} Validate
   * @param {boolean} IsOffline
   * @param {string} SiteId
   * @param {boolean} IsResend
   * @return
   */
  async sendEmailVerificationCode({ PlatformType = '0', Email, Password, BonusCode, BTag, CountryCode, Invite, RecaptchaToken = '', RecaptchaAction, RecaptchaSiteKey, Challenge = '', SecCode = '', Validate = '', IsOffline = false, SiteId, IsResend = false, }) {
    try {
      const r = await this.#api.put('/player/signup/email', { PlatformType, Email: (Email ? Email.trim() : undefined), Password, BonusCode, BTag, CountryCode, Invite, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate, IsOffline, SiteId, IsResend });
      if (r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : sendEmailVerificationCode');
      throw e;
    }
  }

  /**
   * @description 이메일 인증 번호 전송
   * @id SendMobileVerificationCodeRequest
   * @param {string} Challenge
   * @param {string} CountryCode
   * @param {string} IsOffline
   * @param {string} MessageType
   * @param {string} MobileNumber
   * @param {string} RecaptchaToken
   * @param {string} SecCode
   * @param {string} validate
   * @param {string} VerificationType
   * @param {string} IsFromExternalUrl
   * @return
   */
  async sendMobileVerificationCode({ Challenge, CountryCode, IsOffline = 'false', MessageType, MobileNumber, RecaptchaToken, SecCode, validate, VerificationType = 'AgeVerification', IsFromExternalUrl = 'true' }) {
    try {
      const r = await this.#api.put('/verification/mobile/signup', { Challenge, CountryCode, IsOffline, MessageType, MobileNumber, RecaptchaToken, SecCode, validate, VerificationType, IsFromExternalUrl });
      return r;
    } catch (e) {
      console.log('error : sendMobileVerificationCode');
      throw e;
    }
  }

  /**
   * @description 회원가입
   * @id SignupRequest
   * @param {string} PlatformType
   * @param {string} CountryCode
   * @param {string} StateCode
   * @param {string} MobileCountryCode
   * @param {string} MobileNumber
   * @param {string} VerificationCode
   * @param {string} Email
   * @param {string} Password
   * @param {string} BonusCode
   * @param {string} ChannelingAgentCode
   * @param {string || null} BTag
   * @param {string} QTag
   * @param {string} Invite
   * @param {string} SignupType?
   * @param {string} EmailVerificationCode
   * @param {string} IsFromExternalUrl
   * @param {string} IsTrackingUrl
   * @param {string} SiteId
   * @param {string} RecaptchaToken
   * @param {string?} RecaptchaAction
   * @param {string?} RecaptchaSiteKey
   * @param {string} IsOffline
   * @param {string} Challenge
   * @param {string} SecCode
   * @param {string} Validate
   * @param {string} FirstName
   * @param {string} LastName
   * @param {string} Gender
   * @param {string} DateOfBirth
   * @param {string} Address
   * @param {string} City
   * @param {string} PostalCode
   * @param {string} IsMarketingAgreement
   * @param {string} IsAgreeTnC
   * @param {string} IsTypedBonusCode
   * @param {string} QueryParams
   * @returns {Promise<SignupResponse> || object}
   */
  // async signup({
  //   PlatformType, StateCode, MobileCountryCode, CountryCode, MobileNumber, VerificationCode, Email,
  //   Password, BonusCode, ChannelingAgentCode, BTag, QTag, Invite, SignupType, EmailVerificationCode,
  //   IsFromExternalUrl = 'true', IsTrackingUrl, SiteId, RecaptchaToken = '', RecaptchaAction, RecaptchaSiteKey, IsOffline = 'false', Challenge = '', SecCode = '',
  //   Validate = '', FirstName = '', LastName = '', Gender = '0', DateOfBirth = '', Address = '', City = '', PostalCode = '',
  //   IsMarketingAgreement = '', IsAgreeTnC = '', IsTypedBonusCode, QueryParams
  // })
  async signup(info, AccessToken) {
    try {
      const query = this.#store.state.query;

      if (info.BonusCode) {
        info.BTag = null;
      } else {
        info.BTag = query.qtag || query.qtag1 || query.btag || query.btag1 || this.#services.cookie.getBonusTag();
        if(_.isArray(info.BTag) && info.BTag.length) info.BTag = info.BTag[0];

        const REFERRAL_CODE_REGEX = /ca[0-9]{4,}/gi;
        if (info.SiteId === siteIds.WSOPON && REFERRAL_CODE_REGEX.test(info.BTag)) {
          info.BonusCode = info.BTag;
          info.BTag = null;
        }
      }

      /** tnc 화면이 안나오는 site들은 IsMarketingAgreement, TncAgree가 true이다. */
      const agree = isExcludeTnc(info.SiteId);
      const IsMarketingAgreement = agree || info.IsMarketingAgreement;
      const IsAgreeTnc = agree || info.IsAgreeTnC;

      const isExcluded = isExcludeGgpass(this.#store.state.env.site);
      const ReferenceKey = this.#store.state.query.refKey;
      const path = !isExcluded ? '/v1/auth/sign-up' : '/player/signup';
      const body = !isExcluded ? { RequestSiteId: info.SiteId, AccessToken: AccessToken || info.AccessToken, IsMarketingAgreement, IsAgreeTnc, ReferenceKey, CountryCode: info.Country || this.#store.state.user.countryCode, PlatformType: info.PlatformType, BonusCode: info.BonusCode } : { ...info, Email: (info.Email ? info.Email?.trim() : undefined), IsAgreeTnc, IsMarketingAgreement };

      const r = await this.#api.post(path, body);
      if (!r?.error) {
        this.#store.commit('user/setSignedAccessToken', r.AccessToken);
        trackGtmEvent({ 'event': 'Signup', 'Signup_Success_YN': true, 'User_Email': ReferenceKey || info.Email });

        const brandKey = Object.keys(brandIds).find(key => brandIds[key] === this.#store.state.env.brand);
        this.trackSignupSegment('sign-up complete', { user_id: r?.SegmentTrackId, user_email: ReferenceKey || info.Email, site_id: info.SiteId, brand_name: displayBrandNames[brandKey] });
      } else {
        this.trackSignupSegment('sign-up fail');
      }
      return r;
    } catch (e) {
      console.log('error : signup', e);
      if (e?.body?.Description === 'Invalid Bonus Code.') {
        return 'invalidBonusCode';
      }
      throw e;
    }
  }

  /**
   * @desc GGPDE Segment Tracking 용
   */
  trackSignupSegment(track, identify = null) {
    try {
      if (this.#store.state.query.aTag && identify) identify.a_tag = base32.decode(this.#store.state.query.aTag);
    } catch (e) {
      console.log('error : trackSignupSegment aTag', this.#store.state.query.aTag);
    }
    trackSegment(track, identify);
  }

  /**
   * @description 로그인
   * @id LoginRequest
   * @param {number} LoginMethodType
   * @param {string} Brand
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Username
   * @param {string} Email
   * @param {string} Password
   * @param {boolean} IsAutoLogin
   * @param {string} VerificationCode
   * @param {number} PlatformType
   * @param {number} SiteId
   * @return {Promise<LoginResponse>}
   */
  async playerLogin({ LoginMethodType, Brand, CountryCode, MobileNumber, Username, Email, Password, IsAutoLogin, VerificationCode, PlatformType, SiteId }) {
    try {
      const r = await this.#api.post('/LoginRequest', { LoginMethodType, Brand, CountryCode, MobileNumber, Username, Email: (Email ? Email.trim() : undefined), Password, IsAutoLogin, VerificationCode, PlatformType, SiteId });
      return r;
    } catch (e) {
      console.log('error : playerLogin');
      throw e;
    }
  }

  /**
   * @description 기존 verificationService.js에 있는 GetPlayerIdentityVerificationMethodsRequest 사용 안함
   * @description forgotPasswordService.js에 있는 거 사용
   * @id GetPlayerIdentityVerificationMethodsRequest
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {number} SiteId
   * @param {string} RecaptchaToken
   * @param {boolean} IsOffline
   * @param {string} Challenge
   * @param {string} SecCode
   * @param {string} Validate
   * @return {Promise<VerificationMethods>} GetPlayerIdentityVerificationMethodsResponse
   */
  async getIdentityVerificationMethods({ LoginMethod, CountryCode, MobileNumber, Email, Username, SiteId, RecaptchaToken = '', IsOffline = false, Challenge = '', SecCode = '', Validate = '' }) {
    try {
      const r = await this.#api.get('/getIdentityVerificationMethods', { LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, SiteId, RecaptchaToken, IsOffline, Challenge, SecCode, Validate });
      return r;
    } catch (e) {
      console.log('error : getIdentityVerificationMethods');
      throw e;
    }
  }

  /**
   * @description 비밀번호 재설정을 위해 사용자를 식별할 e-Mail 이나 Mobile 을 확인
   * @id GetPlayerIdentityVerificationMethodsRequest
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {number} SiteId
   * @param {string} RecaptchaToken
   * @param {boolean} IsOffline
   * @param {string} Challenge
   * @param {string} SecCode
   * @param {string} Validate
   * @return {Promise<VerificationMethods>} GetPlayerIdentityVerificationMethodsResponse
   */
  async getIdentityVerification(info) {
    try {
      const CountryCode = info.CountryCode;
      const Email = info.Email?.trim();
      const MobileNumber = info.MobileNumber;
      const LoginMethod = info.LoginMethod;
      const Username = null;
      const RecaptchaAction = info.RecaptchaAction;
      const RecaptchaSiteKey = info.RecaptchaSiteKey;
      const RecaptchaToken = info.RecaptchaToken;
      const IsOffline = false;
      const SiteId = null;
      const Challenge = null;
      const SecCode = null;
      const Validate = null;
      // const r = await this.#api.get('/player/identity/verification/methods', { LoginMethod, CountryCode, MobileNumber, Email, Username, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline, Challenge, SecCode, Validate }, { silent: true });

      const r = await this.#api.post('/player/identity/verification/methods', { LoginMethod, CountryCode, MobileNumber, Email, Username, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline, Challenge, SecCode, Validate }, { silent: true });
      if (r.IsValidPlayer === false && r.SiteId === 'None') {
        return { error: true, key: 'INVALID_PLAYER' };
      }
      return r;
    } catch (e) {
      console.log('error : getIdentityVerification');
      throw e;
    }
  }
  /**
   * @id VerifyEmailVerificationCodeVerifyByIdentityKeyRequest
   * @param {number} PlayerEmailVerificationType
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {string} VerificationCode
   * @param {string|null} RecaptchaToken
   * @param {string|null} Challenge
   * @param {string|null} SecCode
   * @param {string|null} Validate
   * @param {boolean|null} IsOffline
   * @param {number} SiteId
   * @returns
   */
  async verifyEmailVerificationCode({ PlayerEmailVerificationType, LoginMethod, CountryCode, MobileNumber, Email, Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, VerifyCode, SiteId = 'None' }) {
    const VerificationCode = VerifyCode;
    try {
      const r = await this.#api.post('/player/emailcodeverify/by-identity-key', { PlayerEmailVerificationType, LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, VerificationCode, Challenge, SecCode, SiteId }, { silent: true });
      return r;
    } catch (e) {
      console.log('error : verifyEmailVerificationCode');
      throw e;
    }
  }
  /**
   * @id VerifyMobileVerificationCodeByIdentityKeyRequest
   * @param {number} PlayerEmailVerificationType
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {string} VerificationCode
   * @param {string|null} RecaptchaToken
   * @param {string|null} Challenge
   * @param {string|null} SecCode
   * @param {string|null} Validate
   * @param {boolean|null} IsOffline
   * @param {number} SiteId
   * @returns
   */
  async verifyMobileVerificationCode({ VerificationType, LoginMethod, CountryCode, MobileNumber, Email, Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, VerifyCode, SiteId = 'None' }) {
    const VerificationCode = VerifyCode;
    try {
      const r = await this.#api.post('/player/mobilecodeverify/by-identity-key', { VerificationType, LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, VerificationCode, Challenge, SecCode, SiteId }, { silent: true });
      return r;
    } catch (e) {
      console.log('error : verifyMobileVerificationCode');
      throw e;
    }
  }
  /**
   * @description 비밀번호 재설정을 위한 모바일 인증 코드 전송
   * @id SendMobileVerificationCodeByIdentityKeyRequest
   * @param {number} PlayerEmailVerificationType
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {string} VerificationCode
   * @param {string|null} RecaptchaToken
   * @param {string|null} Challenge
   * @param {string|null} SecCode
   * @param {string|null} Validate
   * @param {boolean|null} IsOffline
   * @param {number} SiteId
   * @returns
   */
  async getSendVerifyMobileVerificationByIdentityKey({ CountryCode, MessageType, Email, Language, LoginMethod = 'Email', MobileNumber, Username, Challenge, SecCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction = 'ForgotPasswordEmailSendCode', VerificationType = 'ForgotPassword', SiteId = 'None' }) {
    try {
      // const r = await this.#api.get('/player/mobile/verification/by-identity-key', { CountryCode, Email, MessageType, Language, LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, VerificationType, SiteId, Username }, { silent: true });

      const r = await this.#api.post('/player/mobile/verification/by-identity-key', { CountryCode, Email: (Email ? Email.trim() : undefined), MessageType, Language, LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, VerificationType, SiteId, Username }, { silent: true });
      if (r.error && r.key === apiErrorCode.PHONE_RESEND_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : getSendVerifyMobileVerificationByIdentityKey');
      throw e;
    }
  }
  /**
   * @description 비밀번호 재설정에 필요한 인증코드 전송
   * @id SendEmailVerificationByIdentityKeyRequest
   * */
  async getSendEmailVerificationByIdentityKey({ CountryCode, Email, Language, LoginMethod = 'Email', MobileNumber, Username, Challenge, SecCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, VerificationType = 'ForgotPassword', SiteId = 'None' }) {
    try {
      // const r = await this.#api.get('/player/email/verification/by-identity-key', { CountryCode, Email, Language, LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, VerificationType, SiteId, Username }, { silent: true });

      const r = await this.#api.post('/player/email/verification/by-identity-key', { CountryCode, Email: (Email ? Email.trim() : undefined), Language, LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, VerificationType, SiteId, Username }, { silent: true });
      if (r.error && r.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : getSendEmailVerificationByIdentityKey', e);
    }
  }
  /**
   * @description forgotPasswordService.js 의 resetPasswordWithToken + resetPasswordByRequest + resetPassword
   * @id ResetPasswordRequest
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string?} Username
   * @param {string} Password
   * @param {boolean?} IsIgnoredCaptcha
   * @param {boolean?} IsTokenVerification
   * @param {number?} RequestMethodType
   * @param {string?} Token
   * @param {string?} MobileVerificationCode
   * @param {string?} EmailVerificationCode
   * @param {string?} RecaptchaToken
   * @param {string?} Challenge
   * @param {string?} SecCode
   * @param {string?} Validate
   * @param {boolean?} IsOffline
   * @param {string?} EmailVerificationToken
   * 확인 중
   */
  async signResetPassword({ LoginMethod, CountryCode, MobileNumber, Email, Username = '', Password, IsTokenVerification, RequestMethodType, Token, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Validate, IsOffline, Challenge, SecCode, EmailVerificationToken, VerifyCode, NpApiType }) {
    try {
      const MobileVerificationCode = NpApiType === 'Mobile' ? VerifyCode : null;
      const EmailVerificationCode = NpApiType === 'Email' ? VerifyCode : null;
      const result = await this.#api.patch('/player/password', { LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, Password, IsTokenVerification, RequestMethodType, Token, MobileVerificationCode, EmailVerificationCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Validate, Challenge, SecCode, IsOffline, EmailVerificationToken }, { silent: true });
      return result;
    } catch (e) {
      console.log('error : signResetPassword');
      throw e;
    }
  }
  /**
   * @id CheckUserRequest
   * @param {string?} Email
   * @param {number?} LoginMethodType
   * @param {string?} MobileNumber
   * @param {number?} SiteId
   * @param {string?} CountryCode
   * @returns {CheckUserResponse}
   */
  async checkUser({ CountryCode, Email, LoginMethodType, MobileNumber, SiteId }) {
    try {
      const r = await this.#api.get('/players/check', { CountryCode, Email: (Email ? Email.trim() : undefined), LoginMethodType, MobileNumber, SiteId });
      return r;
    } catch (e) {
      console.log('error : checkUser');
      throw e;
    }
  }
  /**
   * @param {string} domain
   * @returns
   */
  async getSignupUrl({ domain }) {
    try {
      const r = /** @type {NewSignUpResponse} */ await this.#services.channelingApi.get('/signup_url', { domain });
      const environment = process.env.VUE_APP_ENV;
      if (r.signupUrl) {
        let queries = r.signupUrl.split('?');
        const domain = queries.splice(0, 1);
        queries = queries.join('');
        queries = `${queries}${queries.length ? '&' : ''}checkedUrl=true`;

        if (environment === 'local') location.href = `http://${location.host}/client/signup/country?${queries}`;
        /** 아래 줄 ggpcz channeling production 환경에서 막는 로직 live 배포 시에 아래 줄 제거 */
        // else if (environment === 'production' && (queries.includes(regionsKeys.CZ))) location.replace(`/not-support-region?channelingUrl=${domain}&checkedUrl=true`);
        else {
          console.log('channeling replace');
          location.replace(`${domain}?${queries}`);
          return false;
        }
      }
      return r;
    } catch (e) {
      console.log('error : getSignupUrl');
      throw e;
    }
  }

  /**
   * @param {string} domain
   * @returns
   */
  async checkChannelingDownload({ domain }) {
    try {
      const r = /** @type {NewSignUpResponse} */ await this.#services.channelingApi.get('/signup_url', { domain });
      return r;
    } catch (e) {
      console.log('error : checkChannelingDownload');
      throw e;
    }
  }

  /**
   * @param {string} channelingKey
   * @param {string} regionKey
   * @returns {*}
   */
  async getChannelingInfo({ channelingKey, regionKey }) {
    try {
      const r = this.#services.channelingApi.get('/channeling/info', { channelingKey, regionKey });
      return r;
    } catch (e) {
      console.log('error : getChannelingInfo');
      throw e;
    }
  }

  /**
   * @id CheckAvailableLocationRequest
   * @param {string} countryCode
   * @returns {IsAllowed: boolean}
   */
  async checkAvailableLocation({ countryCode }) {
    try {
      const r = await this.#api.get('/player/checkavailablelocation', { countryCode });
      if (r?.Code === apiErrorCode.SERVICE_MAINTENANCE) return { error: true, comment: r.CustomerErrorCode };
      return r;
    } catch (e) {
      console.log('error : checkAvailableLocation');
      throw e;
    }
  }

  /**
   * @id GetDownloadUrl
   * @param {string} domain
   * @returns {ChannelingDownloadUrl}
   */
  async getChannelingDownloadUrl({ domain }) {
    try {
      const r = await this.#services.channelingApi.get('/channeling/download_url', { domain });
      return r;
    } catch (e) {
      console.log('error : getChannelingDownloadUrl');
      throw e;
    }
  }
  /**
   * @id GetValidateReferenceKeyRequest
   * @description 백엔드에서 발급된 ReferenceKey 토큰을 검증
   * @param {string} ReferenceKey
   * @returns {{IsBonusCodeRequired}}
   * */
  async getValidateReferenceKey(ReferenceKey) {
    try {
      const r = await this.#api.get(`/v1/auth/tokens/states`, { ReferenceKey });
      return r;
    } catch (e) {
      console.log('error : getValidateReferenceKey', e);
      throw e;
    }
  }
  /**
   * @id RequestVerificationByTokenRequest
   * */
  async requestVerificationToken({ VerificationToken, VerificationMethodType }) {
    try {
      const r = await this.#api.post(`/player/token/verify`, { VerificationToken, VerificationMethodType });
      return r;
    } catch (e) {
      console.log('error : requestVerificationToken', e);
      throw e;
    }
  }

  /**
   * @param payload { { PlatformType, StateCode, MobileCountryCode,PhoneCountryCode, CountryCode, MobileNumber, VerificationCode, Email,Password, BonusCode, ChannelingAgentCode, BTag, QTag, Invite, SignupType, EmailVerificationCode,IsFromExternalUrl , IsTrackingUrl, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline , Challenge , SecCode ,Validate , FirstName , LastName , Gender , DateOfBirth , Address , City , PostalCode ,IsMarketingAgreement , IsAgreeTnC , IsTypedBonusCode, QueryParams } }
   * */
  async #gpSignup(payload) {
    try {
      const email = payload.Email?.trim();
      const password = payload.Password;
      const mobileNumber = payload.MobileNumber;
      const countryCode = mobileNumber ? `+${payload.PhoneCountryCode}` : null;
      const verificationCode = payload.VerificationCode;
      const oAuthType = null;
      const oAuthToken = null;
      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;
      const residenceCountryId = payload.CountryCode;
      const invitor = payload.Invite;
      const isMarketingAgreement = payload.IsMarketingAgreement;
      const isTncAgreement = payload.IsAgreeTnC;
      const recaptchaToken = payload.RecaptchaToken;
      const challenge = payload.Challenge;
      const secCode = payload.SecCode;
      const bonusCode = payload.BonusCode;
      const referenceKey = this.#store.state.query.refKey;

      const query = this.#store.state.query;
      const REFERRAL_CODE_REGEX = /ca[0-9]{4,}/gi;
      let marketingTag = null;
      let referralCode = query.qtag || query.qtag1 || query.btag || query.btag1 || this.#services.cookie.getBonusTag();
      if(_.isArray(referralCode) && referralCode.length) referralCode = referralCode[0];
      if (referralCode && referralCode.includes('|')) {
        marketingTag = referralCode;
        referralCode = null;
      }
      if (siteId === siteIds.WSOPON && REFERRAL_CODE_REGEX.test(referralCode)) referralCode = null;

      let r = await this.#ggpass.post('/op-auth/v1/onepass/auth/sign-up', { email, countryCode, mobileNumber, password, verificationCode, oAuthType, oAuthToken, brandId, siteId, marketingTag, residenceCountryId, referralCode, bonusCode, invitor, isMarketingAgreement, isTncAgreement, recaptchaToken, challenge, secCode });
      try { if (r?.accessToken) r = await this.signup(payload, r.accessToken); } catch (ee) { /* empty */ } // np signup 오류가 발생해도 무시해야하므로 try catch 적용
      return r;
    } catch (e) {
      console.log('error : signup', e);
    }
  }

  async getChanneling(domain) {
    const r = /** @type {NewSignUpResponse} */ await this.#services.channelingApi.get('/signup_url', { domain }, { headers: { 'X-Forwarded-For-Custom': '223.27.122.138' } },);
  }

  async publicSendEmailVerificationCode(payload) {
    const site = this.#store.state.env.site;
    try {
      return isExcludeGgpass(site) ? await this.sendEmailVerificationCode(payload) : await this.#gpSignup(payload);
    } catch (e) {
      console.log('error : publicSendEmailVerificationCode');
      throw e;
    }
  }

  async publicEmailVerification(payload, verify) {
    const site = this.#store.state.env.site;
    try {
      return verify || isExcludeGgpass(site) ? this.emailVerification(payload) : await this.#gpSignup(payload);
    } catch (e) {
      console.log('error : publicEmailVerification');
      throw e;
    }
  }

  async publicSignup(payload, AccessToken) {
    const site = this.#store.state.env.site;
    try {
      return isExcludeGgpass(site) ? await this.signup(payload, AccessToken) : await this.#gpSignup(payload);
    } catch (e) {
      console.log('error : publicSignup');
      throw e;
    }
  }

  /**
   * @id GetGameClientLoginTokenRequest
   * @description - 인증된 플레이어의 웹뷰 → 클라 자동로그인 용 1회성 accessToken 을 발급
   * 에러 발생 시 무시해야하므로 catch 에 throw 제거
   * @id - GetGameClientLoginTokenRequest
   * @returns {Promise<string>}
   */
  async getGcLoginToken() {
    try {
      return (await this.#userApi.get(`/v1/auth/tokens/gc-login`)).ReLoginToken;
    } catch (e) {
      console.log('error : getGcLoginToken', e);
    }
  }

  /**
   * @id GetSiteConfigRequest
   * @description - SiteId로 해당 사이트의 기본 설정 조회 (현재는 T&C여부만 반환)
   * 에러 발생 시 무시해야하므로 catch 에 throw 제거
   * @id - GetGameClientLoginTokenRequest
   * @returns {Promise<GetSiteConfigResponse>}
   */
  async getSiteConfig(siteId) {
    try {
      return await this.#api.get(`/site/config/${siteId}`);
    } catch (e) {
      console.log('error : getSiteConfig', e);
    }
  }
  /**
   * @description - 채널링 사인업 ( Agent Code ) 시, Agent Code ( Channeling Code ) 를 입력 불가능한 SiteID 리스트를 받아온다.
   * @id - GetBonusCodes
   */
  async getChannelingBonusCodes(channelingKey){
    try{
      const r = await this.#channelingApi.get(`/bonus_codes?channelingKey=${channelingKey}`);
      return r;
    }catch(e){
      console.log('error : getChannelingSignup', e);
    }
  }
}