<template>
  <div search-drop-select :class="[size]" tabindex="0" @focus="focusIn" @blur="focusOut" @click="focusIn">
    <a :class="{ 'placeholder' : empty}" class="opener">
      <TextInput v-if="isMd" v-model="searchText" size="sm" theme="black" :placeholder="placeholder" :is-trim="isTrim" :readonly="selectOnly" :maxLength="maxLength" :class="[{'select-only': selectOnly}, 'search-field']" @focus="$emit('focus')" @blur="focusOut" />
      <label v-html="viewModel" v-else />
      <FontIcon name="caret-down" :class="{'on' : isShow}" :default-path="cdnSvgPath" v-if="((!isMd || selectOnly) && !hiddenIcon)" />
    </a>
    <div class="list-container" v-if="!matchSelect" :style="listContainerStyleValue" :class="[{'on' : isShow && (autoOpen || !isMd || searchLength || selectOnly)},{'fill': parentFill && typeof(parentFill) !== 'object'}]">
      <div class="list-inner" :style="listInnerStyleValue">
        <div class="input-holder" v-if="!isMd && !selectOnly">
          <TextInput class="inside-input" theme="dark" v-model="searchText" clearable :focus="isOn" :tabindex="isOn ? '0' : '-1'" :placeholder="inputPlaceholder" :maxLength="maxLength" @focus="$emit('blur')" />
        </div>
        <div class="scroll-holder" v-if="showList && showList.length" :class="{'no-list' : !showList || !showList.length, 'full': selectOnly}" ref="scroller" tabindex="-1">
          <div class="size-holder" tabindex="-1">
            <label v-if="subLabel">{{ subLabel }}</label>
            <button v-for="(item,id) in showList" :key="id" :tabindex="isShow ? '0' : '-1'" :class="{'active': isSelected(item), 'focus': focusId === id}"
                    @click="updateItem(item)" v-html="getView(item)" @blur="id === showList.length-1 ? toggle(false) : () => {}" />
          </div>
        </div>
        <p class="empty" v-else>
          <span v-if="!isLoading">{{ $t('noResultsMatched') }}</span>
          <FontIcon v-else name="rotate-arrows" />
        </p>
      </div>
      <div v-if="footerTemplate" class="list-footer" v-html="footerTemplate" />
    </div>
  </div>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils.mjs';
import { state } from '@shared/utils/storeUtils.mjs';
import TextInput from '@shared/components/common/input/TextInput.vue';
import Specific from '@shared/types/Specific';
import _, { isBoolean } from 'lodash';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'SearchDropSelect',
  components: { FontIcon, TextInput },
  props: {
    params: Specific,
    type: { type: String, default: 'select' },
    value: { /** @type {BaseInfo} */ type: Specific, default: null },
    searchValue: { type: String, default: null },
    size: { type: String, default: 'sm' },
    list: { type: Array, default: null },
    maxLength: { type: Number, default: 999 },
    autoOpen: { type: Boolean, default: false },
    template: { type: Function, default: null },
    subLabel: { type: String, default: null },
    parentFill: { type: Specific, default: false },
    selectOnly: { type: Boolean, default: false, },
    searchHandler: { type: Function, default: null },
    footerTemplate: { type: String, default: null },
    placeholder: { type: String, default: null },
    inputPlaceholder: { type: String, default: null },
    showNoResult: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    autoSelect: { type: Boolean, default: true },
    topGap: { type: Number, default: 0 },
    hiddenIcon:{ type: Boolean, default : false},
  },
  data() {
    return {
      focusId: -1,
      selectedItem: null,
      viewTemplate: null,
      searchText: '',
      showList: [],
      originList: null,
      isOn: false,
      isShow: false,
      isBottom: true,
      listContainerStyleValue: null,
      listInnerStyleValue: null,
      scrollHeight: null,
      isFocus: false,
      shiftDown: false,
      firstOpen: false,
      isLoading: false,
      hold: false,
    };
  },
  computed: {
    isListOnly() {
      return this.selectOnly;
    },
    tabindex() {
      return (this.showList || []).length ? '0' : '-1';
    },
    gap() {
      return this.isLg || (this.isSm && !this.selectOnly) ? 64 : 16;
    },
    empty() {
      return !this.selectedItem;
    },
    isSm() {
      return this.size === 'sm';
    },
    isMd() {
      return this.size === 'md';
    },
    isLg() {
      return this.size === 'lg';
    },
    searchLength() {
      return this.searchText?.length;
    },
    viewModel() {
      if (this.selectedItem) return this.getView(this.selectedItem, true);
      if (this.placeholder) return this.placeholder;
      if (this.size === 'lg' && this.showList?.length) return this.getView(this.showList?.[0]);
      return '';
    },
    searchGap() {
      return 60;
    },
    isParentTarget() {
      return !isBoolean(this.parentFill);
    },
    matchSelect() {
      return this.type.includes('email') && this.showList?.length === 1 && (this.searchText === this.selectedItem?.value || this.searchText === this.selectedItem?.label);
    },
    selectedmodel() {
      return this.type === 'email' ? this.searchText : this.selectedItem;
    },
    isTrim(){
      return this.type === 'email';
    },
    site: state('env', 'site'),
    isMobile: state('browser', 'mobile'),
    myInfo: state('user', 'userInfo'),
    env: state('env', 'env'),
    landingMode: state('query', 'landingMode'),
  },
  watch: {
    value: 'updateModel',
    searchText: 'updateSearch',
    searchValue: 'updateSearchValue',
    basePaddingBottom: 'updatePosition',
    async lang() {
      await this.getList();
      if (this.originList) this.selectedItem = this.originList.find(o => o.value === this.selectedItem.value);
    },
    async list() {
      this.originList = this.list;
      this.showList = this.originList;
      if ((this.autoOpen && this.showList?.length && !this.firstOpen) || (this.showList?.length && (this.type.includes('email') || this.type.includes('address')))) {
        this.firstOpen = true;
        await this.toggle(true);
        this.$el.querySelector('input').focus();
      }
      this.isLoading = false;
    },
    originList() {
      this.updatePosition();
    },
  },
  methods: {
    isSelected(item) {
      return item?.value === this.selectedItem?.value;
    },
    checkToggle(e) {
      e.stopPropagation();
      if (e.target !== this.$el && !e.target?.className.includes('opener') && !e.target.parentNode?.className.includes('search-field')) return;
      this.toggle(null);
    },
    async toggle(on, update = true) {
      if (this.disabled) return;
      if (this.isMd && !this.selectOnly && this.isOn && on === null) return;

      this.isOn = on === null ? !this.isOn : on;
      if (on === null) this.focusIn();
      if (this.isOn) {
        if (!this.isMd && !this.selectOnly && !this.type.includes('email')) this.searchText = '';

        await this.getList();
        await this.updatePosition();
        if (this.selectedItem) this.scrollUpdate(null, this.getTargetId(this.selectedItem.value));
      } else {
        await this.off();
      }
      this.isShow = this.isOn;
    },
    async getList() {
      if (!this.list?.length) return;
      this.originList = this.list;
      this.showList = this.originList.filter(o => o.value);
      this.updateModel();
    },
    async updatePosition() {
      if (!this.isOn || !this.$el) return;

      await sleep(60);
      const parentRect = this.$el.getBoundingClientRect();

      const l = parentRect.left;
      let t = parentRect.top + parentRect.height;
      const parentWidth = (this.parentFill && this.isParentTarget) ? `${this.parentFill?.clientWidth}px` : 'auto';
      const w = this.isParentTarget ? parentWidth : 'auto';
      let h = window.innerHeight - (parentRect.top + parentRect.height + 60);
      this.isBottom = t + h < window.innerHeight && h > 150;
      h = !this.isBottom ? Math.min(parentRect.top - 50, 300) : h;
      this.scrollHeight = `${h - this.searchGap}px`;

      const minHeight = this.isSm ? 50 : 50; // 높이 최소 값
      let height = 0;
      if (!this.showList?.length && this.autoOpen) height = minHeight;
      else {
        const rowCount = this.$el.querySelector('.size-holder')?.children?.length || 0;
        const rowHeight = this.$el.querySelector('.size-holder > button')?.clientHeight || 0;
        height = Math.min(Math.min(h, ((rowCount * rowHeight) || 32) + this.gap), 500);
        height = height < minHeight ? minHeight : height;
        height = this.footerTemplate ? height + 30 : height;
      }

      t = !this.isBottom ? -(height + this.topGap + (this.isSm ? 4 : 2)) : this.$el.clientHeight + 4;
      this.listContainerStyleValue = { top: `${t}px`, height: `${height}px` };
      if (typeof (this.parentFill) === 'object') this.listContainerStyleValue.width = w;
      this.listInnerStyleValue = { height: this.footerTemplate ? 'calc(100% - 30px)' : '100%' };
    },

    scrollUpdate(key, id) {
      if (!this.showList?.length) return;
      let idx = -1;
      if (id >= 0) {
        idx = id;
      } else if (key) {
        const group = this.showList?.filter(o => o.label && o.label.slice(0, 1).toLowerCase() === key.toLowerCase());
        if (!group.length || this.focusId < 0) return;
        const targetId = this.getTargetId(this.showList?.[this.focusId]?.value) || 0;
        if (group.length > 0) {
          const groupId = group.findIndex(o => o.value === this.showList?.[this.focusId]?.value);
          idx = groupId < 0 || targetId + 1 >= this.focusId + (group.length - (groupId)) ? this.getTargetId(group[0]?.value) : targetId + 1;
        } else {
          idx = targetId;
        }
      }

      const scroller = this.$refs.scroller;
      if (idx < 0 || !scroller) return;
      const t = this.$el.querySelector('.size-holder');
      const h = (t.clientHeight - scroller.clientHeight) / (this.showList?.length - 1);
      scroller.scrollTo(0, idx * h);
      this.focusId = idx;
    },
    getView(v, selected) {
      const template = this.template || this.updateTemplate;
      return template(v, selected);
    },
    updateTemplate(v) {
      return v.label;
    },
    updateModel() {
      // 20240718 - EmailSearchSelect 에서, 초기 list 데이타가 없을때, 데이터 싱크를 위하여 추가
      if(this.type === 'email' && !this.showList?.length) this.$emit('input', this.value);
      if (!this.showList?.length || !this.value) return;
      this.selectedItem = this.value;
      if (_.isString(this.selectedItem)) {
        this.selectedItem = this.originList.find(o => this.type.includes('email') ?  o.label === this.selectedItem : o.value === this.selectedItem);
        if (!this.selectedItem) this.selectedItem = this.originList[0];
        // if (!this.selectedItem) this.selectedItem = this.originList.find(o => o.value === this.myInfo?.Country);
      }
      if (!this.selectedItem && this.autoSelect && !this.type.includes('email')) {
        this.selectedItem = this.list?.[0] || this.originList?.[0];
        this.searchText = this.selectedItem.label;
      }

      this.$emit('input', this.selectedmodel);
      if (this.selectOnly) this.searchText = this.selectedItem.label;
    },
    updateSearchValue(v) {
      this.searchText = v;
    },
    updateFocus() {
      this.focusId = this.showList?.findIndex(o => o.value === this.selectedItem?.value);
    },
    async updateItem(item) {
      if (!item) return;
      this.selectedItem = item;
      if (this.isMd) this.searchText = item.Description || item.label;
      if(this.type.includes('email')) {
        this.hold = true;
        this.$emit('input', this.selectedmodel);
        await sleep(100);
        this.hold = false;
      } else {
        this.$emit('input', this.selectedmodel);
      }
      this.updateFocus();
      await this.off();


      // this.cursorEnd();
    },
    async updateSearch() {
      if (this.selectOnly) {
        await sleep(60);
        await this.off();
        return;
      }

      this.isLoading = true;
      this.$emit('updateSearch', this.searchText);

      if (this.searchText?.length) {
        const searchText = this.searchText.toLowerCase();
        this.showList = this.searchHandler ? this.searchHandler(this.originList, searchText) : this.originList?.filter(o => o.CountryName?.toLowerCase().includes(searchText) || o.PhoneCountryCode?.includes(searchText) || o.value?.toLowerCase().includes(searchText));
      } else {
        this.showList = this.originList;
      }

      await sleep(60);
      if (!this.type.includes('address')) this.isLoading = false;
      if (this.isOn) {
        this.updateFocus();
        await this.updatePosition();
      }
      if(this.hold) await this.off();

      if (!this.showList?.length && !this.showNoResult) this.isShow = false;
      //
    },
    async off() {
      this.isOn = false;
      this.isShow = false;
      this.listContainerStyleValue = { height: 0 };

      await sleep(60);
      if (!this.type.includes('email')) {
        if (!this.selectedItem) this.searchText = '';
        else if (this.isMd) this.searchText = this.selectedItem.label;
      }
    },
    getTargetId(value) {
      return this.showList?.findIndex(o => o?.value === value);
    },
    async checkedOpen() {
      if (this.shiftDown || !this.isFocus || this.isOn) return true;
      await this.toggle(true, false);

      await sleep(60);
      this.$el.querySelector('input').focus();
      return false;
    },
    keyDown(e) {
        switch (e.code) {
          case 'Shift':
            this.shiftDown = true;
            return;
          case 'ArrowUp':
            if (!this.checkedOpen()) return;
            if (this.isOn) {
              if (this.$el.querySelector('input')) this.$el.querySelector('input').focus();
              this.focusId = 0 > this.focusId - 1 ? (this.showList?.length - 1 || 0) : this.focusId - 1;
              // this.$emit('focus');
            }
            break;
          case 'ArrowDown':
            if (this.checkedOpen()) {
              if (this.isOn) {
                e.preventDefault();
                if (this.$el.querySelector('input')) this.$el.querySelector('input').focus();
                this.focusId = this.showList?.length - 1 < this.focusId + 1 ? 0 : this.focusId + 1;
              } else {
                this.$emit('blur');
              }
            }
            break;
          case 'Tab':
            if (!this.matchSelect && this.isOn && this.showList?.length) {
              e.preventDefault();
              this.$el.querySelector('input').focus();
              this.focusId = this.showList?.length - 1 < this.focusId + 1 ? 0 : this.focusId + 1;
            } else {
              this.focusOut();
              this.$emit('blur');
            }
            break;
          case 'ArrowRight':
          case 'Enter':
            if(this.type.includes('email') && !this.isOn) return;
            this.updateItem(this.showList?.[this.focusId]);
            break;
          default:
            if (this.isOn && e.key?.length === 1 && !this.searchText?.length) this.scrollUpdate(e.key);
            return;
        }
        if (this.isOn && this.focusId >= 0) this.scrollUpdate(null, this.focusId);
    },
    keyUp(e) {
      switch (e.code) {
        case 'Shift':
          this.shiftDown = false;
          return;
      }
    },
    checkPoint(e) {
      // if(!this.isOn && !this.isFocus) return;

      const mx = e.pageX;
      const my = e.pageY;
      const size = 68;
      const list = this.$el.querySelector('.list-container');
      if(!list) return;
      const pos = this.$el.getBoundingClientRect();
      const posW = pos.x + (my > pos.y + pos.height ? list.clientWidth : this.$el.clientWidth);
      const listH = list.clientHeight;
      const posH = pos.y + (this.isOn ? (this.isBottom ? listH + pos.height : -listH) : size);
      const gap = Array.prototype.find.call(this.$el.parentNode.classList, o => o === 'field') ? 0 : 30;
      const sy = window.scrollY;

      if (mx < pos.x || mx > posW || (this.isBottom && (my - sy < pos.y - gap || my - sy > posH)) || (!this.isBottom && (my - sy > (pos.y + pos.height) + gap || my - sy < posH))) {
        this.off();
        this.$emit('blur');
      } else {
        // this.$emit('focus');
        // this.$el.querySelector('input')?.focus();
      }
    },
    eventControl(remove) {
      if (remove) {
        document.body.removeEventListener('mousedown', this.checkPoint);
        window.removeEventListener('resize', this.updatePosition);
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('keyup', this.keyUp);
      } else {
        document.body.addEventListener('mousedown', this.checkPoint);
        window.addEventListener('resize', this.updatePosition);
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('keyup', this.keyUp);
      }
    },
    focusIn() {
      this.$emit('focus');
      this.isFocus = true;
      if (!this.selectOnly) {
        // if(this.isMd) this.cursorEnd();
        // this.$el.querySelector('input').focus();
        // this.$emit('blur');
      } else {
      }
    },

    cursorEnd() {
      const t = this.$el.querySelector('input');
      if(t) {
        t.focus();
        t.setSelectionRange(t.value.length, t.value.length);
      }
    },
    async focusOut() {
      this.isFocus = false;
      if (!this.isShow || !this.searchText?.length) this.$emit('blur');
    }
  },
  async mounted() {
    /** For Caching List */
    await this.getList();
    if (this.searchValue) this.updateSearchValue(this.searchValue);
    this.eventControl(false);
    this.$el.addEventListener('click', this.checkToggle);
  },
  beforeDestroy() {
    this.eventControl(true);
    // this.$el.removeEventListener('click', this.checkToggle);
    this.$el.addEventListener('click', this.checkToggle);
  },
};
</script>

<style lang="less">
@import '@/less/proj.less';
.RTL {
  [search-drop-select] {
    > a {
      [font-icon] { .r(auto); .l(16); }
      > label { .tr(); .w(100%); }
    }
    .list-container {
      .list-inner {
        .scroll-holder {
          em { .tr(); }
        }
      }
    }
  }
}
.GOLD {
  [search-drop-select] {
    > a { .bgc(@c-w01); }
  }
}
[search-drop-select] { .rel(); .h(100%);
  &.lg:focus {
    > a { .-a(@c-yellow, 2); }
  }
  > a { .br(8); .bgc(#1e1e1e); box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(0, 0, 0) inset; .block(); .c(white); .fs(18); .flex(); .items-center(); .h(64); .p(6, 20, 6, 12); .-box(); .w(100%); .rel();
    > label { .medium();
      > em { .fs(18); }
      img { .mb(4); }
    }
    [font-icon] { .fs(16); .abs(); .rt(16, 50%); .t-yc();
      path { .fill(white); }
      &.on { .t-r(180deg); .mt(-8); }
    }
  }
  .list-container { box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5); .bgc(#1e1e1e); .br(8); .br-t(0); .abs(); .o(0); .crop(); .w(100%); .max-h(500); .h(0); .l(-9999);
    &.fill { .w(var(--innerWidth)) !important; }
    &.on { .o(1); .l(0); .z(10);
      .list-inner {
        .scroll-holder { pointer-events: auto; .z(10);}
      }
    }
    .list-inner { .pb(12); .hf();
      .input-holder { .p(10, 12); }
      .scroll-holder { overflow-y: auto; .h(calc(100% - 54px)); pointer-events: none; .rel(); .z(-1);
        &.full { .h(100%); }
        label { .block(); .p(10, 16); .w(100%); .c(#999); .tl(); }
        button { .flex(); .p(10, 16); .w(100%); .c(white); .tl();
          &:hover, &:focus, &.focus { .bgc(@c-b02); }
          &.active { .bgc(@c-b04); }
          em { .fs(14); .w(100%); }
          .icn-chevron-right { margin: 0 0 0 auto; }
        }
      }
      .empty { .fs(14); .p(10, 12); .rel(); .h(100%);
        [font-icon] { .block() ; .w(white); .abs(); .lt(50%, 4); .fs(20); .t-xyc(); animation: rotate-circle 2s linear infinite; }
      }
    }
    .list-footer > img { .flex(); .mr(15); .ml(auto); }
  }
  &.md {
    .list-container {
      .list-inner {
        .scroll-holder { .h(100%); }
        .empty {
          [font-icon] { .t(40%); }
        }
      }
    }
  }
  &.sm, &.md {
    > a { .bgc(transparent) !important; box-shadow: none; .-a(); .p(0, 12); .h(36);
      > [text-input] {.-a(); box-shadow: none; .w(100%);
        input { .pl(0); }
      }
    }
    .list-container {
      .list-inner { .fs(14);
      }
    }
  }
  [text-input] {
    &.select-only { pointer-events: none; }
    &.inside-input {
      input::placeholder { .fs(14); }
    }
    input { .fs(14, 20); .pb(4);}
  }
  .placeholder { .c(@c-w03); .regular(); }

  &.disabled{
    >a {cursor:default;}
  }
}
</style>
