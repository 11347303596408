<template>
  <GpFormTemplate v-if="load" automatic-reserve>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
    </template>
    <template #content>
      <InputAutomaticReserveWin :model="model" :currency-symbol="currencySymbol" :current="current" />
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="openModal" :disabled="disabled">Submit</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import ResponsibleRadioItem from '@/views/components/pages/responsible-gaming/template/common/ResponsibleRadioItem.vue';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import InputAutomaticReserveWin from '@/views/components/pages/responsible-gaming/template/inputs/InputAutomaticReserveWin.vue';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import Specific from '@shared/types/Specific';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';

export default {
  name: 'RgAutomaticReserveWin',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputAutomaticReserveWin, GpButton, GpFormTemplate, GpDescription },
  props: {
    structure: { type: Specific, default: null },
    step: { type: String, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      selectedValue: null,
      currencySymbol: null,
      current: {
        Currency: null,
        CurrentReserveWinLimit: null,
        ReserveWinLimitApplyAt: null,
        ExceededWinningAmount: null,
      },
      model: {
        RequestReserveWinLimit: null,
      },
      load: false,
      ResponsibleRadioItem,
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'default', current: this.current.CurrentReserveWinLimit, request: this.model.RequestReserveWinLimit, noLimit: this.noLimit, valueType: 'amount', currencySymbol: this.currencySymbol },
      ];
    },
    noLimit() {
      return this.model.RequestReserveWinLimit ? false : true;
    },
    subDescription() {
      return this.$t('_.subDescription.automaticReserveWin', { min1: `${this.current.Currency} 10`, min2: `${this.currencySymbol} 10` });
    },
    disabled() {
      return +this.current.CurrentReserveWinLimit === +this.model.RequestReserveWinLimit;
    }
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current
      };
      if (!this.current.CurrentReserveWinLimit) this.current.CurrentReserveWinLimit = 'None';
      else {
        this.model.RequestReserveWinLimit = this.current.CurrentReserveWinLimit;
      }
      this.currencySymbol = getCurrencySymbol(this.current.Currency) || '$';
      this.load = true;
    },
    async request() {
      const payload = this.model.RequestReserveWinLimit === null ? {} : this.model;
      return await this.$rg.request(payload);
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      return {
        title: this.$t('_.modal.title'),
        subTitle: this.$t(`_.title.${this.structure.config.title}`),
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel: descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze
      };
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },
  },
  async mounted() {
    await this.initialize();
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[automatic-reserve] {
  .desc { .pb(20);
    > p {.c(#999); .fs(12); line-height: 16px;}
  }
  .center { text-align: center; }
  .left { text-align: left}
  .limit-input-group {.flex(); .flex-dc(); .gap(15); .br(4); .pv(20); }
  .row {.flex(); .flex-dr(); .bgc(#f8f8f8); .br(4); .p(20);
    .label {flex: 1; .c(black); .fs(14);}
  }
}

</style>

