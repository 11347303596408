<template>
  <component gp-state-component :is="component" :disabled="disabled" :value="model" @input="input" :placeholder="placeholder" :hidden-icon="hiddenIcon" />
</template>

<script>

import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import StateSearchSelect from '@/views/components/pages/on-boarding/StateSearchSelect.vue';

export default {
  name: 'StateComponent',
  props: {
    value: { type: Specific, default: null },
    disabled: { type: Boolean, default: false, },
    placeholder: { type: String, default: null, },
    hiddenIcon:{ type: Boolean, default : false},
  },
  data(){
    return {
      model : null,
      stateList : null,
      StateSearchSelect,
      TextInputBox
    }
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    useDropDownStateCountries(){
      if(this.isWSOP) return false;
      return ['CA', 'MX', 'US'].includes(this.userInfo.Country)
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    component(){
      if(this.useDropDownStateCountries) return StateSearchSelect
      return TextInputBox
    }
  },
  methods: {
    input(v){
      this.model = v;

      if(this.useDropDownStateCountries) this.$emit('input', v?.label);
      else this.$emit('input', v);
    }
  },
  async mounted() {
    this.$nextTick(async ()=>{
      if(this.useDropDownStateCountries){
        this.stateList = (await this.$services.base.getStateList({ SiteId: this.site })).map(o => ({ label: o.StateName, value: o.StateCode }));
        this.model = this.stateList?.find(item => [item.label, item.value].includes(this.value));
      }else this.model = this.value;
    })

  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-state-component] {
  .list-container { .pb(55);}
}
</style>