import { siteIds, baseInfo, envDomainList } from '@/constants/base/siteMap';
import _ from 'lodash';

export const regionsKeys = {
  GLOBAL: 'ggpcom-global',
  BR: 'ggpcom-br',
  UA: 'ggpcom-ua',
  ES: 'ggpcom-es',
  EU: 'ggpcom-eu',
  PL: 'ggpcom-pl',
  HU: 'ggpcom-hu',
  FI: 'ggpcom-fi',
  CW: 'ggpcom-cw',
  BENL: 'ggpcom-be-nl',
  BEFR: 'ggpcom-be-fr',
  NLNL: 'ggpcom-nl-nl',
  NLEN: 'ggpcom-nl-en',
  DE: 'ggpcom-de',
  RO: 'ggpcom-ro',
  CZ: 'ggpcom-cz',
  UK: 'ggpoker-uk',
  PH: 'ggpcom-ph',
  CIS: 'ggpokerok-cis',
  GGPUKE: 'ggpuke-ggpuke', // 확인 중 (현재 안씀) https://docs.google.com/spreadsheets/d/1inp0dPEoX-jy-odk1nFFl7qm1VWs7OTQt1cLskQzBeg/edit#gid=0
  WSOP: 'ggpokerca',
  OCNP: 'ocean-bet'
};

export const channelingMap = {
  eapt: 'EAPT',
  roadtovegas: 'Road to Vegas',
  'wsop-paradise': 'WSOP Paradise',
  dtd: 'DTD',
  ocean : 'OCEAN',
};

const defaultSupportRegions = [regionsKeys.GLOBAL, regionsKeys.UK, regionsKeys.EU, regionsKeys.PL, regionsKeys.HU, regionsKeys.FI, regionsKeys.BENL, regionsKeys.BEFR, regionsKeys.NLNL, regionsKeys.NLEN, regionsKeys.BR, regionsKeys.UA, regionsKeys.ES, regionsKeys.CIS, regionsKeys.WSOP, regionsKeys.PH];

const getFirstUrl = domains => domains.split(',')[0];
const isProd = baseInfo.VUE_APP_ENV === 'production';
const ggpComUrls = envDomainList[siteIds.GGPCOM].split(',');
const signupUrls = /** @type {{'com','de','eu','be','nl','ro','cz', 'ph', 'uk','cis','ca','puke'}} */{};
['com', 'de', 'eu', 'be', 'nl', 'ro', 'cz', 'ph'].forEach(o => {
  signupUrls[o] = isProd ? ggpComUrls.find(v => v.includes(`.${o}`)) : ggpComUrls[0];
});
signupUrls.ph = getFirstUrl(envDomainList[siteIds.GGPPH])
signupUrls.uk = getFirstUrl(envDomainList[siteIds.GGPUK]);
signupUrls.cis = getFirstUrl(envDomainList[siteIds.GGPOK]);
signupUrls.ca = getFirstUrl(envDomainList[siteIds.WSOPON]);
signupUrls.puke = envDomainList[siteIds.GGPUKE];

export const regionMap = {
  [regionsKeys.GLOBAL]: { label: 'Global', signUpUrl: signupUrls.com, siteIds: siteIds.GGPCOM, lang: 'en', footer: 'ggpcom_global', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.BR]: { label: 'GGPoker BR', signUpUrl: signupUrls.com, siteIds: siteIds.GGPBR, lang: 'pt', footer: 'ggpcom_cw', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.UA]: { label: 'GGPoker UA', signUpUrl: signupUrls.com, siteIds: siteIds.GGPUA, lang: "uk-ua", footer: 'ggpcom_ua', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.ES]: { label: 'GGPoker ES', signUpUrl: signupUrls.com, siteIds: siteIds.GGPCOM, lang: 'es', footer: 'ggpcom_es', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.EU]: { label: 'GGPoker EU', signUpUrl: signupUrls.eu, siteIds: siteIds.GGPEU, lang: 'en', footer: 'ggpcom_eu', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.PL]: { label: 'GGPoker PL', signUpUrl: signupUrls.eu, siteIds: siteIds.GGPPL, lang: 'pl', footer: 'ggpcom_cw', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.HU]: { label: 'GGPoker HU', signUpUrl: signupUrls.eu, siteIds: siteIds.GGPHU, lang: 'hu', footer: 'ggpcom_hu', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.FI]: { label: 'GGPoker FI', signUpUrl: signupUrls.eu, siteIds: siteIds.GGPFI, lang: 'fi', footer: 'ggpcom_fi', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.BENL]: { label: 'GGPoker BE-NL', signUpUrl: signupUrls.be, siteIds: siteIds.GGPBE, lang: 'nl', footer: 'ggpcom_be_nl', supportRegions: [regionsKeys.BENL, regionsKeys.BEFR], excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.BEFR]: { label: 'GGPoker BE-FR', signUpUrl: signupUrls.be, siteIds: siteIds.GGPBE, lang: 'fr', footer: 'ggpcom_be_fr', supportRegions: [regionsKeys.BENL, regionsKeys.BEFR], excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.NLNL]: { label: 'GGPoker NL-NL', signUpUrl: signupUrls.nl, siteIds: siteIds.GGPNL, lang: 'nl', footer: 'ggpcom_nl_nl', supportRegions: [regionsKeys.NLNL, regionsKeys.NLEN], excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.NLEN]: { label: 'GGPoker NL-EN', signUpUrl: signupUrls.nl, siteIds: siteIds.GGPNL, lang: 'en', footer: 'ggpcom_nl_en', supportRegions: [regionsKeys.NLNL, regionsKeys.NLEN], excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.DE]: { label: 'GGPoker DE', signUpUrl: signupUrls.de, siteIds: siteIds.GGPDE, lang: 'de', footer: 'ggpcom_de', supportRegions: [regionsKeys.DE] },
  [regionsKeys.RO]: { label: 'PLAYGG RO', signUpUrl: signupUrls.ro, siteIds: siteIds.GGPRO, lang: 'ro', footer: 'ggpcom_ro', supportRegions: [regionsKeys.RO] },
  // [regionsKeys.CZ]: { label: 'GGPoker CZ', signUpUrl: signupUrls.com, siteIds: siteIds.GGPCZ, lang: 'cs', footer: 'ggpcom_cz', supportRegions: [regionsKeys.CZ] },
  [regionsKeys.PH]: { label: 'GGPoker PH', signUpUrl: signupUrls.ph, siteIds: siteIds.GGPPH, lang: 'en', footer: 'ggpcom_ph', supportRegions: [regionsKeys.PH] },
  [regionsKeys.UK]: { label: 'GGPoker UK', signUpUrl: signupUrls.uk, siteIds: siteIds.GGPUK, lang: 'en', footer: 'ggpoker_uk', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.CIS]: { label: 'GGPoker CIS', signUpUrl: signupUrls.cis, siteIds: siteIds.GGPOK, lang: 'ru', footer: 'ggpokerok_cis', supportRegions: [] },
  [regionsKeys.GGPUKE]: { label: 'GGPuke', signUpUrl: signupUrls.puke, siteIds: siteIds.GGPUKE, lang: 'zh-cn', footer: 'ggpuke_ggpuke', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.WSOP]: { label: 'GGPoker CA', signUpUrl: signupUrls.ca, siteIds: siteIds.WSOPON, lang: 'en', footer: 'wsop_wsopca', supportRegions: [regionsKeys.WSOP] },
  [regionsKeys.UK]: { label: 'GGPoker UK', signUpUrl: signupUrls.uk, siteIds: siteIds.GGPUK, lang: 'en', footer: 'ggpoker_uk', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
  [regionsKeys.OCNP]: { label: 'GGPoker Ocean', signUpUrl: signupUrls.com, siteIds: siteIds.OCNP, lang: 'en', footer: 'ggpoker_uk', excludeRegions: [regionsKeys.PH, regionsKeys.CIS] },
};

export const channelingSiteMap = regionKey => {
  const info = { ...regionMap[regionKey] };
  info.supportRegions = info.supportRegions || defaultSupportRegions;
  info.footerContent = `signUp.footer.channeling.${info.footer}`;
  if(regionMap[regionKey].excludeRegions && regionMap[regionKey].excludeRegions.length > 0) regionMap[regionKey].excludeRegions.forEach(excludeRegion => _.remove(info.supportRegions, o => o === excludeRegion));
  return info;
};

export const getRegion = ({ site, lang }) => {
  if (site === siteIds.GGPCOM && lang === 'es') return regionsKeys.ES;
  else if (site === siteIds.GGPBE && lang === 'fr') return regionsKeys.BEFR;
  else if (site === siteIds.GGPNL && lang === 'en') return regionsKeys.NLEN;
  else return Object.keys(regionMap).find(key => {
      const region = regionMap[key];
      return region.siteIds === site;
    });
};