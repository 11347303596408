<template>
  <div bonus-status>
    <TermsAndConditionsLink :status-info="statusInfo" />
    <div class="title">
      <h2>{{ name }}</h2>
      <button @click="$emit('reload')">
        <FontIcon name="rotate-right" />
      </button>
      <StatusBadge :status="status" />
    </div>
    <p class="date" v-if="period">{{ period }}<b v-if="isInProgress">{{ $t('_.daysLeft', { numberOfDays: leftDays }) }}</b></p>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import StatusBadge from '@/views/components/pages/promotion/v1/StatusBadge.vue';
import TermsAndConditionsLink from '@/views/components/pages/promotion/v1/TermsAndConditionsLink.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { state } from '@shared/utils/storeUtils';
import { toDurationDays, toFullDate } from '@/utils/dateTimeUtil';

export default {
  name: 'BonusStatus',
  lexicon: 'promotion.v1',
  components: { StatusBadge, TermsAndConditionsLink, FontIcon },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, default: null },
  },
  computed: {
    site: state('env', 'site'),
    isReferral() {
      return this.statusInfo.promotionName === 'Referral Promotion Bonus';
    },
    statusItem() {
      return /** @type {PromotionStatusItem} */ this.statusInfo?.item || {};
    },
    name() {
      const name = this.isReferral? this.statusInfo.promotionName : this.statusItem.name;
      switch(name) {
        case 'Match Bonus':
          return this.$t('_.promotionName.matchBonus');
        case 'Free Bonus':
          return this.$t('_.promotionName.freeBonus');
        case 'Sign-up Bonus':
          return this.$t('_.promotionName.signupBonus');
        case 'Referral Promotion Bonus':
          return this.$t('_.promotionName.referralPromotionBonus');
        default:
          return name;
      }
    },
    status() {
      if (this.isReferral) return this.statusInfo.status;
      return this.statusItem.playerPromotionStatus;
    },
    period() {
      if (this.isReferral) return;
      const start = this.statusItem.startDate;
      const end = this.statusItem.endDate;
      const locale = this.$i18n('locale');

      if (toDurationDays(start, end) < 1) return;
      if (this.statusItem.playerPromotionStatus === 'Created') return '-';
      return `${toFullDate(start, locale)} - ${toFullDate(end, locale)}`;
    },
    leftDays() {
      if (this.isReferral) return;
      return this.statusItem.leftDays;
    },
    isInProgress() {
      if (this.isReferral) return;
      return this.statusItem.playerPromotionStatus === 'InProgress';
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[bonus-status] { .p(20); .bgc(@c-bg); .fs(16);
  .title { .flex(); .flex-wrap(); .gap(12); .items-center(); .mb(8);
    h2 { .ib(); .fs(18); }
    button { .bgc(); .fs(inherit);
      [font-icon] { .c(@c-dimGrey);}
    }
  }
  .date { .flex(); .gap(16); .fs(14); .c(@c-veryLightGrey);
    b { .c(@c-blurWhite); }
  }
}
</style>
