<template>
  <div proof-of-identity>
    <p class="desc">
      {{ description }}<br />
    </p>
    <DescriptionHolder :list="example" transparent />
    <FileSelector @update="fileUpdate" ref="selector" drag-input>
      <p v-html="$t('kyc.dropFiles', { fileLength: maxFileLength, fileSize: maxFileSize })" />
    </FileSelector>
    <p class="add-document">{{ $t('kyc.addDocument') }}</p>
    <div class="list-holder" v-if="fileList && fileList.length">
      <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" :selectList="poiList" @remove="remove(id)" />
    </div>
    <ProgressButton button-name="upload" :disabled="!verifyDocument" @click="upload" />
  </div>
</template>

<script>
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getKycDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import FileSelector from '@/views/components/pages/on-boarding/FileSelector.vue';
import UploadErrorModal from '@/views/components/pages/on-boarding/modal/UploadErrorModal.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { siteIds } from '@/constants/base/siteMap';
import DescriptionHolder from '@/views/components/common/DescriptionHolder.vue';

export default {
  name: 'ProofOfIdentity',
  components: { DescriptionHolder, ProgressButton, UploadItem, FileSelector },
  lexicon: 'kyc.proofOfIdentity',
  props: {
    item: { type: Specific, default: null },
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
  },
  data() {
    return {
      fileList: null,
      uid: 0,
      maxFileLength: 5,
      maxFileSize: 10,
      isFromWithdrawal: false, // @todo: withdrawal(출금) 화면에서 접근한건지 체크
    };
  },
  computed: {
    site: state('env', 'site'),
    poiDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Default');
    },
    poiMandatoryKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Mandatory');
    },
    verifyDocument() {
      const emptySelectFile = !_.every(this.fileList, f => f.type && f.type !== '0');
      const existDefaultFile = _.some(this.poiDefaultKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));
      const existMandatoryFile = _.every(this.poiMandatoryKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));
      if(this.fileList?.length && !emptySelectFile){
        if(this.poiDefaultKycDocumentList?.length && this.poiDefaultKycDocumentList?.length > 0){
          return existDefaultFile && existMandatoryFile;
        }else{
          return existDefaultFile || existMandatoryFile;
        }
      }else{
        return false;
      }
      // return this.fileList?.length && !emptySelectFile && existDefaultFile && existMandatoryFile;
    },
    poiList() {
      return [...(this.poiDefaultKycDocumentList ?? []), ...(this.poiMandatoryKycDocumentList ?? [])];
    },
    description() {
      return this.site === siteIds.GGPCZ ? this.$t('_.descriptionCz') : this.$t('_.description');
    },
    example() {
      if ([siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.TWOACE].includes(this.site)) return [this.$t('_.documentTypesOk')];
      if (this.site === siteIds.GGPCZ) return [this.$t('_.documentTypesCz1'), this.$t('_.documentTypesCz2')];
      if (this.site === siteIds.N8IN && this.isFromWithdrawal) return [this.$t('_.nationalId'), this.$t('_.aadharCard')];
      return [this.$t('_.documentTypes')];
    },
    isDocumentList() {
      /** GGPCZ 이거나 N8IN의 출금화면에서 접근한 POI example document 는 2개다. */
      return this.example.length === 2;
    },
  },
  methods: {
    /**
     * Kyc Document 정보를 Label, Value 형태로 변환
     * @param {KycDocumentGroup} kycDocumentGroup - Kyc Document
     * @param type - Default, Mandatory
     * @returns {{label: *, value: *}[]|*[]}
     */
    convertKeyValue(kycDocumentGroup, type) {
      if (!kycDocumentGroup) return [];
      const documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (!documents) return [];

      const kycDocuments = getKycDocuments(this.site);
      return documents.map(document => {
        const kycDocument = kycDocuments.find(type => type.value === document);
        return kycDocument ? { label: kycDocument?.label, value: kycDocument?.value, description: kycDocument?.description } : null;
      });
    },
    /**
     * 올려야 할 Kyc document 중 Type(Poi, Poa) 별 정보 가져오기
     * @param {KycDocumentGroup[]} kycDocumentGroups - 업로드 필요한 Kyc document group
     * @param {string} type - Kyc document Type(Poi, Poa)
     * @returns {*}
     */
    findKycDocumentGroup(kycDocumentGroups, type) {
      return kycDocumentGroups?.find(group => group.KycDocumentGroupType === type);
    },
    fileUpdate(list) {
      this.fileList = list;
      this.uid++;
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
    async upload() {
      const info = /** @type { ErrorResponse[] } */ await Promise.all(this.fileList.map(async o => this.$services.kyc.setAddKycDocument({ DocumentType: o.type, FileData: o.img, FileName: o.file.name })));
      const documentTypes = this.fileList.filter((o, id) => !info[id].error).map(o => o.type);
      if (documentTypes && documentTypes.length > 0) await this.$services.kyc.setKycRequisition(documentTypes.join(','));
      if (info.find(o => o.error)) {
        const r = await this.$modal(UploadErrorModal, { info: this.fileList.map((o, id) => ({ ...o, status: info[id] })) });
        if (!r) return;
      }
      this.$emit('update');
    }
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.proofIdentity';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[proof-of-identity] {
  .desc{ word-break: break-word; }
  [description-holder] { .mt(12); .pl(16);}
  .add-document { .c(#ccc); .m(20, 0); }
}
</style>
