<template>
  <div bonus-ticket :class="{active, twoace: site === 'TWOACE'}">
    <div class="ticket-box">
      <img v-if="active" :src="`${cdnImgPath}/promotion-ticket-active.png`" alt="ticket">
      <img v-else :src="`${cdnImgPath}/promotion-ticket.png`" alt="ticket">
      <div class="ticket">
        <div class="num">{{ info.ticketCount }}</div>
        <div class="value">{{ ticketValue }}</div>
        <div class="text">{{ ticketText }}</div>
      </div>
    </div>
    <p class="name" v-if="pageName !== 'Freebie'">{{ ticketName }}</p>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'BonusTicket',
  lexicon: 'promotion.v1',
  props: {
    info: { /** @type {DailyBonusTicket} */ type: Specific, required: true },
    active: { type: Boolean, default: false },
  },
  computed: {
    site: state('env', 'site'),
    pageName() { return this.$route.name; },
    ticketText() {
      if (this.pageName !== 'Freebie') return this.$t('_.ticket');
      const splitRe = /(^\D+)(\s\$[0-9.]+$)/;
      const gameType = (this.info?.ticketName || '').replace(splitRe, '$1');
      return gameType === 'Ticket' ? this.$t('promotion.ticket') : gameType;
    },
    ticketName() {
      const name = this.info.ticketName;

      switch (this.site) {
        case 'WSOPON':
          return name.replace('CA$', '$');
        case 'N8IN':
          return name.replace('$', '');
        default:
          return name;
      }
    },
    ticketValue() {
      const info = this.info;
      if (this.site === 'TWOACE') return `${info.ticketDisplayValue}${this.$t('_.million')}`;
      const currency = info.ticketCurrency;
      const value = info.ticketValue ?? info.ticketDisplayValue ?? info.ticketValueInUSD;
      return this.$promotion.getCurrencyString(currency, value);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
.RTL  {
  [bonus-ticket] img { transform: scaleX(-1); }
}
[bonus-ticket] { .fs(16);
  + [bonus-ticket] { .mt(10); }
  .ticket-box { .rel();
    img { .rel(); .wf(); }
    .ticket { .abs(); .t(0); .l(0); .wf(); .hf(); .grid(); .grid-template-columns(30% 70%); .justify-center(); .items-center(); .fs(0.75em); .tc(); .o(0.5); }
    .num { .grid-row(span 2); .fs(1.25em); .flex(); .justify-center(); .items-center();
      &:after { .cnt('x'); .fs(0.8em); .ml(0.3em;) }
    }
    .value { .c(@c-yellow); padding-top: 0.2em; }
  }
  &.active {
    .ticket-box {
      .ticket { .o(1); }
    }
  }
  .name { .mt(2); .fs(0.6875em); .c(@c-grey55); }
}
</style>
