<template>
  <div free-bonus v-if="isReady">
    <div v-if="isEmpty(freeBonusInfo)" no-active-container>
      <p>{{ $t('promotion.noActiveBonus') }}</p>
    </div>
    <template v-else>
      <PromotionTitle type="freeBonus" @reset="updateInfo" />
      <div color-container>
        <Slider ref="refSlider" :info="freeBonusInfo" slides-per-view="1" :pagination="showPagination" pagination-type="custom" :pagination-nav="showPagination" prevent-touch-move only-show-now-page>
          <template v-slot:slide="{info}">
            <ContentsContainer :info="info">
              <FreeBonusComponent :info="info" />
            </ContentsContainer>
          </template>
          <template v-slot:pagination="{info, idx}">
            <p>{{ idx + 1 }}</p>
          </template>
        </Slider>
      </div>
      <BonusHistory :info="freeBonusInfo" :uid="uid" />
    </template>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Slider from '@shared/components/general/Slider.vue';
import { isEmpty } from 'lodash';
import BonusItem from '@/views/components/pages/promotion/BonusItem.vue';
import PromotionTitle from '@/views/components/pages/promotion/PromotionTitle.vue';
import BonusSection from '@/views/components/pages/promotion/BonusSection.vue';
import BonusSlider from '@/views/components/pages/promotion/BonusSlider.vue';
import ContentsContainer from '@/views/components/pages/promotion/ContentsContainer.vue';
import StatusBadge from '@/views/components/pages/promotion/StatusBadge.vue';
import PromotionTimer from '@/views/components/pages/promotion/PromotionTimer.vue';
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import ProgressBar from '@/views/components/pages/promotion/ProgressBar.vue';
import FreeBonusComponent from '@/views/components/pages/promotion/FreeBonusComponent.vue';

export default {
  name: 'FreeBonus',
  components: { FreeBonusComponent, ProgressBar, BonusHistory, PromotionTimer, StatusBadge, ContentsContainer, BonusSlider, BonusSection, PromotionTitle, BonusItem, Slider, FontIcon },
  data() {
    return {
      freeBonusInfo: null,
      history: null,
      isReady: false,
      uid: 0,
    };
  },
  computed: {
    showPagination() {
      return this.freeBonusInfo?.length > 1;
    },
  },
  methods: {
    async updateInfo() {
      ++this.uid;
      const r = await this.$services.promotion.getFreeBonus();
      if (r && !r?.error) this.freeBonusInfo = r;
    },
    async initialize() {
      this.$services.modal.block();
      await this.updateInfo();
      this.$services.modal.unblock();
      this.isReady = true;
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>


<style lang="less">
@import "@/less/tpm";
[slider] .swiper-pagination { .static(); .justify-end(); .ph(20); .c(@c-gray1);
  .pagination-item { .w(28); }
  .pagination-nav { .c(inherit);
    button { .wh(28); .bgc(@c-gray8); }
  }
}
</style>
<style scoped lang="less">
@import "@/less/tpm";
[free-bonus] {
  [color-container] { .bgc(@c-bgGray); .pb(20) }
  [no-active-container] { .flex(); .justify-center(); .items-center(); .wf(); .bgc(@c-bgGray); .min-h(100); }
}
</style>
