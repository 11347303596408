<template>
  <SectionContainer no-promotion-data>
    <template #contents v-if="isUnablePromotion">
      <p>{{ $t('promotion.noActiveBonus') }}</p>
    </template>
    <template #contents v-else>
      <TermsAndConditionsLink :status-info="statusInfo" />
      <p>{{ $t('_.firstDepositAvailable') }}</p>
      <a class="promotion-button" :href="claimUrl" target="_blank">{{ $t('_.claimNow') }}</a>
    </template>
  </SectionContainer>
</template>

<script>
import Specific from '@shared/types/Specific';
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import TermsAndConditionsLink from '@/views/components/pages/promotion/v1/TermsAndConditionsLink.vue';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'NoPromotionData',
  lexicon: 'promotion.v1',
  components: { TermsAndConditionsLink, SectionContainer },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, default: null },
  },
  computed: {
    language: state('query', 'selectedLang'),
    token: state('user', 'token'),
    isMobile: state('browser', 'mobile'),
    site: state('env', 'site'),
    isUnablePromotion() {
      return !this.statusInfo?.isEnablePromotion;
    },
    claimUrl() {
      const queryString = `&lang=${this.language}${this.isMobile ? '&ViewType=mobileType' : ''}`;
      const widgetUrl = process.env[`VUE_APP_WIDGET_URL_${this.site}`];
      return `${widgetUrl}/Cashier?token=${this.token}${queryString}`;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotionButton.less";
[no-promotion-data] { .flex(); .flex-dc(); .justify-center(); .items-center(); .rel(); .min-h(270); .pv(0); .c(@c-dimGrey); .tc();
  [terms-and-conditions-link] { .abs(); .t(20); .r(16); }
  .promotion-button { .mt(20); }

  @media (@tp-up) { .min-h(200); }
}
</style>
