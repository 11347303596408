<template>
  <div desired-limit>
    <ValidationProvider v-slot="{errors}" rules="required">
      <input type="hidden" v-model="valid">
      <div class="form-holder">
        <ValidationComponent :mode="validationMode" :label="$t('monthly')" name="_monthly" :component="TrimTextInput" showCurrencySymbol rules="requiredDesiredLimit:1,9" :preset="desiredLimitPreset" v-model="modelInfo.DesiredLimitAmountMonthly" @input="updateDesiredLimit" />
        <ValidationComponent :mode="validationMode" :label="$t('weekly')" name="_weekly" :component="TrimTextInput" showCurrencySymbol rules="requiredDesiredLimit:1,9" :preset="desiredLimitPreset" v-model="modelInfo.DesiredLimitAmountWeekly" @input="updateDesiredLimit" />
        <ValidationComponent :mode="validationMode" :label="$t('daily')" name="_daily" :component="TrimTextInput" showCurrencySymbol rules="requiredDesiredLimit:1,9" :preset="desiredLimitPreset" v-model="modelInfo.DesiredLimitAmountDaily" @input="updateDesiredLimit" />
      </div>
      <ValidationErrors v-if="desiredLimitErrorMsg" class="error-msg" :errors="errors" :error-msg="desiredLimitErrorMsg" />
    </ValidationProvider>
  </div>
</template>

<script>
import { makeRules } from '@/plugins/validate';
import { sleep } from '@shared/utils/commonUtils';
import { state } from '@shared/utils/storeUtils';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'DesiredLimit',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  props:{
    isReverse: { type: Boolean, default: false }
  },
  data() {
    return {
      rules: null,
      desiredLimit: { daily: null, weekly: null, monthly: null },
      valid: false,
      desiredLimitErrorMsg: null,
      TrimTextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    desiredLimitPreset() {
      return { placeholder: this.$t('kyc.enhancedDueDiligence.desiredLimitPlaceHolder'), digit: true, inputMode: 'numeric', pattern: '[0-9]*', maxLength: 12 };
    },
    desiredLimitDayErrorMsg() {
      return !this.rules.requiredDesiredLimit.validate(this.modelInfo.DesiredLimitAmountDaily, { min: 1, max: 9 }) ? this.$tv({ _rule_: 'requiredDesiredLimit' }) : null;
    },
    desiredLimitWeeklyErrorMsg() {
      return !this.rules.requiredDesiredLimit.validate(this.modelInfo.DesiredLimitAmountWeekly, { min: 1, max: 9 }) ? this.$tv({ _rule_: 'requiredDesiredLimit' }) : null;
    },
    desiredLimitMonthlyErrorMsg() {
      return !this.rules.requiredDesiredLimit.validate(this.modelInfo.DesiredLimitAmountMonthly, { min: 1, max: 9 }) ? this.$tv({ _rule_: 'requiredDesiredLimit' }) : null;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    validationMode() {
      return this.site === siteIds.GGPNL ? 'lazy' : 'aggressive';
    }
  },
  methods: {
    async updateDesiredLimit() {
      try {
        const inputFields = this.$el.querySelectorAll('.form-holder .input-field');
        const pass = Boolean(this.modelInfo.DesiredLimitAmountDaily > 0 && this.modelInfo.DesiredLimitAmountWeekly > 0 && this.modelInfo.DesiredLimitAmountMonthly > 0);

        await sleep(10);

        this.valid = pass && !this.$el.querySelector('.error-msg.error');
        inputFields.forEach(field => (this.valid ? field.classList.remove('error') : field.classList.add('error')));
        this.desiredLimitErrorMsg = this.desiredLimitDayErrorMsg || this.desiredLimitWeeklyErrorMsg || this.desiredLimitMonthlyErrorMsg;
      } catch (e) { /* empty */ }
    },
  },
  async mounted() {
    this.rules = makeRules();
    this.valid = Boolean(this.modelInfo.DesiredLimitAmountDaily > 0 && this.modelInfo.DesiredLimitAmountWeekly > 0 && this.modelInfo.DesiredLimitAmountMonthly > 0);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[desired-limit] {
  > div .form-holder { .flex(); .flex-dc(); .space-between(); .gap(10);
    > [validation-component] {
      [trim-text-input] > input { .tr(); }
      [validation-errors] { .hide(); }
    }
    > [validation-component] + [validation-component] { .mt(0); }
  }

  @media (@mm-up) {
    > div .form-holder { .flex-dr(); }
  }
}
</style>
