import { render, staticRenderFns } from "./NoPromotionData.vue?vue&type=template&id=822a0120&scoped=true"
import script from "./NoPromotionData.vue?vue&type=script&lang=js"
export * from "./NoPromotionData.vue?vue&type=script&lang=js"
import style0 from "./NoPromotionData.vue?vue&type=style&index=0&id=822a0120&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "822a0120",
  null
  
)

export default component.exports