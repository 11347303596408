<template>
  <SectionContainer feature-bonus>
    <template #title>
      <div class="section-top">
        <h3>
          <span class="title">{{ $t('_.freeBonus') }}</span>
          <PromotionTooltipItem :text="`tooltip.receiveFreeBonus`" />
        </h3>
        <div class="timer" v-if="isInProgress">
          <span class="next-day">{{ $t('_.signUp.nextStart', { day: dailyBonus.nextDayNumber }) }}</span>
          <PromotionTimer :time="remainTime" />
        </div>
      </div>
    </template>
    <template #contents v-if="itemList.length">
      <Slider ref="refSlider" class="bonuses" slides-per-view="auto" :info="itemList" @initialized="goCurrencyIndex">
        <template v-slot:slide="{ info }">
          <DailyFreeCard :info="info" />
        </template>
      </Slider>
    </template>
  </SectionContainer>
</template>

<script>
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import Specific from '@shared/types/Specific';
import Slider from '@shared/components/general/Slider.vue';
import DailyFreeCard from '@/views/components/pages/promotion/v1/DailyFreeCard.vue';
import PromotionTooltipItem from '@/views/components/pages/promotion/v1/PromotionTooltipItem.vue';
import PromotionTimer from '@/views/components/pages/promotion/v1/PromotionTimer.vue';
import { state } from '@shared/utils/storeUtils';
import { toMilliseconds } from '@/utils/dateTimeUtil';

export default {
  name: 'FeatureBonus',
  lexicon: 'promotion.v1',
  components: { PromotionTooltipItem, DailyFreeCard, SectionContainer, Slider, PromotionTimer },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
  },
  computed: {
    token: state('user', 'token'),
    language: state('query', 'selectedLang'),
    statusItem() {
      return /** @type {PromotionStatusItem} */ this.statusInfo?.item || {};
    },
    dailyBonus() {
      return /** @type {DailyBonusStatus} */ this.statusItem.dailyBonuses?.[0] || {};
    },
    currentIndex() {
      const index = (this.dailyBonus.nextDayNumber ?? 0) - 2;
      return Math.max(index, 0);
    },
    itemList() {
      return this.dailyBonus?.items || [];
    },
    remainTime() {
      return toMilliseconds(this.dailyBonus?.remainTimeSpan);
    },
    isInProgress() {
      return this.statusItem.playerPromotionStatus === 'InProgress';
    },
    isEnablePromotion() {
      return this.statusInfo?.isEnablePromotion;
    },
    isRestriction() {
      const promotionRestriction = this.statusInfo?.promotionRestriction;
      if (promotionRestriction) return false;
      const status = promotionRestriction.status;
      return ['Restriction', 'Edit'].includes(status);
    },
    description() {
      return this.isEnablePromotion ? 'signup.firstDepositAvailable' : 'signup.noActiveBonus';
    },
  },
  methods: {
    goCurrencyIndex() {
      this.$refs.refSlider.go(this.currentIndex);
    }
  },
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[feature-bonus] {
  [slider] {
    .swiper-container { .wf(); .bgc(@c-black); }
    .swiper-wrapper { .grid(); .grid-template-columns(repeat(6, 1fr)); .min-w(922); .tc(); }
    .swiper-slide { .p(10, 10, 20);
      + .swiper-slide { .-start(@c-w02); }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~@/less/promotion.less";
[feature-bonus] { .ph(20); .-t(@c-dimGrey);
  .section-top { .flex(); .flex-dc(); .pv(16);
    h3 { .pv(0); }
    .timer { .flex(); .items-center(); .gap(12); .align-end(); }
  }
  button[color-button].claim-btn { .wf(); .max-w(448); .h(50); .p(10, 20); .br(2em); .bgc(@c-blurRed); .regular(); .lh(normal); .fs(18); .o(.65);
    &:hover, &:focus, &:active { .bgc(@c-red); }
  }
  .claim-now { .flex(); .flex-dc(); .justify-center(); .min-h(200); .tc();
    .link { .flex(); .justify-end(); .mb(16); .c(@c-blue); .fs(14);
      a { .-b(@c-blue); }
    }
    p { .c(@c-dimGrey); }
  }

  @media (@mm-up) {
    .section-top { .flex-dr(); .space-between(); .items-center();
      .timer { .align-center(); }
    }
  }
}
</style>
