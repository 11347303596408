<template>
  <GpFormTemplate single-bet-limit>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content>
      <InputSingleBetLimit :model="model" :current="current" :currency-symbol="currencySymbol" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="openModal" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputSingleBetLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputSingleBetLimit.vue';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'RgSingleBetLimit',
  lexicon: 'responsibleGaming',
  components: { InputSingleBetLimit, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      currencySymbol: null,
      current: {
        Currency: null,
        CurrentSingleBetLimit: null,
        SingleBetLimitApplyAt: null,
      },
      model: {
        RequestSingleBetLimit: null,
        NoLimit: false,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'default', current: this.current.CurrentSingleBetLimit, request: this.model.RequestSingleBetLimit, noLimit: this.model.NoLimit, valueType: 'amount', currencySymbol: this.currencySymbol },
      ];
    },
    disabled() {
      const {
        CurrentSingleBetLimit,
      } = this.current;
      const {
        RequestSingleBetLimit,
        NoLimit
      } = this.model;

      let request = (RequestSingleBetLimit || parseInt(RequestSingleBetLimit) === 0) ? parseInt(RequestSingleBetLimit) : null;
      request = NoLimit ? null : request;
      const sameValue = CurrentSingleBetLimit === request;

      return sameValue;
    },
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.currencySymbol = getCurrencySymbol(this.current.Currency);
    },

    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };
      return {
        title: this.$t('_.modal.title'),
        subTitle: this.$t(`_.modal.subTitle.${this.structure.config.title}`),
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel: descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze
      };
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>