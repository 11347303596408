import DepositBonus from '@/views/pages/promotion/DepositBonus.vue';
import ReferralBonus from '@/views/pages/promotion/ReferralBonus.vue';
import InvitationBonus from '@/views/pages/promotion/InvitationBonus.vue';
import FreeBonus from '@/views/pages/promotion/FreeBonus.vue';
import MatchBonus from '@/views/pages/promotion/MatchBonus.vue';
import DailyFreebie from '@/views/pages/promotion/DailyFreebie.vue';
import SignUpBonus from '@/views/pages/promotion/SignUpBonus.vue';

const view = path => () => import(/* webpackChunkName: "promotion" */ `../views/pages/promotion/${path}.vue`);

export default ({ local, token }) => {
  const path = local ? 'promotion/' : 'app/';
  const list = [
    /** redirect */
    {
      path: `free`,
      redirect: `${path}free`,
    },
    {
      path: `match`,
      redirect: `${path}match`,
    },
    {
      path: `daily`,
      redirect: `${path}daily`,
    },
    {
      path: `deposit`,
      redirect: token ? `${path}deposit` : '/auth-error',
    },
    {
      path: `signup`,
      redirect: `${path}signup`,
    },
    {
      path: `referral`,
      redirect: token ? `${path}referral` : '/auth-error',
    },
    {
      path: `invitation`,
      redirect: token ? `${path}invitation` : '/auth-error',
    },
    /** root */
    {
      path: `${path}`,
      name: 'Promotion',
      component: view('PromotionHome'),
      redirect: token ? `${path}free` : '/auth-error',
      children: [
        {
          path: 'free',
          name: 'FreeBonus',
          component: FreeBonus,
        },
        {
          path: 'match',
          name: 'MatchBonus',
          component: MatchBonus,
        },
        {
          path: 'daily',
          name: 'DailyFreebie',
          component: DailyFreebie,
        },
        {
          path: 'deposit',
          name: 'Deposit',
          component: DepositBonus,
        },
        {
          path: 'signup',
          name: 'Signup',
          component: SignUpBonus,
        },
        {
          path: 'referral',
          name: 'Referral',
          component: ReferralBonus,
        },
        {
          path: 'invitation',
          name: 'Invitation',
          component: InvitationBonus,
        },
      ],
    },
  ];

  return list;
};
