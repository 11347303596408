<template>
  <SectionContainer daily-free-challenge>
    <template #title>
      <h3>
        <span>{{ $t('_.dailyFreeChallenge') }}</span>
        <PromotionTooltipItem :text="`tooltip.receiveDailyBonus`" />
      </h3>
    </template>
    <template #contents>
      <FreeBonusChallenge :status-info="statusInfo" />
      <CompleteAllChallenge :status-info="statusInfo" v-if="completeAll" />
    </template>
  </SectionContainer>
</template>

<script>
import SectionContainer from '@/views/components/pages/promotion/v1/SectionContainer.vue';
import Specific from '@shared/types/Specific';
import FreeBonusChallenge from '@/views/components/pages/promotion/v1/depositBonus/FreeBonusChallenge.vue';
import CompleteAllChallenge from '@/views/components/pages/promotion/v1/depositBonus/CompleteAllChallenge.vue';
import PromotionTooltipItem from '@/views/components/pages/promotion/v1/PromotionTooltipItem.vue';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'DailyFreeChallenge',
  lexicon: 'promotion.v1',
  components: { PromotionTooltipItem, FreeBonusChallenge, SectionContainer, CompleteAllChallenge },
  props: {
    statusInfo: { /** @type {PromotionStatus} */ type: Specific, required: true },
  },
  computed: {
    language: state('query', 'selectedLang'),
    challenge() {
      return this.statusInfo?.item?.dailyChallenges?.[0];
    },
    itemList() {
      return (this.challenge?.items) || [];
    },
    completeAll() {
      return !this.itemList.find(o => o.status === 'Failed');
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[daily-free-challenge] {
  &::before { .cnt(); .wf(); .h(1); .bgc(@c-dimGrey); }
  .content { .bgc(@c-black); }
  .current { .flex(); .-b(@c-w02);
    > div { .p(20);
      + div { .-l(@c-w02); }
    }
    .info, .hands {
      > p { .flex-wrap();
        span { .nowrap(); }
      }
    }
    .info {
      .day { .items-center(); .mb(16);
        span { .fs(0.8em); .c(@c-veryLightGrey); .ml(8); }
      }
      .name { flex: 0 0 120px; .c(@c-veryLightGrey); }
      p:not(.day) { .mb(8); }

    }
    .hands { flex: 1; .flex(); .flex-dc(); .space-between();
      .name { .mr(16); .c(@c-dimGrey); }
      .text {
        em { .c(@c-blurRed); }
      }
    }
  }

  @media (@ml-down) {
    .current {
      .flex-dc();
      > div + div { .-l(); .-t(@c-w02); }
    }
  }
}
</style>
