<template>
  <div contents-container v-if="info">
    <div class="contents-header" v-if="!noHeader">
      <div class="info">
        <h3>{{ name }}</h3>
        <div>
          <StatusBadge v-if="showStatus" :status="status" />
          <PromotionButton v-if="showActiveButton" @click="active">{{ $t('_.activeBonus') }}</PromotionButton>
        </div>
      </div>
      <div class="date">
        <p class="period"><span>{{ startDate }}</span> - <span>{{ endDate}}</span></p>
        <p v-if="leftDays" class="left-days">{{ $t('_.daysLeft', { days: leftDays }) }}</p>
        <p v-if="currentDay" class="time">
          <span>{{ $t('_.nextDayStart', { day: currentDay + 1 }) }}</span>
          <PromotionTimer :time="remainTime" />
        </p>
      </div>
    </div>
    <div class="contents-body">
      <slot />
    </div>
  </div>
</template>

<script>
import StatusBadge from '@/views/components/pages/promotion/StatusBadge.vue';
import PromotionTimer from '@/views/components/pages/promotion/PromotionTimer.vue';
import { toFullDate, toMilliseconds } from '@/utils/dateTimeUtil';
import Specific from '@shared/types/Specific';
import PromotionPagination from '@/views/components/pages/promotion/PromotionPagination.vue';
import PromotionButton from '@/views/components/pages/promotion/PromotionButton.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'ContentsContainer',
  lexicon: 'promotion',
  props: {
    info: Specific,
    model: Specific,
    noHeader: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    index: { type: Number | String, default: 0 },
  },
  components: { PromotionButton, PromotionPagination, PromotionTimer, StatusBadge },
  computed: {
    // item() {
    //   return this.info?.[this.index];
    // },
    promotionType() {
      return this.$route?.name;
    },
    name() {
      return this.info?.name || this.info?.promotion?.name;
    },
    status() {
      const statusList = {
        OPTED_IN_PAUSED: 'PAUSED',
        OPTED_OUT: 'PAUSED',
      }

      let status;
      switch(this.promotionType) {
        case 'DailyFreebie':
          status = this.info?.attendeeStatus;
          break;
        case 'FreeBonus':
          status = this.info?.attendee?.status;
          break;
        default:
          status = this.info?.status;
      }

      return (status && statusList[status]) || status;
    },
    startDate() {
      return toFullDate(this.info?.createdAt);
    },
    endDate() {
      return toFullDate(this.info?.finishedAt);
    },
    currentDay() {
      return this.info?.currentDay;
    },
    freeBonuses() { // [celina] todo 정리 필요 동일 코드 다른 컴포넌트에서도 계속 사용됨
       return this.info?.pkg?.prizes?.filter(o => o.freeBonusPrizeType === 'FREE');
    },
    remainTime() {
      return toMilliseconds(this.info?.timeUntilNextDay);
    },
    page() {
      return Number(this.index) + 1;
    },
    leftDays() {
      return this.info?.daysUntilFinished;
    },
    showStatus() {
      return this.$route?.name === 'MatchBonus' || this.status === 'PAUSED';
    },
    showActiveButton() {
      return this.$route?.name === 'MatchBonus' && this.info.status === 'PENDING';
    },
  },
  methods: {
    async active() {
      const id = this.info?.dripBonusId;
      if (!id) return;
      const r = await this.$services.promotion.activateDripBonus(id);
      if(r && !r.error) this.$emit('update');
    }
  },
  mounted() {
  }
};
</script>

<style scoped lang="less">
@import "@/less/tpm";
[contents-container] { .bgc(#252525);
   + [contents-container] { .mt(20); }
  section + section {
    .mt(20); .pt(20); .-t(@c-gray6);
  }
  .contents-header { .flex(); .flex-dc(); .row-gap(16); .-b(@c-bd); .p(40, 20, 20);
    > div { .flex(); .items-start(); .gap(24); .wf(); }
    .info { .space-between(); .lh(28);
      h3 { .fs(22); .lh(inherit); .c(@c-white); }
      > div { .flex(); .gap(8); .items-center(); }
      [promotion-button]  { .ib(); .fs(12); }

    }
    //.info span, .date .time { .flex(); .justify-end(); }
    .period { .grid-column(span 2); .c(#b5b5b5);
      span { .nowrap(); }
    }
    .left-days { .c(@c-white); .nowrap(); }
    .time { flex: 1; .flex(); .justify-end(); .items-center(); .column-gap(8); .flex-wrap();
      span { .nowrap(); }
    }
  }
  .contents-body { .p(20, 24); }

  @media(@tp-up) {
    .contents-header { .ph(24); }
  }
}
</style>
