<template>
  <GpFormTemplate v-if="load" rg-game-limit>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <!--      <GpDescription v-if="subDescription" :message="subDescription" />-->
      <CautionMessage :message="$t('_.caution.gameLimit')" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content>
      <InputGameLimitTable :model="model" />
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="submit(validate)" :disabled="disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputGameLimitTable from '@/views/components/pages/responsible-gaming/template/inputs/InputGameLimitTable.vue';
import CautionMessage from '@/views/components/pages/responsible-gaming/template/common/CautionMessage.vue';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'RgGameLimit',
  components: { CautionMessage, InputGameLimitTable, GpFormTemplate, GpDescription, GpButton },
  lexicon: 'responsibleGaming',
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      current: {
        CurrentPokerGameLimit: null,
        CurrentCasinoGameLimit: null,
        CurrentLiveDealerGameLimit: null,
        PokerGameLimitApplyAt: null,
        CasinoGameLimitApplyAt: null,
        LiveDealerGameLimitApplyAt: null,
      },
      model: {
        RequestPokerGameLimit: null,
        RequestCasinoGameLimit: null,
        RequestLiveDealerGameLimit: null,
      },

      load: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'poker', current: this.current.CurrentPokerGameLimit, request: this.model.RequestPokerGameLimit, valueType: 'game' },
        { type: 'casino', current: this.current.CurrentCasinoGameLimit, request: this.model.RequestCasinoGameLimit, valueType: 'game' },
        { type: 'liveCasino', current: this.current.CurrentLiveDealerGameLimit, request: this.model.RequestLiveDealerGameLimit, valueType: 'game' },
      ];
    },
    disabled() {
      return this.current.CurrentPokerGameLimit === this.model.RequestPokerGameLimit && this.current.CurrentCasinoGameLimit === this.model.RequestCasinoGameLimit && this.current.CurrentLiveDealerGameLimit === this.model.RequestLiveDealerGameLimit;
    }
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.model.RequestCasinoGameLimit = this.current.CurrentCasinoGameLimit;
      this.model.RequestLiveDealerGameLimit = this.current.CurrentLiveDealerGameLimit;
      this.model.RequestPokerGameLimit = this.current.CurrentPokerGameLimit;

      this.load = true;
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      return {
        title: this.$t('_.modal.title'),
        subTitle: this.$t(`_.modal.subTitle.${this.structure.config.title}`),
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel: descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze
      };
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },

    async submit(validate) {
      this.openModal();
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-game-limit] {
}

</style>