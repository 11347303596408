<template>
  <span promotion-timer>{{ timeFormat }}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PromotionTimer',
  components: {},
  props: {
    time: { type: Number, required: true }
  },
  data() {
    return {
      timerId: null,
      path: null,
      remainTime: null,
    };
  },
  watch: {
    time: 'updateRemainTime'
  },
  computed: {
    timeFormat() {
      const duration = moment.duration(this.remainTime);
      const days = duration.days();
      const HH = `${duration.hours() + (days * 24)}`.padStart(2, '0');
      const mm = `${duration.minutes()}`.padStart(2, '0');
      const ss = `${duration.seconds()}`.padStart(2, '0');
      return this.remainTime <= 0 ? '00:00:00' : `${HH}:${mm}:${ss}`;
    },
  },
  methods: {
    updateRemainTime() {
      this.remainTime = this.time;
      this.timer();
    },
    // async updateInfo() {
    //   if (this.$route.name === 'Deposit') {
    //     const info = await this.$services.promotion.depositPromotion();
    //     if (info.error) {
    //       await this.replaceRouteName(info.path);
    //     } else {
    //       this.$store.commit('user/setDepositInfo', info);
    //     }
    //   }
    // },
    clearInterval() {
      clearInterval(this.timerId);
      this.timerId = null;
    },
    timer() {
      if (this.remainTime < 1000) return;
      this.clearInterval();
      this.timerId = setInterval(() => {
        this.remainTime -= 1000;
        if (this.remainTime < 1000) {
          this.clearInterval();
          this.updateInfo();
        }
      }, 1000);
    }
  },
  mounted() {
    this.updateRemainTime();
  },
  beforeDestroy() {
    this.clearInterval();
  },
};
</script>

<style lang="less" scoped>
@import "@/less/tpm";
[promotion-timer] { .fs(20); .c(@c-lightYellow); font-family: 'Digital-7';
  span { .fs(inherit); }
}
</style>
