<template>
  <div match-bonus v-if="isReady">
    <div v-if="isEmpty(dripBonuses)" no-active-container>
      <p>{{ $t('promotion.noActiveBonus') }}</p>
    </div>
    <template v-else>
      <PromotionTitle type="matchBonus" @reset="updateInfo" />
      <div color-container>
        <Slider ref="refSlider" :info="dripBonuses" slides-per-view="1" :pagination="showPagination" pagination-type="custom" :pagination-nav="showPagination" prevent-touch-move only-show-now-page>
          <template v-slot:slide="{info}">
            <ContentsContainer :info="info" @update="updateInfo">
              <MatchBonusComponent :drip-bonus="info" />
            </ContentsContainer>
          </template>
          <template v-slot:pagination="{info, idx}">
            <p>{{ idx + 1 }}</p>
          </template>
        </Slider>
      </div>
      <BonusHistory :info="dripBonuses" :uid="uid" />
    </template>
  </div>
</template>

<script>
import PromotionTitle from '@/views/components/pages/promotion/PromotionTitle.vue';
import Slider from '@shared/components/general/Slider.vue';
import BonusHistory from '@/views/components/pages/promotion/BonusHistory.vue';
import ContentsContainer from '@/views/components/pages/promotion/ContentsContainer.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import PromotionPagination from '@/views/components/pages/promotion/PromotionPagination.vue';
import { isEmpty } from 'lodash';
import MatchBonusComponent from '@/views/components/pages/promotion/MatchBonusComponent.vue';

export default {
  name: 'MatchBonus',
  lexicon: 'promotion.matchBonus',
  components: { PromotionPagination, FontIcon, ContentsContainer, BonusHistory, Slider, MatchBonusComponent, PromotionTitle },
  data() {
    return {
      dripBonuses: null,
      history: null,
      isReady: false,
      uid: 0,
    };
  },
  computed: {
    showPagination() {
      return this.dripBonuses?.length > 1;
    }
  },
  methods: {
    async updateInfo() {
      ++this.uid;
      const r = await this.$services.promotion.getDripBonus();
      if (r && !r?.error) this.dripBonuses = r;
      this.$refs.refSlider?.go(0);
    },
    async initialize() {
      this.$services.modal.block();
      await this.updateInfo();
      this.$services.modal.unblock();
      this.isReady = true;
    }
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import "@/less/tpm";
[slider] .swiper-pagination { .static(); .justify-end(); .ph(20); .c(@c-gray1);
  .pagination-item { .w(28); }
  .pagination-nav { .c(inherit);
    button { .wh(28); .bgc(@c-gray8); }
  }
}
</style>
<style scoped lang="less">
@import "@/less/tpm";
[match-bonus] {
  [color-container] { .bgc(@c-bgGray); .pb(20) }
  [no-active-container] { .flex(); .justify-center(); .items-center(); .wf(); .bgc(@c-bgGray); .min-h(100); }
}
</style>
