<template>
  <div personal-names v-if="modelInfo" :name="formName" class="kyc-personal-details-view">
    <p v-if="nameGuide" v-html="nameGuide" />
    <div class="field-holder">
      <ValidationComponent :label="$t('firstName')" name="_firstName" :component="TrimTextInput" :rules="`requiredPersonalName:0,50,${countryWritingSystem}`" v-model="modelInfo.FirstName" :error-msg="firstErrorMsg" :preset="firstNamePreset" />
      <ValidationComponent :label="$t('lastName')" name="_lastName" :component="TrimTextInput" :rules="`requiredPersonalName:0,50,${countryWritingSystem}`" v-model="modelInfo.LastName" :error-msg="lastErrorMsg" :preset="lastNamePreset" />
    </div>
    <component ref="refExtraComponent" :is="extraComponent" :model-info="modelInfo" :structure="structure" />
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import baseTemplate from '@/mixins/baseTemplate';
import { siteIds } from '@/constants/base/siteMap';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';

export default {
  name: 'PersonalNames',
  components: { ValidationComponent },
  mixins: [baseTemplate],
  data() {
    return {
      firstErrorMsg: null,
      lastErrorMsg: null,
      isCheckSameLastName: false,
      TrimTextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    isGGPPH() {
      return this.site === siteIds.GGPPH;
    },
    nameGuide() {
      if (this.isWSOP) return this.$t('personalDetails.nameGuideWsop');
      else if (this.isGGPPH) return '';
      else return this.$t('personalDetails.nameGuide');
    },
    extra() {
      return this.item?.extra && this.item.extra[this.site] ? this.item.extra[this.site] : {};
    },
    extraComponent() {
      return this.extra.template;
    },
    countryWritingSystem() {
      return this.modelInfo.CountryWritingSystem;
    },
    firstNamePreset() {
      return { maxLength: 50, disable: this.disabledFirstName };
    },
    lastNamePreset() {
      return { maxLength: 50, disable: this.disabledLastName };
    },
    formName() {
      return this.site === siteIds.WSOPON ? 'WSOPca_Step11_Personal_Details_Name_View' : 'Personal_Details_Name_View';
    },
    isReCheckCustomStep() {
      if (!this.structure.customSteps) return false;
      return this.structure.customSteps.indexOf(routeNames.personalDetailsRecheck) >= 0;
    },
    disabledFirstName() {
      return this.modelInfo.blockFields?.includes('FirstName') && !!this.modelInfo.FirstName;
    },
    disabledLastName() {
      return this.modelInfo.blockFields?.includes('LastName') && !!this.modelInfo.LastName;
    },
  },
  watch: {
    errorMsg() {
      const { errorMsg } = this;
      this.firstErrorMsg = null;
      this.lastErrorMsg = null;

      if (errorMsg) {
        if (errorMsg === apiErrorCode.INVALID_FIRST_NAME) this.firstErrorMsg = this.$tv({ _rule_: 'required', _field_: '_firstName' });
        else if (errorMsg === apiErrorCode.INVALID_LAST_NAME) this.lastErrorMsg = this.$tv({ _rule_: 'required', _field_: '_lastName' });
      }
    },
  },
  mounted() {

  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[personal-names] {
  > p { .c(@c-text-desc); .mb(24); word-break:break-word; }
  > div {
    > input { .wh(0); .abs();}
  }
  .field-holder { .flex(); .space-between();
    > * {.w(calc(50% - 5px)); }
  }
  > *:nth-of-type(2) { .mt(8); }
}
</style>
