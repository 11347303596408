<template>
  <ValidationObserver tag="div" v-slot="{invalid}">
    <BaseModal :show-title="isEditStep" responsible-progress-modal :title="modalTitle" :class="{'result' : !isEditStep}" @close="cancel">
      <template v-if="isLoaded" #content>
        <DisplayStep v-if="!!options.first && (!!options.last && options.last > 2) && isEditStep" :first="options.first" :last="options.last" :current="questionnaireStep" />
        <component :is="getStepComponent" :gradient="gradient" :title="modalTitle" :sub-title="subTitle" :isExceed="isExceed" :description="description" :data="data" :payload="payload" :type="resultType" :subMessage="subMessage" :message="message" :calculateList="calculateList" :descModel="descModel" :isVerifyFreeze="isVerifyFreeze" @input="input" @close="cancel" @submit="submit" @confirm="close" />
      </template>
      <template #floating>
        <div class="floating">
          <div class="floating-gradient" />
          <CheckboxItem theme="light" v-if="getStepComponent === QuestionnaireLimitAmountModal" v-model="isSelect">{{ $t('responsibleGaming.modal.label.confirmedNewLimit') }}</CheckboxItem>
          <div class="floating-holder">
            <template v-if="modalType === 'Questionnaire'">
              <template v-if="isEditStep">
                <template v-if="getStepComponent === DisplayHangTimeModal">
                  <GpButton type="line-green" @click="cancel">{{ $t('cancel') }}</GpButton>
                  <GpButton type="submit-green" :progress="progress" @click="next">{{ $t('verify') }}</GpButton>
                </template>
                <GpButton v-else type="submit-green" :progress="progress" :disabled="!isSelect || invalid" @click="next">{{ $t(nextText) }}</GpButton>
              </template>
              <GpButton v-if="!isEditStep" type="submit-green" :progress="progress" @click="autoLogin">{{ $t('ok') }}</GpButton>
            </template>
            <template v-else>
              <template v-if="isEditStep">
                <GpButton type="line-green" @click="cancel">{{ $t('cancel') }}</GpButton>
                <GpButton type="submit-green" :progress="progress" @click="submit">{{ $t(submitText) }}</GpButton>
              </template>
              <template v-else>
                <GpButton v-if="!isEditStep" type="submit-green" :progress="progress" @click="autoLogin">{{ $t('ok') }}</GpButton>
              </template>
            </template>
          </div>
        </div>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { autoLogin, close } from '@/utils/clientUtil';
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import ConfirmResult from '@/views/components/pages/responsible-gaming/modal/components/ConfirmResult.vue';
import DisplayHangTimeModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayHangTimeModal.vue';
import DisplayMessageModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayMessageModal.vue';
import DisplayOasisBanModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayOasisBanModal.vue';
import DisplayExternalMonthlyModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayExternalMonthlyModal.vue';
import DisplayExceedLimitModal from '@/views/components/pages/responsible-gaming/modal/components/DisplayExceedLimitModal.vue';
import rgErrorHandler from '@/mixins/responsible-gaming/rgErrorHandler';
import DisplayStep from '@/views/components/pages/my-page/modal/components/DisplayStep.vue';
import QuestionnaireLimitAmountModal from '@/views/components/pages/responsible-gaming/modal/components/QuestionnaireLimitAmountModal.vue';
import QuestionnaireSurveyModal from '@/views/components/pages/responsible-gaming/modal/components/QuestionnaireSurveyModal.vue';
import QuestionnaireDmiModal from '@/views/components/pages/responsible-gaming/modal/components/QuestionnaireDmiModal.vue';
import QuestionnaireGuideModal from '@/views/components/pages/responsible-gaming/modal/components/QuestionnaireGuideModal.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';

export default {
  name: 'ResponsibleProgressModal',
  components: { CheckboxItem, DisplayStep, GpDescription, RadioList, GpButton, BaseModal },
  mixins: [rgErrorHandler],
  props: {
    options: {
      title: { type: String, default: null },
      subTitle: { type: String, default: null },
      description: { type: String, default: null },
      data: Specific,
      calculateList: { type: Specific, default: null },
      isExceed: { type: Boolean, default: false, },
      payload: Specific,
      request: Specific,
      modalType: { type: String, default: null, },
      selfExclusion: false,
      descModel: Specific,
      isVerifyFreeze: { type: Boolean, default: false },
      first: { type: Number, default: null },
      last: { type: Number, default: null },
    }
  },
  data() {
    return {
      // Form Props
      title: null,
      data: null,
      payload: null,
      description: null,
      subTitle: null,
      isExceed: false,
      step: 'EDIT',
      calculateList: null,
      descModel: null,
      isVerifyFreeze: false,

      // Result Props
      resultType: true,
      message: null,
      subMessage: null,

      // oasis 관련 처리용 변수
      oasisBanList: null, // TODO : [ean] history 확인 후 제거 필요
      isLoaded: false,
      progress: false,

      questionnaireStep: null,
      isSelect: false,

      DisplayHangTimeModal,
      DisplayExceedLimitModal,
      DisplayMessageModal,
      QuestionnaireLimitAmountModal,
      QuestionnaireSurveyModal,
      QuestionnaireDmiModal,
      QuestionnaireGuideModal,
      ConfirmResult,
    };
  },
  computed: {
    myInfo: state('user', 'userInfo'),
    reCheckTarget: state('user', 'reCheckTarget'),
    csEmail: state('env', 'csEmail'),
    site: state('env', 'site'),
    modalTitle(){
      if(this.options.last === 2 && this.questionnaireStep === 2) return this.$t('responsibleGaming.title.requestDmiIncrease')
      return this.title;
    },
    modalType() {
      return this.options.modalType;
    },
    getStepComponent() {
      return this.isEditStep ? this.getTypeComponent : ConfirmResult;
    },
    isEditStep() {
      return this.step === 'EDIT';
    },
    getTypeComponent() {
      switch (this.modalType) {
        case 'Message':
          return DisplayMessageModal;
        case 'HangTime':
          return this.isExceed ? DisplayExceedLimitModal : DisplayHangTimeModal;
        case 'Oasis':
          return DisplayOasisBanModal;
        case 'External':
          return DisplayExternalMonthlyModal;
        case 'Questionnaire': {
          if(!this.questionnaireStep || this.questionnaireStep < 1) return DisplayHangTimeModal;
          return this.getQuestionnaireComponent[this.questionnaireStep - 1];
        }
        default:
          return DisplayHangTimeModal;
      }
    },
    getQuestionnaireComponent() {
      const { last } = this.options;

      switch(last) {
        case 1: return [DisplayHangTimeModal];
        case 2: return [DisplayHangTimeModal, QuestionnaireDmiModal];
        case 3: return [QuestionnaireLimitAmountModal, QuestionnaireSurveyModal, QuestionnaireGuideModal];
        case 4: return [QuestionnaireLimitAmountModal, QuestionnaireSurveyModal, QuestionnaireDmiModal, QuestionnaireGuideModal];
        default: return [DisplayHangTimeModal];
      }
    },
    nextText() {
      if (this.step === this.options.last) return 'confirm';
      return 'next';
    },
    submitText() {
      if (this.modalType === 'HangTime' && this.isExceed) return 'verify';
      return 'confirm';
    },
    gradient() {
      return this.$el.querySelector('.scroll-area')?.clientHeight < 340;
    }
  },
  methods: {
    initialize() {
      this.data = this.options.data;
      this.payload = this.options.payload;
      this.title = this.options.title;
      this.subTitle = this.options.subTitle;
      this.description = this.options.desc;
      this.isExceed = this.options.isExceed;
      this.calculateList = this.options.calculateList;
      this.descModel = this.options.descModel;
      this.isVerifyFreeze = this.options.isVerifyFreeze;
      this.step = 'EDIT';
      this.questionnaireStep = this.options.first;

      // [Eric] HangTime -> Dmi 모달 일 경우, isSelect 값을 핸들링 할 수 없기에, 강제로 true 로 변환
      if(this.options.last === 2 && this.modalType === 'Questionnaire') this.isSelect = true;

      this.isLoaded = true;
    },

    async next() {
      this.progress = true;
      if (this.questionnaireStep === this.options.last) {
        const { request } = this.options;
        const result = await request({ ...this.payload, oasisList: this.oasisBanList });
        this.progress = false;
        if (result.error) {
          this.resultType = false;
          this.responseErrorHandler(result);
          this.step = 'RESULT';
          return;
        }

        if (this.options.selfExclusion) {
          // Logout 처리 ( Self Exclusion 처리 )
          await this.routeName('ResponsibleGaming');
          return;
        }

        this.resultType = true;
        this.message = this.options.last === 2 ? this.$t('responsibleGaming.modal.successMessage') : this.$t('responsibleGaming.modal.nlSuccessMessage');
        this.step = 'RESULT';
      } else {
        this.questionnaireStep++;
        this.progress = false;
      }
    },
    async submit() {
      // 진행 타입에 따라 파일업로드일 경우는 서브밋 처리해야하는게 아니라 파일업로드로 가야함
      this.progress = true;
      if (this.isExceed) {
        this.redirectKycUrl();
        return;
      }
      const { request } = this.options;
      const result = await request({ ...this.payload, oasisList: this.oasisBanList });
      this.progress = false;
      if (result.error) {
        this.resultType = false;
        this.responseErrorHandler(result);
        this.step = 'RESULT';
        return;
      }

      if (this.options.selfExclusion) {
        // Logout 처리 ( Self Exclusion 처리 )
        await this.routeName('ResponsibleGaming');
        return;
      }

      this.resultType = true;
      this.message = this.$t('responsibleGaming.modal.successMessage');
      this.step = 'RESULT';
    },

    async autoLogin() {
      if (this.reCheckTarget) {
        if(this.resultType) await autoLogin(this, { site: this.site, token: await this.$services.sign.getGcLoginToken() });
        else await close();
      }
      else this.close();
    },
    async cancel() {
      if(this.reCheckTarget){
        await close();
      }
      else this.$modalCloseAll();
    },
    close() {
      this.replaceRouteName('ResponsibleGaming');
      this.cancel();
    },
    input(array) { // TODO : [ean] history 확인 후 제거 필요
      this.oasisBanList = array;
    },
    redirectKycUrl() {
      this.$rg.redirectKycUrl({ lang: this.$i18n('lang'), additional: true, redirectPath: 'rg-my-deposit-limit' });
    },

    responseErrorHandler(error) {
      const { message, subMessage } = this.apiErrorHandler(error);
      this.message = message;
      this.subMessage = subMessage;
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[responsible-progress-modal] {
  [display-step] { .pt(8); .pb(24); }
  .scroll-area { .ph(0) !important; .pb(0) !important; }
  .floating { .rel(); .w(100%); .ph(20); .pb(20);
    >.floating-gradient { .abs(); .l(0); .b(100%); .w(100%); .h(24); background: linear-gradient(179deg, rgba(247, 247, 248, 0.00) 0.91%, #F7F7F8 86%); }
    >[checkbox-item] { .mb(24); .align-center();
      em { .c(black); .fs(14, 16, 0.42); white-space: normal; }
    }
    >.floating-holder { .flex(); .gap(10); }
  }
  &.result {
    > .panel {.h(auto);
      .scroll-area {margin-bottom: 30px;}
    }
  }
  [gp-description] {.pt(0); .pb(24); padding-right: 20px !important; padding-left: 20px !important;}

  .body { overflow-y: hidden; }
  .content { .pb(20); overflow-y: auto; .max-h(310); .ph(20); }

  .result-table {text-align: center; .c(black); .fs(12); .mv(20);
    > thead {.bold(); border-bottom: #d9d9d9 1px solid;
      > td { .pb(5); }
    }
    > tbody {
      > tr > td {.p(3);}
    }
  }
  .img-group { .flex(); .items-center(); .flex-dc(); .h(100%); .pt(0);
    > p {.c(black); font-size: 16px !important; .pt(10);}
  }

  .footer {.flex(); .gap(10);}

  @media (@ms-down) {
    [gp-description] {
      > p {.ph(15) !important;}
    }
  }
}
</style>
