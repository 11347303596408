import { siteIds } from '@/constants/base/siteMap';
import { KYC_STATUS } from '@/constants/base/my-page';

export const rootRoute = 'RgInfo';
export const STEP = {
  DEFAULT: 'DEFAULT',
  INPUT: 'INPUT',
  VERIFY: 'VERIFY',
};

export const STATUS = {
  'VIEW': 'VIEW',
  'SUCCESS': 'SUCCESS',
  'ERROR': 'ERROR',
};

export const selfExclusionType = {
  none: 'None',
  hrCoolingOff24: 'HrCoolingOff24',
  hrCoolingOff72: 'HrCoolingOff72',
  daysTimeOut7: 'DaysTimeOut7',
  daysTimeOut30: 'DaysTimeOut30',
  daysTimeOut60: 'DaysTimeOut60',
  daysTimeOut120: 'DaysTimeOut120',
  daysTimeOut42: 'DaysTimeOut42',
  daysTimeOut90: 'DaysTimeOut90',
  daysTimeOut180: 'DaysTimeOut180',
  daysTimeOut365: 'DaysTimeOut365',
  daysTimeOut1825: 'DaysTimeOut1825',
  permanentSelfExclusion: 'PermanentSelfExclusion',
  hrTimeOut24: 'HrTimeOut24',
  selfExclusion90: 'SelfExclusion90',
  selfExclusion120: 'SelfExclusion120',
};
export const excludeSkipList = [siteIds.GGPNL];
export const selfExclusionGroup = {
  CoolingOff: 'CoolingOff', Timeout: 'Timeout', Exclusion: 'Exclusion', Custom: 'Custom', None: 'None'
};
export const selfExclusionValues = {
  'None': 0,
  'HrCoolingOff24': 1,
  'DaysTimeOut7': 2,
  'DaysTimeOut30': 3,
  'DaysTimeOut60': 4,
  'DaysTimeOut120': 5,
  'DaysTimeOut180': 6,
  'PermanentSelfExclusion': 7,
  'DaysTimeOut365': 8,
  'DaysTimeOut1825': 9,
  'HrTimeOut24': 10,
  'DaysTimeOut42': 11,
  'HrCoolingOff72': 12,
  'DaysTimeOut90': 15,
};
export const realityCheckIntervalValues = {
  0: 'Off',
  3: 'Minutes3',
  5: 'Minutes5',
  15: 'Minutes15',
  30: 'Minutes30',
  60: 'Hour1',
  120: 'Hour2',
  240: 'Hour4',
  36: 'Hour6',
};
export const realityCheckIntervalType = {
  Off: { value: 'Off', label: 'responsibleGaming.label.off' },
  Minutes3: { value: 'Minutes3', label: 'responsibleGaming.label.minutes3' },
  Minutes5: { value: 'Minutes5', label: 'responsibleGaming.label.minutes5' },
  Minutes15: { value: 'Minutes15', label: 'responsibleGaming.label.minutes15' },
  Minutes30: { value: 'Minutes30', label: 'responsibleGaming.label.minutes30' },
  Hour1: { value: 'Hour1', label: 'responsibleGaming.label.hour1' },
  Hour2: { value: 'Hour2', label: 'responsibleGaming.label.hour2' },
  Hour4: { value: 'Hour4', label: 'responsibleGaming.label.hour4' },
  Hour6: { value: 'Hour6', label: 'responsibleGaming.label.hour6' },
};

export const useExclusionList = {
  [siteIds.SEVENXL]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.DAVAOPOKER]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.GGPCOM]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ],
  },
  [siteIds.GGPUK]: {
    Timeout: [
      { label: 'hrTimeOut24', value: 'HrTimeOut24', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut42', value: 'DaysTimeOut42', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  // Use Oasis
  [siteIds.GGPDE]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
    ],
    Timeout: [],
    Exclusion: [
      { label: 'selfExclusion90', value: 'SelfExclusion90', group: selfExclusionGroup.Exclusion },
      { label: 'selfExclusion120', value: 'SelfExclusion120', group: selfExclusionGroup.Exclusion },
      { label: 'selfExclusion180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'selfExclusion365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'selfExclusion1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ],
    Custom: [
      { label: 'exclusionCustom', value: 'CustomSelfExclusion', group: selfExclusionGroup.Custom },
    ]
  },
  [siteIds.GGPRO]: {
    CoolingOff: [],
    Timeout: [
      { label: 'hrTimeOut24', value: 'HrTimeOut24', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.NATURAL8]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.N8IN]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.GGPOK]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.TWOACE]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.WSOPON]: {
    Timeout: [
      { label: 'dayBreakPlay1', value: 'HrTimeOut24', group: selfExclusionGroup.Timeout },
      { label: 'dayBreakPlay7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'dayBreakPlay30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'dayBreakPlay60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'dayBreakPlay90', value: 'DaysTimeOut90', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.GGPUKE]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  },
  [siteIds.EVPUKE]: {
    CoolingOff: [
      { label: 'hrCoolingOff24', value: 'HrCoolingOff24', group: selfExclusionGroup.CoolingOff },
      { label: 'hrCoolingOff72', value: 'HrCoolingOff72', group: selfExclusionGroup.CoolingOff }
    ],
    Timeout: [
      { label: 'daysTimeOut7', value: 'DaysTimeOut7', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut30', value: 'DaysTimeOut30', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut60', value: 'DaysTimeOut60', group: selfExclusionGroup.Timeout },
      { label: 'daysTimeOut120', value: 'DaysTimeOut120', group: selfExclusionGroup.Timeout }
    ],
    Exclusion: [
      { label: 'daysTimeOut180', value: 'DaysTimeOut180', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut365', value: 'DaysTimeOut365', group: selfExclusionGroup.Exclusion },
      { label: 'daysTimeOut1825', value: 'DaysTimeOut1825', group: selfExclusionGroup.Exclusion },
      { label: 'permanentSelfExclusion', value: 'PermanentSelfExclusion', group: selfExclusionGroup.Exclusion },
    ]
  }
};
export const exclusionGroupList = [selfExclusionGroup.None, selfExclusionGroup.CoolingOff, selfExclusionGroup.Timeout, selfExclusionGroup.Exclusion, selfExclusionGroup.Custom];
export const hiddenNoLimitCountries = ['KR', 'FI', 'HU', 'IE', 'MT', 'SE', 'DE', 'UK', 'NL', 'CZ', 'BE'];
export const noHiddenNoLimitSiteId = [siteIds.WSOPON];
export const useLugasSiteIds = [siteIds.GGPDE];
export const useOasisSiteIds = [siteIds.GGPDE];
export const oasisBanCause = { Undefined: 0, GamblingAddiction: 1, Financial: 2, OverIndebtedness: 3, UnreliableStakes: 4, Std24: 10, NoReasonGiven: 99 };
export const oasisBanCauseList = [
  { label: 'responsibleGaming.selfExclusion.cause1', value: oasisBanCause.NoReasonGiven },
  { label: 'responsibleGaming.selfExclusion.cause2', value: oasisBanCause.GamblingAddiction },
  { label: 'responsibleGaming.selfExclusion.cause3', value: oasisBanCause.Financial },
  { label: 'responsibleGaming.selfExclusion.cause4', value: oasisBanCause.OverIndebtedness },
  { label: 'responsibleGaming.selfExclusion.cause5', value: oasisBanCause.UnreliableStakes },
];
export const hiddenOasisReasonList = [
  selfExclusionGroup.None,
  selfExclusionGroup.CoolingOff,
  selfExclusionGroup.Timeout,
];
export const maximumBySite = {
  [siteIds.GGPNL]: 5000000
};

export const limitTypes = [
  { enable: 'IsEnableDepositLimit', label: { default: 'depositLimit', [siteIds.GGPDE]: 'localDepositLimit' }, value: 'DepositLimit' },
  { enable: 'IsEnableTotalBetLimit', label: { default: 'totalBetLimit' }, value: 'TotalBetLimit' },
  { enable: 'IsEnableLossLimit', label: { default: 'lossLimit' }, value: 'LossLimit' },
  { enable: 'IsEnableSingleBetLimit', label: { default: 'singleBetLimit' }, value: 'SingleBetLimit' },
  { enable: 'IsEnableBalanceLimit', label: { default: 'balanceLimit', [siteIds.GGPCZ]: 'maximumBalance', [siteIds.GGPNL]: 'maximumBalance' }, value: 'BalanceLimit' },
  { enable: 'IsEnableLoginTimeLimit', label: { default: 'playTimeLimit' }, value: 'LoginTimeLimit' },
  { enable: 'IsEnableReLoginTimeLimit', label: { default: 'reLoginLimit' }, value: 'ReLoginTimeLimit' },
  { enable: 'IsEnableMonthlyLoginFrequency', label: { default: 'monthlyLogin' }, value: 'MonthlyLoginFrequency' },
  { enable: 'IsEnableGameLimit', label: { default: 'gameLimit' }, value: 'GameLimit' },
  { enable: 'IsEnableSelfExclusion', label: { default: 'selfExclusion' }, value: 'SelfExclusion' },
  { enable: 'IsEnableRealityCheckNotification', label: { default: 'realityCheckNotification' }, value: 'RealityCheckNotification' },
  { enable: 'IsEnableAutomaticReserveWin', label: { default: 'automaticReserveWin' }, value: 'AutomaticReserveWin' }
];

export const useExceedMaximumDepositLimit = [siteIds.GGPCOM, siteIds.GGPUK, siteIds.GGPBE, siteIds.GGPBR, siteIds.GGPCW, siteIds.GGPCZ, siteIds.GGPDE, siteIds.GGPDK, siteIds.GGPEU, siteIds.GGPFI, siteIds.GGPHU, siteIds.GGPJP, siteIds.GGPNG, siteIds.GGPPH, siteIds.GGPPL, siteIds.GGPRO, siteIds.GGPUA];

export const kycVerified = [KYC_STATUS.POA_VERIFIED, KYC_STATUS.EDD_VERIFIED];