<template>
  <div bonus-code-input>
    <button @click="setOpen" v-if="!open">{{ isGGPBE ? $t('signUp.personalInfo.havePartnerCode') : $t('signUp.personalInfo.haveBonusCode') }}</button>
    <ValidationComponent v-else :component="TextInput" v-model="model" :rules="model && model.length ? isGGPBE ? 'partnerCode' : 'bonusCode' : ''" name="_bonusCode" :preset="{maxLength: 20, disable, isTrim:true,}" :label="isGGPBE ? $t('signUp.personalInfo.partnerCodeOptional') : $t('signUp.personalInfo.bonusCodeOptional')"/>
  </div>
</template>

<script>

import TextInput from '@shared/components/common/input/TextInput.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'BonusCodeInput',
  components: { ValidationComponent },
  props: {
    value: { type: Specific, default: null },
  },
  computed: {
    site: state('env', 'site'),
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    }
  },
  data() {
    return {
      open: false,
      model: '',
      bonusCode: null,
      disable: false,
      TextInput,
    };
  },
  watch: {
    value: 'updateModel',
    model() {
      this.$emit('input', this.model);
    },
  },
  methods: {
    setOpen() {
      this.open = true;
    },
    updateModel() {
      this.model = this.value;
      if (this.model) this.open = true;
    }
  },
  created() {
    const { qtag, qtag1, btag, btag1, b } = /** @type { UrlParameters } */ this.$route.query;
    this.bonusCode = qtag || qtag1 || btag || btag1;
    if (b === '1') {
      this.open = true;
      if(this.bonusCode) {
        this.disable = true;
        this.model = this.bonusCode;
        this.$emit('input', this.model);
      }
    }
  },
  mounted() {
    this.updateModel();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[bonus-code-input] {
  > button { .ml(4); .mt(8); .c(@c-text-desc); .-b(@c-text-desc); }
}

</style>