<template>
  <div questionnaire-guide-modal>
    <div class="content" :class="{gradient}">
      <p>
        <b v-html="$t('responsibleGaming.modal.label.questionaireRiskyNote')" />
      </p>
      <ul class="risky-holder">
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote1') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote2') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote3') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote4') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote5') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote6') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote7') }}</li>
        <li>{{ $t('responsibleGaming.modal.description.questionaireRiskyNote8') }}</li>
      </ul>
      <p>
        <template v-for="obj in loketKansspelObjs">
          <template v-if="obj.key.toLowerCase() === '#text'">
            {{ obj.label }}
          </template>
          <template v-if="obj.key.toLowerCase() === 'a'">
            <a href="#" @click="openWeb(loketKansspelLink)">{{ obj.label }}</a>
          </template>
        </template>
      </p>
      <p>
        <template v-for="obj in cruksObjs">
          <template v-if="obj.key.toLowerCase() === '#text'">
            {{ obj.label }}
          </template>
          <template v-if="obj.key.toLowerCase() === 'a'">
            <a href="#" @click="openWeb(cruksLink)">{{ obj.label }}</a>
          </template>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { openWeb } from '@/utils/clientUtil';
export default {
  name: 'QuestionnaireGuideModal',
  components: { },
  props: {
    gradient: { type: Boolean, default: null },
    message: { type: String, default: null },
    subMessage: { type: String, default: null },
    type: { type: Boolean, default: null }
  },
  data() {
    return {
      loketKansspelLink: 'https://www.loketkansspel.nl/index-en.html',
      cruksLink: 'https://cruksregister.nl',
      loketKansspelObjs: [],
      cruksObjs: [],
      count: 30,
    };
  },
  computed: {},
  methods: {
    async openWeb(url) {
      await openWeb(this, url);
    },
    convertLabelObject(label) {
      if (!label) return;

      const arrLabelObj = [];
      const parser = new DOMParser();
      const nodeList = parser.parseFromString(label, "text/html")?.body?.childNodes;

      nodeList.forEach(node => {
        const { nodeName, nodeValue, innerHTML } = node;
        arrLabelObj.push({key: nodeName, label: innerHTML || nodeValue});
      });

      return arrLabelObj;
    },
    async initialize() {
      this.loketKansspelObjs = this.convertLabelObject(this.$t('responsibleGaming.modal.description.questionaireRiskyNoteGuide1'));
      this.cruksObjs = this.convertLabelObject(this.$t('responsibleGaming.modal.description.questionaireRiskyNoteGuide2'));

      let intervalId = setInterval(() => {
        if (!this.count) {
          clearInterval(intervalId);
          intervalId = null;
        } else {
          this.count--;
        }
      }, 1000);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[questionnaire-guide-modal] {
  .content {
    p { .fs(12, 16, 0.36); .c(#737373);
      b { .fs(14, 20, 0.42); .c(#000); }
      a { .fs(12, 16, 0.36); .c(#1570ef); }
    }
    ul { .mt(8); .mb(20);}
    .risky-holder{ .bgc(#FFF); .p(16); .rel(); line-height: 1.4;
      >li{.fs(12); .c(#737373); .regular(); .pl(15); }
      > li:before { .cnt("•"); .abs(); .l(16); }
    }
    p + p { .mt(12); }
  }
}
</style>
