export default class AccountService {
  #services;
  #api;
  #userApi;
  #rgConfig;
  constructor(services) {
    this.#services = services;
    this.#api = services.userApi;
    this.#userApi = services.userApi;
  }

  /**
   * @id GetPlayerMyInfoRequest
   * @description 플레이어의 개인정보를 반환
   * @param {boolean} reset
   * @returns {Promise<UserInfo>}
   * */
  async getInfo() {
    try {
      const [user, my] = await Promise.all([this.#services.onboarding.getPlayerUserInfo({ silent: true }), this.#services.onboarding.getPlayerMyInfo({ silent: true })]);
      const personalInfo = { ...user.PersonalInfo, ...my.PersonalInfo };
      const r = Object.assign(user, my);
      r.PersonalInfo = personalInfo;

      return r;
    } catch (e) {
      console.log('error : getMyInfo', e);
      throw e;
    }
  }

  /**
   * @id
   * @return {*}
   */
  getAccountStatus() {
    try {
      return this.#api.get('/player/accountStatus');
    } catch (e) {
      console.log('error : getAccountStatus');
    }
  }

  /**
   * @id GetDefaultDepositLimitRequest
   * @return {DepositLimit}
   */
  getDefaultDepositLimit() {
    try {
      return this.#api.get('/player/defaultdepositlimit');
    } catch (e) {
      console.log('error : getDefaultDepositLimit');
    }
  }

  /**
   * @id UserBalanceRequest
   * @returns {BalanceResponse}
   */
  async getBalance() {
    try {
      return await this.#api.get('/player/balance/cashier', null, { silent: true });
    } catch (e) {
      console.log('error : getBalance');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingConfigRequest
   * @returns {*}
   */
  async getRgConfig(noCache = false) {
    try {
      if (!this.#rgConfig || noCache) this.#rgConfig = await this.#api.get('/player/rg/configuration', null, { silent: true });
      return this.#rgConfig;
    } catch (e) {
      console.log('error : getRgConfig');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingDepositLimitConfigRequest
   * @returns {DepositLimitResponse}
   */
  getDepositLimit() {
    try {
      return this.#userApi.get('/player/rg/configuration/depositlimit', null, { silent: true });
    } catch (e) {
      console.log('error : getDepositLimit');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingLossLimitConfigRequest
   * @returns {LossLimitResponse}
   */
  getLossLimit() {
    try {
      return this.#api.get('/player/rg/configuration/losslimit');
    } catch (e) {
      console.log('error : getLossLimit');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingBalanceLimitConfigRequest
   * @returns {BalanceLimitResponse}
   */
  getBalanceLimit() {
    try {
      return this.#api.get('/player/rg/configuration/balancelimit');
    } catch (e) {
      console.log('error : getBalanceLimit');
      throw e;
    }
  }

  /**
   * @id GetResponsibleGamingTotalBetLimitConfigRequest
   * @returns {TotalBetLimitResponse}
   */
  getTotalBetLimit() {
    try {
      return this.#api.get('/player/rg/configuration/totalbetlimit');
    } catch (e) {
      console.log('error : getTotalBetLimit');
    }
  }

  /**
   * @id GetResponsibleGamingReLoginTimeLimitConfigRequest
   * @returns {RelogintimeLimitResponse}
   */
  getRelogintimelimit() {
    try {
      return this.#api.get('/player/rg/configuration/relogintimelimit');
    } catch (e) {
      console.log('error : getRelogintimelimit');
    }
  }

  /**
   * @id GetResponsibleGamingMonthlyLoginFrequencyConfigRequest
   * @returns {*}
   */
  getRgMonthlyLoginFrequency() {
    try {
      return this.#api.get('/player/rg/configuration/monthlyloginfrequency');
    } catch (e) {
      console.log('error : getRgMonthlyLoginFrequency');
    }
  }

  /**
   * @id SetLugasCentralFileLimitRequest
   * @param {number} CrossProviderLimitAmount
   * @returns {*}
   */
  setLugasCentralFileLimit({ CrossProviderLimitAmount }) {
    try {
      return this.#api.put('/player/rg/configuration/centralfile', { CrossProviderLimitAmount });
    } catch (e) {
      console.log('error : setLugasCentralFileLimit');
    }
  }
  /**
   * @id GetResponsibleGamingSingleBetLimitConfigRequest
   * @returns {*}
   */
  getSingleBetLimit() {
    try {
      return this.#api.get('/player/rg/configuration/singlebetlimit');
    } catch (e) {
      console.log('error : getSingleBetLimit');
    }
  }
  /**
   * @id GetResponsibleGamingLoginTimeLimitConfigRequest
   * @returns {*}
   */
  getLoginTimeLimit() {
    try {
      return this.#api.get('/player/rg/configuration/logintimelimit');
    } catch (e) {
      console.log('error : getLoginTimeLimit');
    }
  }
  /**
   * @id GetResponsibleGamingGameLimitConfigRequest
   * @returns {*}
   */
  getGameLimit() {
    try {
      return this.#api.get('/player/rg/configuration/gamelimit');
    } catch (e) {
      console.log('error : getGameLimit');
    }
  }
  /**
   * @id GetResponsibleGamingRealityCheckNotificationConfigRequest
   * @returns {*}
   */
  getRealityCheckNotification() {
    try {
      return this.#api.get('/player/rg/configuration/realitychecknotification');
    } catch (e) {
      console.log('error : getRealityCheckNotification');
    }
  }
  /**
   * @id GetResponsibleGamingAutomaticReserveWinConfigRequest
   * @returns {*}
   */
  getAutomaticReserveWin() {
    try {
      return this.#api.get('/player/rg/configuration/automaticreservewin');
    } catch (e) {
      console.log('error : getRealityCheckNotification');
    }
  }
  /**
   * @id ReaffirmResponsibleGamingConfigRequest

   * @returns {*}
   */
  reaffirmResponsibleGaminConfig({ ApplyReadyDepositLimit, ApplyReadyTotalBetLimit, ApplyReadySingleBetLimit, ApplyReadyLossLimit, ApplyReadyLoginTimeLimit, ApplyReadyReLoginTimeLimit, ApplyReadyMonthlyLoginFrequency, ApplyReadyGameLimit, ApplyReadyBalanceLimit, ApplyReadyAutomaticReserveWin, IsApprove }) {
    try {
      return this.#api.put('/player/rg/configuration/reaffirm', { ApplyReadyDepositLimit, ApplyReadyTotalBetLimit, ApplyReadySingleBetLimit, ApplyReadyLossLimit, ApplyReadyLoginTimeLimit, ApplyReadyReLoginTimeLimit, ApplyReadyMonthlyLoginFrequency, ApplyReadyGameLimit, ApplyReadyBalanceLimit, ApplyReadyAutomaticReserveWin, IsApprove });
    } catch (e) {
      console.log('error : reaffirmResponsibleGaminConfig');
    }
  }

  /**
   * @id SetResponsibleGamingDepositLimitConfigRequest
   * @param RequestDailyDepositLimit
   * @param RequestWeeklyDepositLimit
   * @param RequestMonthlyDepositLimit
   * @param SurveyAnswer1
   * @param SurveyAnswer2
   * @param SurveyAnswer3
   * @param DmiFileList - QuestionnaireDmiModal 에서 추가 된 첨부파일 목록
   * @returns {Promise<*>}
   */
  async setResponsibleDepositLimit({ RequestDailyDepositLimit, RequestWeeklyDepositLimit, RequestMonthlyDepositLimit, SurveyAnswer1, SurveyAnswer2, SurveyAnswer3, DmiFileList }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/depositlimit', { RequestDailyDepositLimit, RequestWeeklyDepositLimit, RequestMonthlyDepositLimit, SurveyAnswer1, SurveyAnswer2, SurveyAnswer3 });
      try {
        if (Array.isArray(DmiFileList) && DmiFileList.length > 0) await Promise.all(DmiFileList.map(async o => this.#services.kyc.setAddKycDocument({ DocumentType: o.type, FileData: o.img, FileName: o.file.name, IsProofOfAffordability: true })));
      } catch (e) { /* empty */ }
      return r;
    } catch (e) {
      console.log('error : setResponsibleDepositLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingTotalBetLimitConfigRequest
   * @param RequestDailyTotalBetLimit
   * @param RequestWeeklyTotalBetLimit
   * @param RequestMonthlyTotalBetLimit
   * @returns {Promise<*>}
   */
  async setResponsibleTotalBetLimit({ RequestDailyTotalBetLimit, RequestWeeklyTotalBetLimit, RequestMonthlyTotalBetLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/totalbetlimit', { RequestDailyTotalBetLimit, RequestWeeklyTotalBetLimit, RequestMonthlyTotalBetLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleTotalBetLimit');
      throw e;
    }
  }
  /**
   * @id SetResponsibleGamingLossLimitConfigRequest
   * @param RequestDailyLossLimit
   * @param RequestWeeklyLossLimit
   * @param RequestMonthlyLossLimit
   * @returns {Promise<*>}
   */
  async setResponsibleLossLimit({ RequestDailyLossLimit, RequestWeeklyLossLimit, RequestMonthlyLossLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/losslimit', { RequestDailyLossLimit, RequestWeeklyLossLimit, RequestMonthlyLossLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleLossLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingSingleBetLimitConfigRequest
   * @param RequestSingleBetLimit
   * @returns {Promise<*>}
   */
  async setResponsibleSingleBetLimit({ RequestSingleBetLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/singlebetlimit', { RequestSingleBetLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleSingleBetLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingBalanceLimitConfigRequest
   * @param RequestBalanceLimit
   * @returns {Promise<*>}
   */
  async setResponsibleBalanceLimit({ RequestBalanceLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/balancelimit', { RequestBalanceLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleBalanceLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingLoginTimeLimitConfigRequest
   * @param RequestDailyLoginTimeLimit
   * @param RequestWeeklyLoginTimeLimit
   * @param RequestMonthlyLoginTimeLimit
   * @param RequestType
   * @returns {Promise<*>}
   */
  async setResponsibleLoginTimeLimit({ RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit, RequestType }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/logintimelimit', { RequestDailyLoginTimeLimit, RequestWeeklyLoginTimeLimit, RequestMonthlyLoginTimeLimit, RequestType });
      return r;
    } catch (e) {
      console.log('error : setResponsibleLoginTimeLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingReLoginTimeLimitConfigRequest
   * @param RequestReLoginTimeLimit
   * @returns {Promise<*>}
   */
  async setResponsibleReLoginTimeLimit({ RequestReLoginTimeLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/relogintimelimit', { RequestReLoginTimeLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleReLoginTimeLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingMonthlyLoginFrequencyConfigRequest
   * @param RequestMonthlyLoginFrequency
   * @returns {Promise<*>}
   */
  async setResponsibleMonthlyLoginFrequency({ RequestMonthlyLoginFrequency }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/monthlyloginfrequency', { RequestMonthlyLoginFrequency });
      return r;
    } catch (e) {
      console.log('error : setResponsibleMonthlyLoginFrequency');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingGameLimitConfigRequest
   * @param RequestPokerGameLimit
   * @param RequestCasinoGameLimit
   * @param RequestLiveDealerGameLimit
   * @returns {Promise<*>}
   */
  async setResponsibleGameLimit({ RequestPokerGameLimit, RequestCasinoGameLimit, RequestLiveDealerGameLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/gamelimit', { RequestPokerGameLimit, RequestCasinoGameLimit, RequestLiveDealerGameLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleGameLimit');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingRealityCheckNotificationConfigRequest
   * @param RequestInterval
   * @returns {Promise<*>}
   */
  async setResponsibleRealityCheckNotification({ RequestInterval }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/realitychecknotification', { RequestInterval });
      return r;
    } catch (e) {
      console.log('error : setResponsibleGameLimit');
      throw e;
    }
  }

  /**
   * @id UpdateResponsibleGamingReCheckTargetRequest
   * @returns {Promise<*>}
   */
  async setRResponsibleGamingReCheckTarget() {
    try {
      const r = await this.#userApi.put('/player/rg/recheck');
      return r;
    } catch (e) {
      console.log('error : setRResponsibleGamingReCheckTarget');
      throw e;
    }
  }

  /**
   * @id SetResponsibleGamingAutomaticReserveWinConfigRequest
   * @param RequestReserveWinLimit
   * @returns {Promise<*>}
   */
  async setResponsibleAutomaticReserveWin({ RequestReserveWinLimit }) {
    try {
      const r = await this.#userApi.put('/player/rg/configuration/automaticreservewin', { RequestReserveWinLimit });
      return r;
    } catch (e) {
      console.log('error : setResponsibleAutomaticReserveWin');
      throw e;
    }
  }

  /**
   * @id setResponsibleGamingSelfExclusionConfigRequest
   * @param RequestSelfExclusionPeriodType
   * @param OasisBanCauses
   * @param CustomSelfExclusionPeriodDay
   * @returns {Promise<*>}
   */
  async setResponsibleSelfExclusion({ RequestSelfExclusionPeriodType, OasisBanCauses, CustomSelfExclusionPeriodDay }) {
    try {
      const r = await this.#userApi.post('/player/rg/configuration/selfexclusion', { RequestSelfExclusionPeriodType, OasisBanCauses, CustomSelfExclusionPeriodDay });
      return r;
    } catch (e) {
      console.log('error : setResponsibleSelfExclusion');
      throw e;
    }
  }
  /**
   * @id GetPaymentAccountVerifyStatusRequest
   * */
  async getPaymentAccountVerifyStatus() {
    try {
      const r = await this.#api.get('/payment/account/verify');
      return r;
    } catch (e) {
      console.log('error : getPaymentAccountVerifyStatus', e);
      throw e;
    }
  }
  /**
   * @id GetPlayerRGTransactionHistoryRequest
   * */
  async getPlayerRGTransactionHistoryRequest(payload) {
    try {
      const result = await this.#api.get('/player/rg_history', payload);
      return result;
    } catch (e) {
      console.log('error : getPlayerRGTransactionHistoryRequest', e);
      throw e;
    }
  }
  /**
   * @id GetRGLossLimitHistoryRequest
   * */
  async getRGLossLimitHistoryRequest({ AgentName, UserId }) {
    try {
      const result = await this.#api.get('/player/rg/configuration/losslimithistory', { AgentName, UserId });
      return result;
    } catch (e) {
      console.log('error : getRGLossLimitHistoryRequest', e);
      throw e;
    }
  }
  /**
   * @id GetDepositLimitRequest
   * */
  async getPaymentDepositLimit() {
    try {
      const result = await this.#api.get('/payments/deposits/limits');
      return result;
    } catch (e) {
      console.log('error : getPaymentDepositLimit', e);
      throw e;
    }
  }
  /**
   * @id GetResponsibleGamingSelfExclusionConfigRequest
   * */
  async getSelfExclusionType() {
    try {
      const result = await this.#api.get('/player/rg/configuration/selfexclusion');
      return result;
    } catch (e) {
      console.log('error : getSelfExclusionType', e);
      throw e;
    }
  }
}