<template>
  <div input-personal-info>
    <div v-if="step === STEP.DEFAULT" class="content">
      <LabelComponent
        v-for="(item, index) in useFields"
        :key="index"
        :label="$t(`_.${item.label}`)"
        :value="getLabelKey(item.key)"
      />
      <div v-if="isGGPDE && model.TPID">
        <TpidLabel />
        <LabelComponent v-model="model.TPID || '-'" />
      </div>
    </div>
    <div v-if="step === STEP.INPUT" class="content">
      <div v-for="(item, index) in useFields" :key="index">
        <InputComponent
          v-model="model[item.key]"
          :value="model[item.key]"
          :label="$t(`_.${item.label}`)"
          :name="item.name"
          :component="item.component"
          :rules="item.rules"
          :preset="{...item.preset, placeholder : $t(`_.${item.label}`), country: model[item.key] }"
          @input="value => input(item.key, value)"
          :error-msg="error[item.key]"
        />
      </div>
      <div v-if="isGGPDE && model.TPID">
        <TpidLabel />
        <InputComponent class="tpid" name="_tpid" :component="TextInputBox" v-model="model.TPID || '-'" :preset="{disable : true}" />
      </div>

    </div>

  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';
import { STEP, personalInfoProperties } from '@/constants/base/my-page';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import CountryNumberDropDown from '@/views/components/gg-pass/CountryNumberDropDown.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import CountryDropDown from '@/views/components/gg-pass/CountryDropDown.vue';
import CzCityDropDown from '@/views/components/pages/my-page/template/extra/CzCityDropDown.vue';
import UkEmploymentTypeDropDown from '@/views/components/pages/my-page/template/extra/UkEmploymentTypeDropdown.vue';
import WsopOccupationDropDown from '@/views/components/pages/my-page/template/extra/WsopOccupationDropDown.vue';
import { siteIds, siteIds as sideIds } from '@/constants/base/siteMap';
import LabelComponent from '@/views/components/gg-pass/LabelComponent.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import TooltipItem from '@/views/components/gg-pass/TooltipItem.vue';
import TpidLabel from '@/views/components/pages/my-page/template/extra/TpidLabel.vue';
import NationalityDropDown from '@/views/components/pages/my-page/template/extra/NationalityDropDown.vue';
import BusinessPhoneNumberInput from '@/views/components/pages/my-page/template/extra/BusinessPhoneNumberInput.vue';
import StateSearchSelect from '@/views/components/pages/on-boarding/StateSearchSelect.vue';
import StateComponent from '@/views/components/pages/my-page/template/extra/StateComponent.vue';

export default {
  name: 'InputPersonalInfo',
  lexicon: 'myInfo.personalDetails',
  components: { TpidLabel, TooltipItem, LabelComponent, InputComponent, FontIcon },
  props: {
    model: Specific,
    editableModel: Specific,
    error: Specific,
    step: { type: String, default: null, },
    useList: { type: Array, default: [], },
    countryWritingCode: null,
  },
  data() {
    return {
      state: null,
      stateList : null,
      TextInputBox,
      CountryNumberDropDown,
      UkEmploymentTypeDropDown,
      WsopOccupationDropDown,
      CzCityDropDown,
      NationalityDropDown,
      BusinessPhoneNumberInput,
      StateComponent
    };
  },
  computed: {
    STEP() {
      return STEP;
    },
    site: state('env', 'site'),
    useDropDownStateCountries(){
      if(!this.showState) return false;
      if(this.isWSOP) return false;
      return  ['CA', 'MX', 'US'].includes(this.model.Country)
    },
    personalInfoProperties() {
      return Object.keys(personalInfoProperties).reduce((acc, cur) => {
        let { key, type, preset } = personalInfoProperties[cur];
        let customPreset = { ...preset, disable: !this.editableModel[key], hiddenIcon: !this.editableModel[key] };
        if (key === 'PostalCode' && (this.isGGPCZ || this.isGGPPH)) {
          customPreset = {
            ...customPreset,
            digit: true,
            inputMode:'numeric',
            maxLength:4,
          };
        }
        acc[cur] = {
          ...personalInfoProperties[cur],
          component: this.getComponentOfType(type, key),
          rules: this.validateModel[key].rules,
          required: this.validateModel[key].required,
          preset: {
            ...customPreset,
          },
        };

        return acc;
      }, {});
    },
    useFields() {
      if (this.isGGPCOM) return this.useGGPCOMFields;
      if (this.isWSOP) {
        return this.useWSOPFields;
      }
      return this.useList.map(key => ({ ...this.personalInfoProperties[key] }));
    },
    showState(){
      return ['CA', 'DE', 'CZ', 'MX', 'US'].includes(this.model.Country);
    },
    useGGPCOMFields() {
      if(this.showState){
        return this.useList.map(key => ({ ...this.personalInfoProperties[key] }));
      }else return this.useList.filter(x => x !== 'State').map(key => ({ ...this.personalInfoProperties[key] }));
    },
    useWSOPFields() {
      if (!['CA', 'CZ', 'MX', 'US'].includes(this.model.Country)) {
        return this.useList.filter(x => x !== 'State').map(key => ({ ...this.personalInfoProperties[key] }));
      } else return this.useList.map(key => ({ ...this.personalInfoProperties[key] }));
    },
    // Component
    componentModel() {
      return {
        Country: CountryDropDown,
        Nationality: NationalityDropDown,
        EmploymentType: UkEmploymentTypeDropDown,
        OccupationType: WsopOccupationDropDown,
        City: this.cityComponent,
        BusinessPhoneNumber: BusinessPhoneNumberInput,
        State: StateComponent,
      };
    },
    cityComponent() {
      const isDropDown = false;
      const isCz = true;
      return isDropDown ? CzCityDropDown : TextInputBox;
    },

    // Custom Validation
    postalCodeValidationRules() {
      let min = 1;
      let max = 20;

      if (this.isGGPPH) {
        min = 4;
        max = 4;
        return `length:${min},${max}`;
      }
      if (this.isGGPCZ) {
        max = 5;
        return `length:${min},${max}`;
      }
      if (this.addressRequired) {
        return `required|validateAddress:${min},${max},${this.countryWritingCode},PostalCode`;
      }

      return `validateAddress:${min},${max},${this.countryWritingCode},PostalCode`;
    },
    isGGPPH() {
      return this.site === siteIds.GGPPH;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isGGPCOM() {
      return this.site === siteIds.GGPCOM;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    addressRequired() {
      // return ![sideIds.GGPUK, siteIds.N8IN].includes(this.site);
      return ![siteIds.N8IN].includes(this.site);
    },

    employmentList() {
      return [
        { label: this.$t('myInfo.employed'), value: 'Employed' },
        { label: this.$t('myInfo.selfEmployed'), value: 'SelfEmployed' },
        { label: this.$t('myInfo.retired'), value: 'Retired' },
        { label: this.$t('myInfo.student'), value: 'Student' },
        { label: this.$t('myInfo.unemployed'), value: 'Unemployed' },
      ];
    },

    validateModel() {
      return {
        Address: { rules: this.getAddressValidateRules('Address', 0, 120, this.addressRequired) },
        State: { rules: this.useDropDownStateCountries ? 'requiredSelect' : this.getAddressValidateRules('State', 0, 120, this.addressRequired) },
        City: { rules: this.getAddressValidateRules('City', 0, 50, this.addressRequired) },
        PostalCode: { rules: this.postalCodeValidationRules },
        Street: { rules: this.getAddressValidateRules('Street', 0, 150, true), },
        StreetName: { rules: this.getAddressValidateRules('StreetName', 0, 150, true), },
        StreetNumber: { rules: 'required|lengthMax:20|validateHouseNumber:StreetNumber', required: true },
        HouseNumber: { rules: 'required|lengthMax:20|validateHouseNumber:HouseNumber', required: true },
        MaidenName: { rules: this.getAddressValidateRules('MaidenName', 0, 50, true) },
        PlaceOfBirth: { rules: this.getAddressValidateRules('PlaceOfBirth', 0, 50, true) },
        MiddleName: { rules: `validateName:0,50,${this.countryWritingCode},MiddleName` },
        AdditionalAddress: { rules: this.getAddressValidateRules('AdditionalAddress', 0, 120, false) },
        BuildingAddress: { rules: this.getAddressValidateRules('BuildingAddress', 0, 150, [sideIds.GGPUK].includes(this.site)) },
        Building: { rules: this.getAddressValidateRules('Building', 0, 150, [sideIds.GGPUK].includes(this.site)) },
        EmploymentType: { rules: '' },
        EmployerName: { rules: `length:0,50` },
        EmployerAddress: { rules: `length:0,250` },
        BusinessPhoneNumber: { rules: 'length:0,50' },
        Alias: { rules: this.getAddressValidateRules('Alias', 0, 50, false), },
        Nationality: { rules: 'required' },
        Country: { rules: `required` },
        OccupationType: { rules: `required` },
      };
    }
  },
  methods: {
    input(key, value) {
      this.$emit('update', key, value);
    },
    getAddressValidateRules(key, min = null, max = null, required = false) {
      const rules = required ? 'required|validateAddress' : 'validateAddress';
      const countryWritingCode = this.countryWritingCode;
      return `${rules}:${[min, max, countryWritingCode, key].join(',')}`;
    },

    getComponentOfType(type, key = '') {
      switch (type) {
        case 'text' :
          return TextInputBox;
        case 'custom' : {
          return this.componentModel[key];
        }
        default :
          return null;
      }
    },
    getLabelKey(key) {
      if (key === 'Country' || key === 'Nationality') {
        return this.model[key] ? this.$t(`country.${this.model[key]}`) : '-';
      }
      if(key === 'State' && this.showState && this.useDropDownStateCountries){
        return this.state?.label || '-';
      }
      if (key === 'OccupationType') return this.model[key] ? this.$t(`occupation.${this.model[key]}`) : '-';
      if (key === 'EmploymentType') return this.model[key] ? this.employmentList.find(v => v.value === this.model[key])?.label : '-';
      return this.model[key];
    },
  },
  async mounted() {
    if (this.model.State && this.showState && this.useDropDownStateCountries) { // state를 노출하며 dropSelect 사용 시 값이 있을 경우 값을 할당 해줘야 함
      this.stateList = (await this.$services.base.getStateList({ SiteId: this.site })).map(o => ({ label: o.StateName, value: o.StateCode }));
      this.state = this.stateList?.find(item => [item.label, item.value].includes(this.model.State));
      if(!this.state) this.model.State = null;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-personal-info] {
  [search-drop-select] > a { .c(black); }
  .content {.flex(); .flex-dc(); .gap(10);}
}
</style>