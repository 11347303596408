<template>
  <ValidationObserver enhanced-due-diligence v-slot="{ invalid }">
    <div class="header-holder">
      <div v-if="message" class="message">
        <FontIcon name="info-circle-line" />
        <div>{{ message }}</div>
      </div>
      <p class="desc" v-html="$t('_.description')" />
    </div>
    <div class="content-holder">
      <p v-html="`1. ${$t('_.employmentStatusTitle')}`" />
      <div class="content">
        <ValidationComponent v-if="employmentStatusList" :label="$t('onBoarding.employmentStatus')" name="_employmentStatus" :component="SearchDropSelect" :rules="employmentStatusRules" :preset="employmentStatusPreset" :value="employmentStatus" @input="updateEmploymentStatus" />
        <ValidationComponent v-if="showOccupation" :label="$t('onBoarding.occupation')" name="_occupation" :component="SearchDropSelect" :rules="occupationRules" :preset="occupationPreset" :value="occupation" @input="updateOccupation" />
      </div>
    </div>
    <div class="content-holder">
      <p v-html="`2. ${$t('_.desiredLimitTitle')}`" />
      <div class="content">
        <DesiredLimit :modelInfo="modelInfo" :structure="structure" :item="item" :is-reverse="isGGPNL" />
        <CheckAffordability v-model="affordabilityCheck" @change="updateAffordability" />
      </div>
    </div>
    <ProgressButton button-name="next" :disabled="invalid" @click="next" />
  </ValidationObserver>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import baseTemplate from '@/mixins/baseTemplate';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import DesiredLimit from '@/views/components/extra/DesiredLimit.vue';
import CheckAffordability from '@/views/components/extra/CheckAffordability.vue';
import { kycEmploymentDocuments, kycIndustryDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';

export default {
  name: 'EnhancedDueDiligence',
  lexicon: 'kyc.enhancedDueDiligence',
  components: { CheckAffordability, DesiredLimit, ProgressButton, ValidationComponent, FontIcon },
  mixins: [baseTemplate],
  props: {
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
    message: { type: String, default: '' },
  },
  data() {
    return {
      TrimTextInput,
      SearchDropSelect,
      employmentStatus: null,
      employmentStatusList: null,
      occupation: null,
      occupationList: null,
      affordabilityCheck: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    employmentStatusPreset() {
      return { list: this.employmentStatusList, placeholder: this.$t('_.employmentStatusPlaceHolder') };
    },
    occupationPreset() {
      return { list: this.occupationList, placeholder: this.$t('_.occupationPlaceHolder') };
    },
    employmentStatusRules() {
      return 'requiredEmploymentStatus';
    },
    occupationRules() {
      return this.showOccupation ? 'requiredOccupation' : '';
    },
    showOccupation() {
      return ['Employed', 'SelfEmployed'].includes(this.employmentStatus?.value);
    },
    isGGPNL(){
      return this.site === siteIds.GGPNL;
    }
  },
  methods: {
    async updateEmploymentStatus(item) {
      this.employmentStatus = item;
      this.modelInfo.EmploymentType = item?.value;
      if (!this.showOccupation) this.modelInfo.OccupationType = 'NotEmployed';
    },
    updateOccupation(item) {
      if (!item) return;
      this.occupation = item;
      this.modelInfo.OccupationType = item?.value;
    },
    updateAffordability(value) {
      this.affordabilityCheck = value;
    },
    async getOccupationType() {
      let r;
      try {
        r = await this.$services.onboarding.getAllOccupationType();
      } catch (e) {
        r = e;
      }

      if (r.error) {
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;
      }

      return r;
    },
    async initialize() {
      const r = await this.getOccupationType();
      const allOccupation = r.OccupationTypes;

      this.employmentStatusList = kycEmploymentDocuments.map(doc => ({ label: this.$t(doc.label), value: doc.value }));
      this.occupationList = allOccupation?.filter(type => type !== 'None').map(type => ({ label: this.$t(`occupation.${type}`), value: type }));

      await sleep(50);

      if (this.modelInfo?.EmploymentType) this.employmentStatus = this.employmentStatusList.find(v => v.value === this.modelInfo.EmploymentType);
      if (this.modelInfo?.OccupationType) this.occupation = this.occupationList.find(v => v.value === this.modelInfo.OccupationType);
    },
    async next() {
      this.$emit('update', kycStatus.Edd);
    }
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.declarationOfAffordability';
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[enhanced-due-diligence] {
  .header-holder {
    .message { .flex(); .flex-ai(); .mb(24); .p(24); .br(8); .bgc(#333);
      > [font-icon] { .fs(24); .mr(8); }
      > div { word-break: break-word; .pre-line(); }
    }
  }
  .content-holder { .pt(20); .pb(16);
    > span { .fs(14); }
    .content { .pt(12);
      .disposableIncome { .flex(); .flex-ai();
        [validation-component] { .pl(12); .w(100%); }
      }
      > [validation-component] + [validation-component] { .mt(8); }
    }
  }
  .content-holder + .content-holder { .-t(#333); }
}
</style>
