<template>
  <div proof-of-identity-ph>
    <div class="holder" v-for="(info, i) in [popoverPoiInfo, popoverPosInfo]" :key="i">
      <h5><FontIcon name="check"/>{{ $t(info.title) }}</h5>
      <ul>
        <li>
          <template v-for="labelObj in info.labelObjs">
            <div>
              <template v-for="obj in labelObj">
                <template v-if="obj.key.toLowerCase() === '#text'">
                  {{ obj.label }}
                </template>
                <template v-if="obj.key.toLowerCase() === 'button'">
                  <button v-popover.bottom.left="{component: obj.component, maxWidth: 400, useClick: true}">{{ obj.label }}</button>
                </template>
              </template>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <FileSelector @update="fileUpdate" ref="selector">
      <p v-html="$t('kyc.dropFiles', { fileLength: maxFileLength, fileSize: maxFileSize })" />
    </FileSelector>
    <p class="add-document">{{ $t('kyc.addDocument') }}</p>
    <div class="list-holder" v-if="fileList && fileList.length">
      <UploadItem v-for="(item, id) in fileList" :key="id" :info="item" :selectList="poiList" @remove="remove(id)" />
    </div>
    <ProgressButton button-name="upload" :disabled="!verifyDocument" @click="upload" />
  </div>
</template>

<script>
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getKycDocuments, kycStatus } from '@/constants/base/onboarding/kyc';
import { siteIds } from '@/constants/base/siteMap';
import FileSelector from '@/views/components/pages/on-boarding/FileSelector.vue';
import UploadItem from '@/views/components/extra/UploadItem.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import PhPoiFloating from '@/views/components/pages/on-boarding/asset/PhPoiFloating.vue';
import PhPosFloating from '@/views/components/pages/on-boarding/asset/PhPosFloating.vue';
import UploadErrorModal from '@/views/components/pages/on-boarding/modal/UploadErrorModal.vue';

export default {
  name: 'ProofOfIdentityPh',
  components: { ProgressButton, UploadItem, FileSelector, FontIcon },
  lexicon: 'kyc',
  props: {
    item: { type: Specific, default: null },
    kycDocumentGroups: /** @type {Array<KycDocumentGroup>} */ Specific,
  },
  data() {
    return {
      isFromWithdrawal: false, // @todo: withdrawal(출금) 화면에서 접근한건지 체크
      fileList: null,
      uid: 0,
      maxFileLength: 5,
      maxFileSize: 10,
      popoverPoiInfo: {
        title: 'kyc.documentType.ProofOfIdentity',
        translateKeys: ['kyc.proofOfIdentity.documentDescription'],
        labelObjs: []
      },
      popoverPosInfo: {
        title: 'kyc.proofOfSelfie.proofOfSelfie',
        translateKeys: ['kyc.proofOfSelfie.documentDescription1', 'kyc.proofOfSelfie.documentDescription2'],
        labelObjs: [],
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    poiDefaultKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Default');
    },
    poiMandatoryKycDocumentList() {
      if (!this.kycDocumentGroups) return [];
      const poiKycDocumentGroup = this.findKycDocumentGroup(this.kycDocumentGroups, kycStatus.Poi);
      return this.convertKeyValue(poiKycDocumentGroup, 'Mandatory');
    },
    verifyDocument() {
      const emptySelectFile = !_.every(this.fileList, f => f.type && f.type !== '0');
      const existDefaultFile = _.some(this.poiDefaultKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));
      const existMandatoryFile = _.every(this.poiMandatoryKycDocumentList, doc => _.some(this.fileList, f => doc.value === f.type));

      if(this.fileList?.length && !emptySelectFile){
        if(this.poiDefaultKycDocumentList?.length && this.poiDefaultKycDocumentList?.length > 0){
          return existDefaultFile && existMandatoryFile;
        }else{
          return existDefaultFile || existMandatoryFile;
        }
      }else{
        return false;
      }

      // return this.fileList?.length && !emptySelectFile && existDefaultFile && existMandatoryFile;
    },
    poiList() {
      return [...(this.poiDefaultKycDocumentList ?? []), ...(this.poiMandatoryKycDocumentList ?? [])];
    },
    example() {
      if ([siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.TWOACE].includes(this.site)) return [this.$t('_.documentTypesOk')];
      if (this.site === siteIds.GGPCZ) return [this.$t('_.documentTypesCz1'), this.$t('_.documentTypesCz2')];
      if (this.site === siteIds.N8IN && this.isFromWithdrawal) return [this.$t('_.nationalId'), this.$t('_.aadharCard')];
      return [this.$t('_.documentTypes')];
    },
  },
  methods: {
    /**
     * Kyc Document 정보를 Label, Value 형태로 변환
     * @param {KycDocumentGroup} kycDocumentGroup - Kyc Document
     * @param type - Default, Mandatory
     * @returns {{label: *, value: *}[]|*[]}
     */
    convertKeyValue(kycDocumentGroup, type) {
      if (!kycDocumentGroup) return [];
      const documents = type === 'Default' ? kycDocumentGroup.DefaultKycDocuments : kycDocumentGroup.MandatoryKycDocuments;
      if (!documents) return [];

      const kycDocuments = getKycDocuments(this.site);
      return documents.map(document => {
        const kycDocument = kycDocuments.find(type => type.value === document);
        return kycDocument ? { label: kycDocument?.label, value: kycDocument?.value, description: kycDocument?.description } : null;
      });
    },
    /**
     * 올려야 할 Kyc document 중 Type(Poi, Poa) 별 정보 가져오기
     * @param {KycDocumentGroup[]} kycDocumentGroups - 업로드 필요한 Kyc document group
     * @param {string} type - Kyc document Type(Poi, Poa)
     * @returns {*}
     */
    findKycDocumentGroup(kycDocumentGroups, type) {
      return kycDocumentGroups?.find(group => group.KycDocumentGroupType === type);
    },
    fileUpdate(list) {
      this.fileList = list;
      this.uid++;
    },
    remove(id) {
      this.$refs.selector.remove(id);
    },
    convertLabelObject(label, component) {
      if (!label) return;

      const arrLabelObj = [];
      const parser = new DOMParser();
      const nodeList = parser.parseFromString(label, "text/html")?.body?.childNodes;

      nodeList.forEach((node, i) => {
        const { nodeName, nodeValue, innerHTML} = node;
        if (nodeName.toLowerCase() === 'button') arrLabelObj.push({ key: nodeName, label: innerHTML || nodeValue, component });
        else arrLabelObj.push({key: nodeName, label: innerHTML || nodeValue, });
      });

      return arrLabelObj;
    },
    async upload() {
      const info = /** @type { ErrorResponse[] } */ await Promise.all(this.fileList.map(async o => this.$services.kyc.setAddKycDocument({ DocumentType: o.type, FileData: o.img, FileName: o.file.name })));
      const documentTypes = this.fileList.filter((o, id) => !info[id].error).map(o => o.type);
      if (documentTypes && documentTypes.length > 0) await this.$services.kyc.setKycRequisition(documentTypes.join(','));
      if (info.find(o => o.error)) {
        const r = await this.$modal(UploadErrorModal, { info: this.fileList.map((o, id) => ({ ...o, status: info[id] })) });
        if (!r) return;
      }
      this.$emit('update');
    },
    async initialize() {
      this.popoverPoiInfo.labelObjs.push(this.convertLabelObject(this.$t(this.popoverPoiInfo.translateKeys[0]), PhPoiFloating));
      this.popoverPosInfo.labelObjs.push(this.convertLabelObject(this.$t(this.popoverPosInfo.translateKeys[0])));
      this.popoverPosInfo.labelObjs.push(this.convertLabelObject(`• ${this.$t(this.popoverPosInfo.translateKeys[1])}`, PhPosFloating));
    },
  },
  async mounted() {
    if (this.item) this.item.title = 'onBoarding.title.proofOfIdentitySelfie';
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[proof-of-identity-ph] {
  .holder { .pt(15);
    h5 { .mb(8); .medium();
      [font-icon] { .fs(10, 16); .br(50); .bgc(@c-mint); .wh(16); .ib(); .tc(); .mr(4);}
    }
    > ul { .pl(26); .fs(14, 20);
      li { .c(@c-w07);
        &:not(:nth-of-type(1)) { .mt(4); }
        &:before { .pr(10); }
        span { .block(); }
        button { .c(#2680EB); .underline(); }
      }
    }
  }
  .holder + .holder { .mt(15); .-t(#3e3e3e); }
  [image-selector] { .mt(20); }
  .add-document { .c(#ccc); .m(20, 0); }
}
</style>
