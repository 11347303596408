<template>
  <div invitation-bonus>
    <div class="qr-container" v-if="agentName">
      <img :src="`${cdnImgPath}/${logo}.png`" alt="ggpuke wsop">
      <template>
        <div v-if="agentName === 'incomeaccess'" class="warning">
          <FontIcon name="sharp-triangle-warning" />
          <p>{{ $t('_.notAvailable') }}</p>
        </div>
        <div v-else>
          <div class="qr-box">
            <QrcodeVue type="canvas" :value="QRCode" size="120" class="qr" />
          </div>
          <p v-html="$t('_.scanInvitationQRCode')" />
          <div class="btn-holder">
            <button @click="e => copyCode(e, QRCode)">复制邀请链接</button>
            <p class="message" v-show="isShowMessage">
              <FontIcon name="content-copy" />
              <span>{{ $t('promotion.v1.copiedToClipboard') }}</span>
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils';
import { siteIds } from '@/constants/base/siteMap';
import QrcodeVue from 'qrcode.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { copyClipboard, sleep } from '@shared/utils/commonUtils';

export default {
  name: 'InvitationBonus',
  lexicon: 'promotion.v1.invitation',
  components: { QrcodeVue, FontIcon },
  data() {
    return {
      info: null,
      isShowMessage: false,
      agentName: null,
    };
  },
  computed: {
    token: state('user', 'token'),
    selectedLang: state('query', 'selectedLang'),
    baseLang: state('env', 'baseLang'),
    site: state('env', 'site'),
    QRCode() {
      const signupUrl = process.env[`VUE_APP_SIGNUP_URL_${siteIds[this.site]}`];
      const nickname = this.$route.query?.nickname;
      return `${signupUrl}?invite=${nickname}&lang=${this.selectedLang || this.baseLang}`;
    },
    logo() {
      return this.site === siteIds.EVPUKE ? 'evpuke_wsop2022' : 'ggpuke_wsop2020';
    }
  },
  methods: {
    async showMessage() {
      this.isShowMessage = true;
      await sleep(1000);
      this.isShowMessage = false;
    },
    async copyCode(e, code) {
      try {
        copyClipboard(code);
        await this.showMessage();
      } catch (e) {
        alert(`copy error: ${e}`);
      }
    },
  },
  async mounted() {
    const r = await this.$services.onboarding.getMyInfo();
    this.agentName = r?.AgentName;
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[invitation-bonus] { .h(640); .p(20); .bgc(@c-nero); .tc();
  * {font-family: 'Roboto', sans-serif;}
  .qr-container { .flex(); .flex-dc(); .items-center(); .hf(); .ph(8);
    .bgc(@c-black);
  }
  img { .w(300); .max-w(100%); .mt(80); }
  .qr-box { .wh(140); .mv(40); .mh-c(); .p(10); .bgc(@c-white);
    .pr { .f(); }
  }
  .btn-holder { .ib(); .rel(); .mt(52);
    button { .min-w(200); .p(14, 32); .br(4); .bgc(#946d24); .c(@c-white); .fs(16); .bold(); .tr-d(0.35s);
      &:hover, &:focus, &:active { .bgc(#ac8a4b); }
    }
    .message { .abs(); .b(100%); .r(0); .mb(6); .p(4, 8); .br(2); .bgc(#eaeaea); .c(#333); .fs(16);
      &::after { .cnt(); .abs(); .r(12); .t(100%); .wh(6); .bgc(inherit); transform: translateY(-50%) rotate(45deg); }
    }
  }
  .warning { .c(@c-grey67);
    [font-icon] { .ib(); .fs(120); .pt(80); .pb(32); }
    p { .fs(20); }
  }
}
</style>
