import { isExcludeGgpass } from '@/utils/baseUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { gpSiteIds } from '@/constants/base/siteMap';

export default class VerificationService {
  #services;
  #api;
  #userApi;
  #ggpass;
  #store;
  #site;
  #app;
  #emailType;
  #mobileType;
  constructor(services) {
    this.#services = services;
    this.#store = services.store;
    this.#api = services.api;
    this.#userApi = services.userApi;
    this.#ggpass = services.passApi;
    this.#site = this.#store.state.env.site;
    this.#app = this.#store.state.query.app;

    this.#emailType = 'EMAIL';
    this.#mobileType = 'MOBILE';
  }

  /**
   * @id SendEmailVerificationRequest
   * @param VerificationType
   * @param Email
   * @param UserId
   * @param Language
   * @param Kind
   * @param RecaptchaToken
   * @param RecaptchaSiteKey
   * @param RecaptchaAction
   * @param Challenge
   * @param SecCode
   * @returns {Promise<*|{value: *, key: string}>}
   */
  async #sendNpUserEmailVerification({ VerificationType = 'ContactInfo', Email, UserId, Language = 'en', RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, }) {
    try {
      const r = await this.#userApi.post('/player/email/verification', { VerificationType, Email: (Email ? Email.trim() : undefined), UserId, Language, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode });
      if (r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) {
        return { ...r, key: 'limit', value: (r.desc || r.desc2).replace(/[^0-9]/g, '') };
      }
      return r;
    } catch (e) {
      if (e?.code === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...e, key: 'limit', value: (e.desc || e.desc2).replace(/[^0-9]/g, '') };
      console.log('error : #sendNpUserEmailVerification');
      throw e;
    }
  }

  /**
   * @id SendMobileVerificationCodeRequest
   * @param {string} VerificationType
   * @param {string} MessageType
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} ContactMobileNumber
   * @param {string} UserId
   * @param {string} UserName
   * @param {string} RecaptchaToken
   * @param {string} RecaptchaSiteKey
   * @param {string} RecaptchaAction
   * @param {boolean} IsOffline
   * @param {string} SecCode
   * @param {string} Challenge
   * @param {string} Validate
   * */
  async #sendNpUserMobileVerification({ CountryCode, MobileNumber, ContactMobileNumber, IsOffline = false, MessageType = 'SMS', UserId, UserName, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, Validate, VerificationType = 'ContactInfo', IsUpdateContactInfo = false }) {
    try {
      if (ContactMobileNumber) MobileNumber = ContactMobileNumber;
      const r = await this.#userApi.post('/player/mobile/verification', { CountryCode, MobileNumber, IsOffline, MessageType, UserId, UserName, RecaptchaToken, RecaptchaSiteKey, RecaptchaAction, Challenge, SecCode, Validate, VerificationType });
      if (r?.key === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: (r.desc || r.desc2).replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      if (e?.code === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { ...e, key: 'limit', value: (e.desc || e.desc2).replace(/[^0-9]/g, '') };
      console.log('error : #sendNpUserMobileVerification');
      throw e;
    }
  }

  /**
   * @param payload
   * @param type
   * @param isEmail
   * @returns {Promise<{[p: string]: *}|*>}
   */
  async #sendGpVerification(payload, type = 'SIGN_UP') {
    const { SendVerificationType } = payload;
    const isEmail = SendVerificationType === 'EMAIL';
    try {
      const email = isEmail ? payload.Email?.trim() : null;
      const countryCode = isEmail ? null : payload.CountryCode;
      const mobileNumber = isEmail ? null : payload.MobileNumber;
      const action = payload.RecaptchaAction;
      const recaptchaToken = payload.RecaptchaToken;
      const brandId = this.#store.state.env.gpBrand;
      // EVPUKE일 경우에만 siteID 발송
      const siteId = this.#store.state.env.gpSite === gpSiteIds.EVPUKE ? this.#store.state.env.gpSite : null;
      const challenge = payload.Challenge;
      const secCode = payload.SecCode;
      const result = await this.#ggpass.post('/op/v1/onepass/verification-codes/send', { email, countryCode, mobileNumber, type, action, recaptchaToken, brandId, siteId, challenge, secCode }, { silent: true });
      if (result.error && result.key === apiErrorCode.RESEND_TERM_LIMITED) return { ...result, key: 'limit', value: result.desc2.replace(/[^0-9]/g, '') };

      return result;
    } catch (e) {
      console.log('error : #sendGpVerification', e);
    }
  }

  /**
   * @param payload
   * @param type
   * @returns {Promise<*|{value: *, key: string}|{value: *, key: string}|undefined|{[p: string]: *}>}
   */
  async #myInfoVerificationAdapter(payload, type) {
    const site = this.#store.state.env.site;
    const { ApiType, SendVerificationType } = payload;
    const isEmail = SendVerificationType === 'EMAIL';
    try {
      switch (ApiType) {
        case 'CHANGE_LOGIN_ID': {
          if (isExcludeGgpass(site)) {
            payload.VerificationType = 'LoginId';
            return isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload);
          }

          const changeIdPayload = isEmail ? { ...payload, Code: payload.VerificationCode } : { ...payload, Code: payload.VerificationCode, CountryCode: `+${payload.CountryNumber}` };
          // return this.#sendGpVerification(changeIdPayload, type);
          const result = await this.#services.ggpass.setLoginId(changeIdPayload);
          if(result.error){
            if(result.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) return {...result, error : false, key : null,}
            if (result.key === apiErrorCode.RESEND_TERM_LIMITED) return { ...result, key: 'limit', value: result.desc2.replace(/[^0-9]/g, '') };
            else return result;
          }
          return result;
        }
        case 'MFA_TYPE_ADD': {
          if (isExcludeGgpass(site)) {
            payload.VerificationType = 'LoginId';
            return isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload);
          }

          return await this.#services.mfa.sendMfa(payload);
        }
        case 'CONTACT_INFO': {
          payload.VerificationType = 'ContactInfo';
          return isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload);
        }
      }
    } catch (e) {
      console.log('error : #myInfoVerificationAdapter', e);
      throw e;
    }
  }

  /**
   * @description 비밀번호 재설정을 위해 사용자를 식별할 e-Mail 이나 Mobile 을 확인
   * @id GetPlayerIdentityVerificationMethodsRequest
   * @param LoginMethod
   * @param CountryCode
   * @param MobileNumber
   * @param Email
   * @param Username
   * @param SiteId
   * @param RecaptchaToken
   * @param RecaptchaAction
   * @param RecaptchaSiteKey
   * @param IsOffline
   * @param Challenge
   * @param SecCode
   * @param Validate
   * @returns {Promise<*|{error: boolean, key: string}>}
   */
  async #sendNpPublicIdentityVerification({ LoginMethod, CountryCode, MobileNumber, Email, Username, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline, Challenge, SecCode, Validate }) {
    try {
      const r = await this.#api.post('/player/identity/verification/methods', { LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, SiteId, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, IsOffline, Challenge, SecCode, Validate }, { silent: true });
      if (r.IsValidPlayer === false && r.SiteId === 'None') {
        console.log('error : getIdentityVerificationMethods', r);
        return { error: true, key: apiErrorCode.INVALID_PLAYER };
      }
      return r;
    } catch (e) {
      console.log('error : #sendNpPublicIdentityVerification');
      throw e;
    }
  }

  /**
   * @id VerifyEmailVerificationCodeVerifyByIdentityKeyRequest
   * @param {number} PlayerEmailVerificationType
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {string} VerificationCode
   * @param {string|null} RecaptchaToken
   * @param {string|null} Challenge
   * @param {string|null} SecCode
   * @param {string|null} Validate
   * @param {boolean|null} IsOffline
   * @param {number} SiteId
   * @returns
   */
  async #verifyNpEmailFpCode({ PlayerEmailVerificationType, LoginMethod, CountryCode, MobileNumber, Email, Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, VerifyCode, SiteId = 'None' }) {
    const VerificationCode = VerifyCode;
    try {
      const r = await this.#api.patch('/player/email/forgotpassword', { PlayerEmailVerificationType, LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, VerificationCode, Challenge, SecCode, SiteId }, { silent: true });
      if (r.error && r.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #sendNpEmailVerificationCodeByIdentityKey');
      throw e;
    }
  }

  /**
   * @id VerifyMobileVerificationCodeByIdentityKeyRequest
   * @param {number} PlayerEmailVerificationType
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string} Username
   * @param {string} VerificationCode
   * @param {string|null} RecaptchaToken
   * @param {string|null} Challenge
   * @param {string|null} SecCode
   * @param {string|null} Validate
   * @param {boolean|null} IsOffline
   * @param {number} SiteId
   * @returns
   */
  async #verifyNpMobileFpCode({ VerificationType, LoginMethod, CountryCode, MobileNumber, Email, Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, VerifyCode, SiteId = 'None' }) {
    const VerificationCode = VerifyCode;
    try {
      const r = await this.#api.patch('/player/mobile/forgotpassword', { VerificationType, LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email.trim() : undefined), Username, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, VerificationCode, Challenge, SecCode, SiteId }, { silent: true });
      if (r.error && r.key === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #sendNpMobileVerificationCodeByIdentityKey');
      throw e;
    }
  }

  /**
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param type { String }
   * @param code { String }
   * @param token { String }
   * @param method { String }
   * @returns { Promise<null> }
   * */
  #sendGpVerificationCode(payload) {
    const isEmail = payload.SendVerificationType === 'EMAIL';
    try {
      const email = isEmail ? payload.Email : null;
      const countryCode = isEmail ? null : payload.CountryCode;
      const mobileNumber = isEmail ? null : payload.MobileNumber;
      const type = payload.Type;
      const code = payload.Code;
      const method = payload.Method;
      const token = payload.Token;
      const username = payload.UserName;

      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;

      return this.#ggpass.post('/op/v1/onepass/verification-codes/verify', { email, countryCode, mobileNumber, type, token, code, method, username, brandId, siteId }, { silent: true });
    } catch (e) {
      console.log('error : #sendGpVerificationCode', e);
    }
  }

  /**
   * @description 비밀번호 재설정에 필요한 인증코드 전송
   * @id SendEmailVerificationForgotPasswordRequest
   * @param LoginMethod
   * @param CountryCode
   * @param MobileNumber
   * @param Email
   * @param Username
   * @param SiteId
   * @param Challenge
   * @param SecCode
   * @param RecaptchaSiteKey
   * @param RecaptchaToken
   * @param RecaptchaAction
   * @param Validate
   * @param IsOffline
   * @returns {Promise<{[p: string]: *}|*|{value: *, key: string}|undefined|null|{error: boolean, key: string}>}
   */

  async #sendNpFpEmailVerification({ LoginMethod = 'Email', CountryCode, MobileNumber, Email, Username, SiteId = 'None', Challenge, SecCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Validate, IsOffline, Language = 'en' }) {
    try {
      const r = await this.#api.post('/player/email/verification/forgotpassword', { CountryCode, Email: (Email ? Email.trim() : undefined), LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, SiteId, Username, Validate, IsOffline, Language }, { silent: true });
      if (r.error && r.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #npFpEmailVerification', e);
    }
  }

  /**
   * @description 비밀번호 재설정을 위한 모바일 인증 코드 전송
   * @id SendMobileVerificationForgotPasswordRequest
   * @param CountryCode
   * @param MessageType
   * @param Email
   * @param LoginMethod
   * @param MobileNumber
   * @param Username
   * @param Challenge
   * @param SecCode
   * @param RecaptchaSiteKey
   * @param RecaptchaToken
   * @param RecaptchaAction
   * @param SiteId
   * @param Validate
   * @param IsOffline
   * @returns {Promise<{[p: string]: *}|*>}
   */
  async #sendNpFpMobileVerification({ CountryCode, MessageType = 'SMS', Email, LoginMethod = 'Email', MobileNumber, Username, Challenge, SecCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction = 'ForgotPasswordEmailSendCode', SiteId = 'None', Validate, IsOffline, Language = 'en' }) {
    try {
      const r = await this.#api.post('/player/mobile/verification/forgotpassword', { CountryCode, Email: (Email ? Email.trim() : undefined), MessageType, LoginMethod, MobileNumber, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Challenge, SecCode, SiteId, Username, Validate, IsOffline, Language }, { silent: true });
      if (r.error && r.key === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #npFpMobileVerification');
      throw e;
    }
  }

  /**
   * @id EditMobileNumberRequest
   * @param {string} VerificationCode
   * @param {string} MobileCountryCode
   * @param {string} MobileNumber
   * @param {string} ContactMobileNumber
   * @param {boolean} IsSkipMobileVerification
   * @param {string} VerificationType
   * */
  async #editOnboardingVerifyMobileCode({ IsSkipMobileVerification = false, CountryCode, MobileNumber, ContactMobileNumber, VerificationCode, VerificationType = 'ContactInfo' }) {
    try {
      if (ContactMobileNumber) MobileNumber = ContactMobileNumber;
      return this.#userApi.patch('/player/mobile', { IsSkipMobileVerification, CountryCode, MobileNumber, VerificationCode, VerificationType });
    } catch (e) {
      console.log('error : #editOnboardingVerifyCode', e);
      throw e;
    }
  }

  /**
   * @description 이메일 인증 번호 전송
   * @id SendEmailVerificationCodeRequest
   * @param {string} PlatformType
   * @param {string} Email
   * @param {string} Password
   * @param {string} BonusCode
   * @param {string} BTag
   * @param {string} CountryCode
   * @param {string} StateCode
   * @param {string} Invite
   * @param {string} RecaptchaToken
   * @param {string?} RecaptchaAction
   * @param {string?} RecaptchaSiteKey
   * @param {string?} Challenge
   * @param {string?} SecCode
   * @param {string?} Validate
   * @param {boolean} IsOffline
   * @param {string} SiteId
   * @param {boolean} IsResend
   * @return
   */
  async #editNpSignupVerifyEmailCode({ PlatformType = '0', Email, Password, BonusCode, BTag, CountryCode, Invite, RecaptchaToken = '', RecaptchaAction, RecaptchaSiteKey, Challenge = '', SecCode = '', Validate = '', IsOffline = false, SiteId, IsResend = false, }) {
    try {
      const r = await this.#api.put('/player/signup/email', { PlatformType, Email: (Email ? Email.trim() : undefined), Password, BonusCode, BTag, CountryCode, Invite, RecaptchaToken, RecaptchaAction, RecaptchaSiteKey, Challenge, SecCode, Validate, IsOffline, SiteId, IsResend });
      if (r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #editNpSignupVerifyEmailCode');
      throw e;
    }
  }

  /**
   * @id VerifyAndEditEmailRequest
   * @param {string} Email
   * @param {string} VerificationCode
   * @param {string} VerificationType
   * @returns {*}
   */
  async #editOnboardingVerifyEmailCode({ Email, VerificationCode, VerificationType = 'ContactInfo' }) {
    try {
      return this.#userApi.patch('/player/email', { Email: (Email ? Email.trim() : undefined), VerificationCode, VerificationType });
    } catch (e) {
      console.log('error : #editOnboardingVerifyCode', e);
      throw e;
    }
  }

  /**
   * @param payload
   * @param type - GGPASS Use
   */
  async sendVerification(payload, type = 'SIGN_UP') {
    const site = this.#store.state.env.site;
    const app = this.#store.state.query.app;
    const { SendVerificationType } = payload;
    const isEmail = SendVerificationType === this.#emailType;
    try {
      switch (app) {
        case 'forgot-password':
        case 'fp': {
          return isExcludeGgpass(site) ? await this.#sendNpPublicIdentityVerification(payload) : await this.#sendGpVerification(payload, type);
        }
        case 'onboarding': {
          return isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload);
        }
        case 'myinfo': {
          return await this.#myInfoVerificationAdapter(payload, type);
        }
        case 'signup': { // signup의 경우 사용 안함
          return isExcludeGgpass(site) ? await this.#sendNpPublicIdentityVerification(payload) : await this.#sendGpVerification(payload, type);
        }
        default: {
          return isExcludeGgpass(site) ? (isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload)) : await this.#sendGpVerification(payload, type);
        }
      }
    } catch (e) {
      console.log('error : sendVerification', e);
      throw e;
    }
  }

  async sendFpVerification(payload) {
    const isEmail = payload.SendVerificationType === this.#emailType;
    try {
      return isEmail ? await this.#sendNpFpEmailVerification(payload) : await this.#sendNpFpMobileVerification(payload);
    } catch (e) {
      console.log('error : sendFpVerification', e);
      throw e;
    }
  }

  async verifyCode(payload) {
    const app = this.#store.state.query.app;
    const site = this.#store.state.env.site;
    const isEmail = payload.SendVerificationType === this.#emailType;

    try {
      switch (app) {
        case 'forgot-password':
        case 'fp': {
          if(isExcludeGgpass(site)){
            return isEmail ? await this.#verifyNpEmailFpCode(payload) : await this.#verifyNpMobileFpCode(payload)
          }
          else return await this.#sendGpVerificationCode(payload);
        }
        case 'onboarding': {
          payload.CountryCode = payload.MobileCountryCode || payload.CountryCode;
          return isEmail ? await this.#editOnboardingVerifyEmailCode(payload) : await this.#editOnboardingVerifyMobileCode(payload);
        }
        case 'signup': {
          return;
        }
      }
    } catch (e) {
      console.log('error : verifyCode', e);
      throw e;
    }
  }

  /**
   * @param payload
   * @param type - GGPASS Use
   */
  async resend(payload, type = 'SIGN_UP') {
    const { SendVerificationType } = payload;
    const isEmail = SendVerificationType === this.#emailType;
    try {
      switch (this.#app) {
        case 'forgot-password':
        case 'fp': {
          const result = isExcludeGgpass(this.#site) ? (isEmail ? await this.#sendNpFpEmailVerification(payload) : await this.#sendNpFpMobileVerification(payload)) : await this.#sendGpVerification(payload, type);
          if(result.key === apiErrorCode.ONEPASS_NOT_FOUND) return { ...result, key : apiErrorCode.FORGOT_ONEPASS_NOT_FOUND, error: true };
          return result;
        }
        case 'signup': {
          // TODO : [ean] signup 용 send api 호출 하도록 처리해야함
          return isExcludeGgpass(this.#site) ? await this.#editNpSignupVerifyEmailCode(payload) : await this.#sendGpVerification(payload, type);
        }
        case 'onboarding': {
          payload.CountryCode = payload.MobileCountryCode || payload.CountryCode;
          return isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload);
        }
        case 'myinfo': {
          return await this.#myInfoVerificationAdapter(payload, type);
        }
        default: {
          return isExcludeGgpass(this.#site) ? (isEmail ? await this.#sendNpUserEmailVerification(payload) : await this.#sendNpUserMobileVerification(payload)) : await this.#sendGpVerification(payload, type);
        }
      }
    } catch (e) {
      console.log('error : sendVerification', e);
      throw e;
    }
  }
}