<template>
  <ContentsContainer bonus-history :info="historyInfo" no-header>
    <h3>{{ $t('_.bonusHistory') }}</h3>
    <div class="table-holder">
      <GridTable :list="/** @type {PrizeHistoryItem[]} */tableList" minWidth="970px">
        <template v-slot="{ /** @type {PrizeHistoryItem} */row }">
          <GridColumn :label="$t('_.date')" :weight="colWidth.date">{{ toFullDateT(row.createdAt) }}</GridColumn>
          <GridColumn :label="$t('_.promotion')" :weight="colWidth.promotion">{{ promotionName(row) }}</GridColumn>
          <GridColumn :label="$t('_.description')" :weight="colWidth.description">{{ row.description }}</GridColumn>
          <GridColumn :label="$t('amount')" class="tr" :weight="colWidth.amount">{{ getCurrencyString(row.currencyId, row.amount)  }}</GridColumn>
          <GridColumn :label="$t('_.totalAmount')" class="tr" :weight="colWidth.total">{{ getCurrencyString(row.totalCurrencyId, row.totalAmount) }}</GridColumn>
        </template>
        <template #no-list>
          <p>{{ $t('_.noBonusHistory') }}</p>
        </template>
      </GridTable>
    </div>
    <div class="pagination">
      <button :disabled="!hasPrev" @click="prev">
        <FontIcon name="chevron-left" />
      </button>
      <p>{{ pageIndex + 1 }}</p>
      <button :disabled="!hasNext" @click="next">
        <FontIcon name="chevron-right" />
      </button>
    </div>
  </ContentsContainer>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { toFullDateT } from '@/utils/dateTimeUtil';
import ContentsContainer from '@/views/components/pages/promotion/ContentsContainer.vue';
import GridTable from '@shared/components/common/table/GridTable.vue';
import GridColumn from '@shared/components/common/table/GridColumn.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'BonusHistory',
  lexicon: 'promotion',
  components: { ContentsContainer, FontIcon, GridColumn, GridTable },
  props: {
    info: Specific,
    uid: Number,
  },
  data() {
    return {
      historyInfo: null,
      pageIndex: 0,
      limit: 20,
      colWidth: {
        date: 1.8,
        promotion: 3,
        description: 2.2,
        amount: 1.5,
        total: 1.5,
      }
    };
  },
  watch: {
    uid: 'updateInfo',
  },
  computed: {
    tableList() {
      return this.historyInfo?.list || [];
    },
    hasPrev() {
      return this.historyInfo?.hasPrevious;
    },
    hasNext() {
      return this.historyInfo?.hasNext;
    },
  },
  methods: {
    toFullDateT,
    getCurrencyString(currency, value) {
      return this.$promotion.getCurrencyString(currency, value);
    },
    promotionName(row) {
      return row?.promotion?.name;
    },
    async prev() {
      if (this.hasPrev) {
        --this.pageIndex;
        await this.updateInfo(this.pageIndex);
      }
    },
    async next() {
      if (this.hasNext) {
        ++this.pageIndex;
        await this.updateInfo(this.pageIndex);
      }
    },
    async updateInfo(offset = 0) {
      const r = /** @type {PrizeHistory} */await this.$services.promotion.getPrizeHistory(this.promotionType, { offset, limit: this.limit });
      if (r && !r.error) this.historyInfo = r;
    },
    async initialize() {
      const types = {
        FreeBonus: 'FREE_BONUS',
        MatchBonus: 'DEPOSIT_MATCH_BONUS',
      };
      const routeName = this.$route?.name;
      this.promotionType = types[routeName];
      if (!this.promotionType) return;
      await this.updateInfo();
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import "@/less/tpm";
[bonus-history] { .c(@c-gray3);
  .header, table tbody tr { .bgc(); }
  table thead th, table tbody td { .p(12, 4);
    &:first-child { .pl(20); }
    &:last-child { .pr(20); }
  }
  table thead {
    th { border-color: @c-bd; border-width: 1px; .tl() !important;
      &:not(:nth-of-type(1)) { .-l(); }
      &.tr { .tr() !important; }
    }
  }
  table tbody {
    td { border-color: @c-bd; .fs(16);
      &[label="Promotion"], &[label="Description"] { .c(@c-white); }
    }
  }
}
</style>
<style scoped lang="less">
@import "@/less/tpm";
[bonus-history] { .mt(16);
  h3 { .pv(20); .fs(16); .regular(); }
  .table-holder { .mh(-20); }
  .tr { .tr(); }

  .pagination { .mt(20); }
  .pagination { .flex(); .justify-end(); .items-center(); .gap(4); .tc(); .c(@c-gray1);
    > p { .w(28); }
    button { .wh(28); .bgc(@c-gray8); .c(inherit);
      &:disabled { .o(0.35); cursur: auto; pointer-events: none; }
    }
  }
}
</style>
