import { isExcludeGgpass } from '@/utils/baseUtil';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { gpBrandIds, gpSiteIds } from '@/constants/base/siteMap';

export default class UserService {
  #services;
  #api;
  #userApi;
  #ggpass;
  #store;
  constructor(services) {
    this.#services = services;
    this.#api = services.api;
    this.#userApi = services.userApi;
    this.#ggpass = services.passApi;
    this.#store = services.store;
  }

  /**
   * @id VerifyAndEditEmailRequest
   * @param Email
   * @param Kind
   * @param VerificationCode
   * @param IsContactUpdate
   * @returns {Promise<*|{value, key: string}>}
   */
  async #editNpEmail({ Email, VerificationType, VerificationCode, IsContactUpdate }) {
    try {
      const r = await this.#userApi.patch('/player/email', { Email: (Email ? Email.trim() : undefined), VerificationType, VerificationCode });
      if (r?.error && r?.key === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { key: 'limit', value: r.body.Description };
      if(r?.error) return r;
      // if(IsContactUpdate){
      //   const contact = await this.#updateNpContactWithLoginId('Email');
      //   if(contact?.error){
      //     if([apiErrorCode.EXIST_MOBILE, apiErrorCode.EMAIL_EXISTS, apiErrorCode.MOBILE_SERVICE_NOT_SUPPORTED, apiErrorCode.EXISTING_MOBILE_NUMBER].includes(contact?.key)){
      //       return {...contact, key : 'contact', error : true,};
      //     }
      //     return {...contact, key : 'contact-error', error : true,};
      //   }
      //   return contact
      // }
      // return r;
      const contact = await this.#updateNpContactWithLoginId('Email');
      if(contact?.error){
        if([apiErrorCode.EXIST_MOBILE, apiErrorCode.EMAIL_EXISTS, apiErrorCode.MOBILE_SERVICE_NOT_SUPPORTED, apiErrorCode.EXISTING_MOBILE_NUMBER].includes(contact?.key)){
          return {...contact, key : 'contact', error : true,};
        }
        return {...contact, key : 'contact-error', error : true,};
      }
      return contact
    } catch (e) {
      if (e?.code === apiErrorCode.EMAIL_RESEND_TERM_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @id EditMobileNumberRequest
   * @param CountryCode
   * @param MobileNumber
   * @param VerificationCode
   * @param Kind
   * @param IsContactUpdate
   * @returns {Promise<*>}
   */
  async #editNpMobileNumber({ CountryCode, MobileNumber, VerificationCode, VerificationType, IsContactUpdate, IsSkipMobileVerification = false }) {
    try {
      const r = await this.#userApi.patch('/player/mobile', { CountryCode, MobileNumber, VerificationCode, VerificationType, IsSkipMobileVerification });
      if (r?.error && r?.key === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { key: 'limit', value: r.body.Description };
      if(r?.error) return r;

      if(IsContactUpdate){
        const contact = await this.#updateNpContactWithLoginId('MobileNumber');

        if(contact?.error){
          if([apiErrorCode.EXIST_MOBILE, apiErrorCode.EMAIL_EXISTS, apiErrorCode.MOBILE_SERVICE_NOT_SUPPORTED, apiErrorCode.EXISTING_MOBILE_NUMBER].includes(contact?.key)){
            return {...contact, key : 'contact', error : true,};
          }
          return {...contact, key : 'contact-error', error : true,};
        }
        return contact
      }
      return r;
    } catch (e) {
      console.log('error : editMobileNumber');
      if (e?.code === apiErrorCode.PHONE_RESEND_TERM_LIMITED) return { key: 'limit', value: e.body.Description };
      throw e;
    }
  }

  /**
   * @param Email
   * @param CountryCode
   * @param Token
   * @param Code
   * @param MobileNumber
   * @param IsContactUpdate
   * @param LoginMethod
   * @param SendVerificationType
   * @returns {Promise<*|{error}|void>}
   */
  async #setGpLoginId({ Email, CountryCode, Token, Code, MobileNumber, IsContactUpdate, LoginMethod, SendVerificationType }) {
    const isEmail = SendVerificationType === 'EMAIL';
    const email = isEmail ? Email?.trim() : null;
    const countryCode = isEmail ? null : CountryCode;
    const mobileNumber = isEmail ? null : MobileNumber;
    const verificationCode = Code;
    const verificationToken = Token;
    const IdType = isEmail ? 'Email' : 'MobileNumber';
    const brandId = this.#store.state.env.gpBrand;
    // EVPUKE일 경우에만 siteID 발송
    const siteId = this.#store.state.env.gpSite === gpSiteIds.EVPUKE ? this.#store.state.env.gpSite : null;
    try {
      const result = await this.#ggpass.put('/op/v1/onepass/login-id', { email, countryCode, mobileNumber, verificationCode, verificationToken, brandId, siteId });
      if (result.error) return result;
      if (!IsContactUpdate) return result;

      const contact = await this.#updateNpContactWithLoginId(IdType);

      if(contact?.error){
        if([apiErrorCode.EXIST_MOBILE, apiErrorCode.EMAIL_EXISTS, apiErrorCode.MOBILE_SERVICE_NOT_SUPPORTED, apiErrorCode.EXISTING_MOBILE_NUMBER].includes(contact?.key)){
          return {...contact, key : 'contact', error : true,};
        }
        return {...contact, key : 'contact-error', error : true,};
      }
      return contact;
    } catch (e) {
      console.log('error : setLoginId', e);
      throw e;
    }
  }

  /**
   * @id UpdateContactWithLoginIdRequest
   * @description GGPass A type일때 Update Contact Email / Mobile 전용
   * */
  async #updateNpContactWithLoginId(IdType) {
    try {
      const r = await this.#userApi.patch('/player/contact/update-by-id', { IdType });
      return r;
    } catch (e) {
      console.log('error : updateContactWithLoginId', e);
      throw e;
    }
  }

  async #myInfoLoginIdAdapter(payload) {
    const site = this.#store.state.env.site;
    const { ApiType, SendVerificationType } = payload;
    const isEmail = SendVerificationType === 'EMAIL';
    try {
      switch (ApiType) {
        case 'CHANGE_LOGIN_ID' : {
          if (isExcludeGgpass(site)) {
            payload.VerificationType = 'LoginId';
            return isEmail ? await this.#editNpEmail(payload) : await this.#editNpMobileNumber(payload);
          } else {
            const changeIdPayload = isEmail ? { ...payload, Code: payload.VerificationCode, IsContactUpdate: true } : { ...payload, Code: payload.VerificationCode, CountryCode: `+${payload.CountryNumber}` };
            return await this.#setGpLoginId(changeIdPayload);
          }
        }
        case 'MFA_TYPE_ADD' : {
          payload.IsContactUpdate = false;
          if (isExcludeGgpass(site)) {
            payload.VerificationType = 'LoginId';
            const result = isEmail ? await this.#editNpEmail(payload) : await this.#editNpMobileNumber(payload);
            if (result.error) {
              return result;
            }
            // MFA 일때, 모바일 번호 저장이 성공 하면 MFA도 세팅 한다.
            const mfaResult = await this.#services.mfa.sendMfa(payload);
            return mfaResult;
          } else {
            const result = await this.#services.mfa.sendMfa(payload);
            if (result.error) {
              return result;
            }
            // MFA 일때, 모바일 번호 저장이 성공 하면 MFA도 세팅 한다.
            const mfaResult = await this.#services.mfa.sendMfa(payload);
            return mfaResult;
          }
        }
        case 'CONTACT_INFO' : {
          payload.VerificationType = 'ContactInfo';
          payload.IsContactUpdate = false;
          return isEmail ? await this.#editNpEmail(payload) : await this.#editNpMobileNumber(payload);
        }
      }
    } catch (e) {
      console.log('error : myInfoVerificationAdapter', e);
      throw e;
    }
  }

  /**
   * @id ChangePasswordRequest
   * @param Password
   * @param OldPassword
   * @returns {Promise<*>}
   */
  async #changeNpPassword({ Password, OldPassword }) {
    try {
      const r = await this.#userApi.post('/player/password', { Password, OldPassword });
      return r;
    } catch (e) {
      console.log('error : changePassword');
      throw e;
    }
  }

  /**
   * @param oldPassword { String }
   * @param newPassword { String }
   * */
  #changeGpPassword({ OldPassword, Password }) {
    try {
      const newPassword = Password;
      const oldPassword = OldPassword;

      const brandId = this.#store.state.env.gpBrand;
      const siteId = this.#store.state.env.gpSite;

      return this.#ggpass.put('/op/v1/onepass/passwords', { oldPassword, newPassword, brandId, siteId });
    } catch (e) {
      console.log('error : changePassword', e);
      throw e;
    }
  }

  async setLoginId(payload) {
    const site = this.#store.state.env.site;
    const app = this.#store.state.query.app;
    const { SendVerificationType } = payload;
    const isEmail = SendVerificationType === 'EMAIL';

    try {
      switch (app) {
        case 'myinfo' : {
          return await this.#myInfoLoginIdAdapter(payload);
        }
        default : {
          if (isExcludeGgpass(site)) {
            return isEmail ? await this.#editNpEmail(payload) : await this.#editNpMobileNumber(payload);
          } else {
            return await this.#setGpLoginId(payload);
          }
        }
      }
    } catch (e) {
      console.log('error : sendSetLoginId');
      throw e;
    }
  }

  async changePassword(payload) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return this.#changeNpPassword(payload);
      } else {
        return this.#changeGpPassword(payload);
      }

    } catch (e) {
      console.log('error : changePassword');
      throw e;
    }
  }

  /**
   * @id ChangeFundPasswordRequest
   * @param FundPassword
   * @param CurrentFundPassword
   * @param IsEnableFundPassword
   * @returns {Promise<*>}
   */
  async changeNpFundPassword({ FundPassword, CurrentFundPassword, IsEnableFundPassword }) {
    try {
      const r = await this.#userApi.post('/player/fundpassword', { FundPassword, CurrentFundPassword, IsEnableFundPassword });
      return r;
    } catch (e) {
      console.log('error : changeFundPassword');
      throw e;
    }
  }

  /**
   * @id EditPlayerMyInfoRequest
   * @param FirstName {string}
   * @param LastName {string}
   * @param MaidenName {string}
   * @param MiddleName {string}
   * @param Alias {string}
   * @param PlaceOfBirth {string}
   * @param DateOfBirth {string}
   * @param Address {string}
   * @param Building {string}
   * @param StreetName {string}
   * @param StreetNumber {string}
   * @param HouseNumber {string}
   * @param City {string}
   * @param PostalCode {string}
   * @param State {string}
   * @param Nationality {string}
   * @param Gender {string}
   * @param EmploymentType {string}
   * @param OccupationType {string}
   * @param IsMarketingAgreement {boolean}
   * @param EmployerName {string}
   * @param EmployerAddress {string}
   * @param BusinessPhoneNumber {string}
   */
  editPersonalInfo({ FirstName, LastName, MaidenName, MiddleName, Alias, PlaceOfBirth, DateOfBirth, Address, Building, StreetName, StreetNumber, HouseNumber, State, City, PostalCode, Nationality, Gender, EmployerAddress, EmploymentType, EmployerName, OccupationType, IsMarketingAgreement, BusinessPhoneNumber }) {
    try {
      return this.#userApi.patch('/player/myinfo', { FirstName, LastName, MaidenName, MiddleName, Alias, PlaceOfBirth, DateOfBirth, Address, Building, StreetName, StreetNumber, HouseNumber, State, City, PostalCode, Nationality, Gender, EmployerAddress, EmploymentType, EmployerName, OccupationType, IsMarketingAgreement, BusinessPhoneNumber });
    } catch (e) {
      console.log('error : editPersonalInfo');
      throw e;
    }
  }

  /**
   * @returns {Promise<*>}
   */
  async #LogoutGpAllDevices() {
    try {
      const brandId = this.#store.state.env.gpBrand;
      return this.#ggpass.delete(`/op/v1/onepass/refresh-tokens?brandId=${brandId}`, { brandId }, { silent: true });
    } catch (e) {
      console.log('error: logoutAllDevices', e);
    }
  }

  /**
   * @returns {Promise<*>}
   */
  async #LogoutNpAllDevices() {
    try {
      return this.#userApi.delete('/v1/auth/tokens/revoke-all', null, { silent: true });
    } catch (e) {
      console.log('error: logoutAllDevices', e);
    }
  }

  /**
   * @returns {Promise<*>}
   */
  async logoutAllDevices() {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#LogoutNpAllDevices();
      } else {
        return await this.#LogoutGpAllDevices();
      }
    } catch (e) {
      console.log('error : logoutAllDevices');
      throw e;
    }
  }


  /**
   * @description forgotPasswordService.js 의 resetPasswordWithToken + resetPasswordByRequest + resetPassword
   * @id ResetPasswordRequest
   * @param {number} LoginMethod
   * @param {string} CountryCode
   * @param {string} MobileNumber
   * @param {string} Email
   * @param {string?} Username
   * @param {string} Password
   * @param {boolean?} IsIgnoredCaptcha
   * @param {boolean?} IsTokenVerification
   * @param {number?} RequestMethodType
   * @param {string?} Token
   * @param {string?} MobileVerificationCode
   * @param {string?} EmailVerificationCode
   * @param {string?} RecaptchaToken
   * @param {string?} Challenge
   * @param {string?} SecCode
   * @param {string?} Validate
   * @param {boolean?} IsOffline
   * @param {string?} EmailVerificationToken
   * 확인 중
   */
  async #sendNpResetPassword({ LoginMethod, CountryCode, MobileNumber, Email, Username = '', Password, IsTokenVerification, RequestMethodType, Token, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Validate, IsOffline, Challenge, SecCode, EmailVerificationToken, VerifyCode, NpApiType, SendVerificationType }) {
    const isEmail = SendVerificationType === 'EMAIL';
    try {
      const MobileVerificationCode = isEmail ? null : VerifyCode;
      const EmailVerificationCode = isEmail ? VerifyCode : null;
      const result = await this.#api.patch('/player/password', { LoginMethod, CountryCode, MobileNumber, Email: (Email ? Email?.trim() : undefined), Username, Password, IsTokenVerification, RequestMethodType, Token, MobileVerificationCode, EmailVerificationCode, RecaptchaSiteKey, RecaptchaToken, RecaptchaAction, Validate, Challenge, SecCode, IsOffline, EmailVerificationToken }, { silent: true });
      return result;
    } catch (e) {
      console.log('error : #sendNpResetPassword');
      throw e;
    }
  }

  /**
   * @param refreshToken { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @param verificationToken { String }
   * @param password { String }
   * @returns { Promise<null> }
   * */
  #sendGpResetPassword(payload) {
    const isEmail = payload.SendVerificationType === 'EMAIL';
    try {
      // 24-07-10 수정 - OP API Spec 변경으로 인하여 B/S 추가
      const brandId = this.#store.state.env.gpBrand;
      const isMbp = brandId === gpBrandIds.GGPUKE || brandId === gpBrandIds.EVPUKE;
      const siteId = isMbp ? this.#store.state.env.gpSite : null;

      const email = isEmail ? payload.Email?.trim() : null;
      const countryCode = isEmail ? null : payload.CountryCode;
      const mobileNumber = isEmail ? null : payload.MobileNumber;
      const verificationCode = payload.Code;
      const verificationToken = payload.Token;
      const password = payload.Password;
      const verificationCodeType = payload?.VerificationCodeType;

      return this.#ggpass.put('/op/v1/onepass/passwords/reset', { email, countryCode, mobileNumber, verificationCode, verificationToken, password, verificationCodeType, brandId, siteId }, { silent: true });
    } catch (e) {
      console.log('error : #sendGpResetPassword');
      throw e;
    }
  }

  async sendResetPassword(payload) {
    const site = this.#store.state.env.site;
    try {
      return isExcludeGgpass(site) ? await this.#sendNpResetPassword(payload) : await this.#sendGpResetPassword(payload);
    } catch (e) {
      console.log('error : sendResetPassword');
      throw e;
    }
  }
}