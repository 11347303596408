<template>
  <div verify-rg-balance-setting>
    <LimitUnitField :label="$t('newLimit')" :unit-label="unitLabel" name="_amount" :rules="rules" :max-length="8" v-model="modelInfo.RequestBalanceLimit" :required="isGGPNL" />
    <p class="list-star">{{ $t(isGGPNL ? '_.balanceLimit.maximumDescription' : '_.balanceLimit.description') }}</p>
    <ul>
      <li>{{ $t(`_.balanceLimit.subDescription1`) }}</li>
      <li>{{ $t(`_.balanceLimit.subDescription2`) }}</li>
    </ul>
  </div>
</template>

<script>
import LimitUnitField from '@/views/components/pages/on-boarding/template/extra/LimitUnitField.vue';
import baseTemplate from '@/mixins/baseTemplate';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { commaDecimal } from '@shared/utils/numberUtils.mjs';

export default {
  name: 'VerifyRgBalanceSetting',
  lexicon: 'onBoarding.setting',
  components: { LimitUnitField },
  mixins: [baseTemplate],
  computed: {
    site: state('env', 'site'),
    currencySymbol: state('user', 'currencySymbol'),
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    rules() {
      if (this.isGGPNL) return `requiredNumber:1,8|minimumDepositLimit:${this.currencySymbol},1|maximumDepositLimit:${this.currencySymbol},1,5000000`;
      return 'requiredNumber:1,8';
    },
    unitLabel() {
      return !this.isGGPNL ? this.$t('amount') : `${this.$t('maximum')}: ${this.currencySymbol}${commaDecimal(5000000)}`;
    },
  },
  methods: {
    async initialize() {
      await this.$services.account.getBalanceLimit();
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[verify-rg-balance-setting] {
  [limit-unit-field] {
    label { flex-basis: 30%; .min-w(98); white-space: normal; }
    label + div { flex-basis: 70%; }
  }
  .list-star { .pb(0); }
  > ul { .mt(4); .ml(16);
    li { .pl(10); .rel(); .c(#7F7F7F); .fs(16, 20, normal);
      &:before { .cnt(); .wh(4); .br(50%); .bgc(#7F7F7F); .abs(); .lt(0, 9); .t-yc(); }
    }
  }
}
</style>
