<template>
  <GpFormTemplate balance-limit>
    <template #header>
      <GpDescription :message="$t('responsibleGaming.description.balanceLimitNl')" />
      <GpUlDescriptionList :message="$t('responsibleGaming.description.balanceLimitNlGuide')" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content>
      <InputBalanceLimit :model="model" :current="current" :currency-symbol="currencySymbol" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="openModal" :disabled=" !!invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import InputBalanceLimit from '@/views/components/pages/responsible-gaming/template/inputs/InputBalanceLimit.vue';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { siteIds } from '@/constants/base/siteMap';
import { maximumBySite } from '@/constants/base/responsible-gaming';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';

export default {
  name: 'RgBalanceLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputBalanceLimit, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    // description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      currencySymbol: null,
      current: {
        Currency: null,
        CurrentBalanceLimit: null,
        BalanceLimitApplyAt: null,
      },
      model: {
        RequestBalanceLimit: null,
        RequestType: null,
        NoLimit: false,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    calculateList() {
      return [
        { type: 'default', current: this.current.CurrentBalanceLimit, request: this.model.RequestBalanceLimit, noLimit: this.noLimit, valueType: 'amount', currencySymbol: this.currencySymbol },
      ];
    },
    noLimit() {
      return this.model.RequestBalanceLimit ? false : true;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    disabled() {
      const {
        CurrentBalanceLimit,
      } = this.current;
      const {
        RequestBalanceLimit,
        NoLimit
      } = this.model;

      if (!RequestBalanceLimit) return true;

      let request = (RequestBalanceLimit || parseInt(RequestBalanceLimit) === 0) ? parseInt(RequestBalanceLimit) : null;
      request = NoLimit ? null : request;
      const sameValue = CurrentBalanceLimit === request;

      return sameValue;
    },
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };
      this.currencySymbol = getCurrencySymbol(this.current.Currency);
    },
    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };

      return {
        title: this.$t('_.modal.title'),
        subTitle: this.$t(`_.modal.subTitle.${this.structure.config.title}`),
        modalType: 'HangTime',
        request: () => this.$rg.request(this.model),
        calculateList: this.calculateList,
        descModel: descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze
      };
    },
    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[balance-limit] {
  .desc { .pb(20);
    > p {.c(#999); .fs(12); line-height: 16px;}
  }
  .limit-input-group {.flex(); .flex-dc(); .gap(20); .p(20); .bgc(#f8f8f8); .br(4);}
}

</style>